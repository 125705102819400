import React, { useState } from "react";
import UserForm from "./UserForm";
import { useFirebase } from "../../../app/context/Firebase";
import { useToasts } from "react-toast-notifications";

export default function CreateUser({ goBack }) {
  const firebase = useFirebase();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { addToast } = useToasts();
  //TODO:Move to users context
  const handleSubmit = async (e, formValues, reset, createAnother) => {
    setLoading(true);
    e.preventDefault();
    formValues.displayName = `${formValues.firstName} ${formValues.lastName}`;
    delete formValues.formValid;

    const existingUserSnap = await firebase
      .findUserByEmail(formValues.email)
      .get();

    const existingUser = existingUserSnap.docs.length > 0;

    if (existingUser) {
      setError({
        type: "existingUser",
        message: "There is already a user assigned to this email address",
      });

      setLoading(false);
    } else {
      return firebase
        .createNewUser({
          ...formValues,
          firstLogin: true,
          isApproved: true,
        })

        .then(() => {
          addToast("New user created successfully", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 1500,
            transitionDuration: 10,
            transitionState: "entered",
          });
          setLoading(false);
          console.log(createAnother);
          if (createAnother) reset();
          else goBack();
        })
        .catch((error) => {
          setLoading(false);

          setError(error);
          console.log(error);
        });
    }
  };

  return (
    <UserForm
      loading={loading}
      error={error}
      handleSubmit={handleSubmit}
      submitText="Create User"
      create={true}
      cancel={goBack}
      initialValues={{ role: undefined }}
    />
  );
}
