import React from "react";
import styled from "@emotion/styled";
import { Card, Image } from "@theme-ui/components";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import DashNewsflash from "../Dashboard/DashNewsflash";
import { useUserContext } from "../../app/context/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPointString } from "../../app/context/points/formatting";
import PlatformLinks from "../Dashboard/PlatformLinks";
import { primary } from "../../app/DSL/theme";
import { Ring } from "react-awesome-spinners";
import colours from "../../app/DSL/styles/colours";

const NotApproved = () => {
  const user = useUserContext();
  return (
    <div>
      <PageHeaderContainer image>
        <Image
          src={`/assets/${
            process.env.REACT_APP_LOGO
              ? process.env.REACT_APP_LOGO
              : "long-logo.svg"
          }`}
          width="390px"
        />
      </PageHeaderContainer>
      <MessageCard>
        <h3>Your account has not yet been approved</h3>
        <p>
          An admin will review your account as soon as possible.
          <br />
          You will recieve an email once your account has been approved. If you
          have recieved the approval email and are seeing this message please
          try logging out and back in again.
        </p>
      </MessageCard>
      <TopGrid>
        <Card>
          <BlockHeaderContainer
            title={`Welcome ${user?.displayName ? user?.displayName : "back"}`}
          />
          {user ? (
            <div>
              {process.env.REACT_APP_VIEW_POINTS === "true" &&
                (user?.points ? (
                  <Points>
                    <h1>
                      <FontAwesomeIcon icon={["far", "star"]} />{" "}
                      {formatPointString(user.points, "spaces")}{" "}
                    </h1>
                    <h2>POINTS</h2>
                  </Points>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Ring color={colours[primary].shade["500"]} />
                  </div>
                ))}
              {(process.env.REACT_APP_SHOP === "true" ||
                process.env.REACT_APP_BROCHURE === "true" ||
                process.env.REACT_APP_WAREHOUSE === "true" ||
                process.env.REACT_APP_SALES === "true") && (
                <div>
                  <BlockHeaderContainer title="Platforms" />
                  <PlatformLinks />
                </div>
              )}
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Ring color={colours[primary].shade["500"]} />
            </div>
          )}
        </Card>

        <Card
          style={{
            display: "grid",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              display: "block",
              maxWidth: "960px",
            }}
          >
            <div style={{ paddingTop: "56.25%" }}>
              <iframe
                title={`Welcome to ${process.env.REACT_APP_SITE_TITLE}`}
                src={process.env.REACT_APP_VIDEO}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                }}
              ></iframe>
            </div>
          </div>
        </Card>
      </TopGrid>

      {process.env.REACT_APP_NEWSFLASH === "true" && (
        <Card style={{ position: "relative" }}>
          <BlockHeaderContainer title="Newsflash" />
          <DashNewsflash />
        </Card>
      )}
    </div>
  );
};

export default NotApproved;

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 1em;
  margin-bottom: 1em;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;

const Points = styled.div`
  text-align: center;
  h1 {
    svg {
      color: ${(props) => props.theme.colors.orange.shade["500"]};
      margin-right: 5px;
    }
    font-size: 3em;
    margin-bottom: 0;
  }
  h2 {
    font-size: 2em;
    margin-top: 0;
  }
`;

const MessageCard = styled(Card)`
  padding: 1em 2em;
  margin-bottom: 1em;
  background: ${(props) => props.theme.colors[primary].shade[500]};
  color: ${(props) => props.theme.colors[primary].font[500]};
`;
