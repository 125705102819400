import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faTh,
  faTrash,
  faPencilAlt,
  faEye,
  faCheckCircle,
  faTimesCircle,
  faUserCircle,
  faSearch,
  faThumbsUp,
  faCheck,
  faLock,
  faPortrait,
  faCircle,
  faQuestionCircle,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import {
  faSignOut,
  faHourglassHalf,
  faUserAlt,
  faLifeRing,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faCog,
  faTh,
  faSignOut,
  faTrash,
  faPencilAlt,
  faEye,
  faCheckCircle,
  faTimesCircle,
  faHourglassHalf,
  faUserAlt,
  faUserCircle,
  faSearch,
  faThumbsUp,
  faCheck,
  faLock,
  faPortrait,
  faCircle,
  faLifeRing,
  faQuestionCircle,
  faDownload
);

export {
  faCog,
  faTh,
  faSignOut,
  faTrash,
  faPencilAlt,
  faEye,
  faCheckCircle,
  faTimesCircle,
  faHourglassHalf,
  faUserAlt,
  faUserCircle,
  faSearch,
  faThumbsUp,
  faCheck,
  faLock,
  faPortrait,
  faCircle,
  faLifeRing,
  faQuestionCircle,
  faDownload,
};
