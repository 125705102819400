import React, { createContext, useContext, useState, useEffect } from "react";
import { transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext } from "../auth";

const DoctorsContext = createContext({});

export const DoctorsProvider = ({ children }) => {
  const firebase = useFirebase();
  const [doctors, setDoctors] = useState();
  const { user } = useAuthContext();

  useEffect(() => {
    const unsubscribe = user
      ? firebase
          .doctors()
          .onSnapshot((snap) => setDoctors(transformCollectionSnap(snap)))
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  return (
    <DoctorsContext.Provider value={{ doctors }}>
      {children}
    </DoctorsContext.Provider>
  );
};

export const DoctorsConsumer = DoctorsContext.Consumer;

export const useDoctors = () => useContext(DoctorsContext);
