import React from "react";
import { Image } from "@theme-ui/components";
import ComplimentCard from "./ComplimentCard";
import styled from "@emotion/styled";
import { isBefore } from "date-fns";
import colours from "../../../app/DSL/styles/colours";
import { primary } from "../../../app/DSL/theme";
import { Ring } from "react-awesome-spinners";

export default function ComplimentList({ compliments, approval, loading }) {
  return loading ? (
    <div style={{ textAlign: "center" }}>
      <Ring color={colours[primary].shade["500"]} />
    </div>
  ) : compliments?.length > 0 ? (
    <Compliments approval={approval}>
      {compliments
        .sort((a, b) =>
          isBefore(a?.datePosted?.toDate(), b?.datePosted?.toDate()) ? 1 : -1
        )
        .map((c, idx) => (
          <ComplimentCard compliment={c} key={idx} approval={approval} />
        ))}
    </Compliments>
  ) : (
    <div style={{ textAlign: "center" }}>
      <Image
        src="/assets/empty-mail.svg"
        style={{ width: "45%", margin: "auto" }}
      />
      <h4>There are no compliments here yet</h4>
    </div>
  );
}

const Compliments = styled.div`
  & > div {
    margin-bottom: 1em;
  }
  max-height: 45vh;
  padding: 1em;
  overflow-y: auto;
`;
