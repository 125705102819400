import React from "react";
import { Image } from "@theme-ui/components";
import styled from "@emotion/styled";
import MessageCard from "./MessageCard";
import { isBefore } from "date-fns";

export default function MessageList({ messages }) {
  return messages?.length > 0 ? (
    <Messages>
      {messages
        .sort((a, b) =>
          isBefore(a.datePosted.toDate(), b.datePosted.toDate()) ? 1 : -1
        )
        .map((s, idx) => (
          <MessageCard message={s} key={idx} />
        ))}
    </Messages>
  ) : (
    <div style={{ textAlign: "center" }}>
      <Image
        src="/assets/messages.svg"
        style={{ width: "45%", margin: "auto" }}
      />
      <h4>There are no messages here</h4>
    </div>
  );
}

const Messages = styled.div`
  & > div {
    margin-bottom: 1em;
  }
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1em;
`;
