import React, { useState, useEffect, useContext, createContext } from "react";
import { useFirebase } from "../Firebase";
import { pointReport, numPoints } from "../points/formatting";

const AuthContext = createContext();
const UserContext = createContext();

export const AuthProvider = ({ children }) => {
  const firebase = useFirebase();
  const [loading, setLoading] = useState(true);
  const [authState, setAuthState] = useState({
    user: undefined,
    userId: undefined,
    claims: undefined,
  });
  const [userState, setUserState] = useState();

  const updateFirstLogin = async () => {
    const reason = "joined";
    const batch = firebase.db.batch();

    await batch.update(firebase.user(userState.id), {
      firstLogin: false,
    });
    await batch.update(firebase.user(userState.id), {
      firstLoginToday: new Date(),
    });
    if (
      (userState?.points === 0 || !userState.points) &&
      process.env.REACT_APP_EARN_POINTS === "true"
    ) {
      await batch.update(firebase.user(userState.id), {
        points: firebase.firestore.FieldValue.increment(numPoints(reason)),
      });
      await batch.set(
        firebase.pointsLog().doc(),
        pointReport({
          reason: "joined",
          userLink: `${userState.id}__${userState.displayName}`,
        })
      );
    }

    await batch.commit();

    return setAuthState((prev) => ({
      ...prev,
      claims: { ...prev.claims, firstLogin: false },
    }));
  };

  const handleLoginRedirect = async (authUser) => {
    if (
      !authUser &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/register"
    ) {
      window.location.replace("/");
    }
  };

  const handleDashboardRedirect = async (claims) => {
    if (
      claims &&
      !claims.firstLogin &&
      (window.location.pathname === "/" ||
        window.location.pathname === "/register")
    ) {
      window.location.replace("/dashboard");
    }
  };

  useEffect(() => {
    firebase.checkUserAuth(async (authUser) => {
      handleLoginRedirect(authUser);

      if (authUser) {
        await firebase.auth.currentUser
          .getIdTokenResult()
          .then(async (idTokenResult) => {
            setAuthState((prev) => ({
              ...prev,
              claims: idTokenResult.claims,
            }));

            handleDashboardRedirect(idTokenResult.claims);

            const batch = firebase.db.batch();
            const today = new Date();

            await batch.update(firebase.user(authUser.uid), {
              lastLogin: today,
            });
            //TODO: Change date to be YYYY/MM/DD (requires cloud function for fixing old ones)
            await batch.set(
              firebase.setting("HQlogins"),
              {
                [`${today.getDate()}-${
                  today.getMonth() + 1
                }-${today.getFullYear()}`]:
                  firebase.firestore.FieldValue.arrayUnion(authUser.uid),
              },
              { merge: true }
            );
            await batch.commit();
          });

        setAuthState((prev) => ({
          ...prev,
          user: authUser,
          userId: authUser.uid,
        }));
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [firebase]);

  useEffect(() => {
    const unsubscribe = authState.userId
      ? firebase.user(authState.userId).onSnapshot((snap) =>
          setUserState({
            id: snap.id,
            ...snap.data(),
            isBirthday: isBirthday(snap.data().birthdate),
          })
        )
      : () => console.log("waiting for auth");

    return () => unsubscribe();
  }, [authState, firebase]);

  var isBirthday = (timestamp) => {
    if (!timestamp) return false;
    var now = new Date();
    var birthday = timestamp.toDate();

    if (
      now.getMonth() === birthday.getMonth() &&
      now.getDate() === birthday.getDate()
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (
      userState?.firstLogin &&
      (window.location.pathname !== "/first-login" ||
        window.location.pathname === "/")
    ) {
      window.location.replace("/first-login");
    }
  }, [userState]);

  return (
    <UserContext.Provider value={userState}>
      <AuthContext.Provider value={{ ...authState, updateFirstLogin, loading }}>
        {children}
      </AuthContext.Provider>
    </UserContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
export const useUserContext = () => useContext(UserContext);
