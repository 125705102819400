import React, { useState } from "react";
import styled from "@emotion/styled";
import { Ring } from "react-awesome-spinners";
import { primary } from "../../theme";

export default function BasicImageUpload({ addImage }) {
  //image uploading taken from this codepen: https://codepen.io/team/Cloudinary/pen/QgpyOK

  const [loading, setLoading] = useState(false);

  const fileInput = React.createRef();

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const dt = e.dataTransfer;
    const files = dt.files;
    handleFiles(files);
  };

  const handleDrag = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleFiles = (files) => {
    //files is FileList not array - forEach does not work
    for (var i = 0; i < files.length; i++) {
      uploadFile(files[i]);
    }
  };

  const uploadFile = (file) => {
    const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    setLoading(true);
    // Update progress (can be used to show progress indicator)
    /*xhr.upload.addEventListener('progress', function(e) {
			const progress = Math.round((e.loaded * 100.0) / e.total);
			document.getElementById('progress').style.width = progress + '%';

			console.log(`fileuploadprogress data.loaded: ${e.loaded},
  data.total: ${e.total}`);
		}); */

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully
        setLoading(false);
        const response = JSON.parse(xhr.responseText);
        // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg

        const url = response.secure_url;
        addImage(url);
      }
    };

    fd.append("upload_preset", process.env.REACT_APP_PRESET);
    fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
    fd.append("file", file);
    xhr.send(fd);
  };

  return (
    <div>
      <DropArea
        onDrop={handleDrop}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
      >
        {loading ? (
          <Ring color="blue" />
        ) : (
          <>
            <p>Drag and drop an image here</p>
            <p>Or</p>
            <FileInput
              id="file"
              type="file"
              multiple
              accept="image/*"
              onChange={() => handleFiles(fileInput.current.files)}
              ref={fileInput}
            />
            <FileInputLabel className="ui button" for="file">
              Choose a file
            </FileInputLabel>
          </>
        )}
      </DropArea>
    </div>
  );
}

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const DropArea = styled.div`
  width: calc(100% - 4em);
  height: 150px;
  border: 5px dashed lightgrey;
  border-radius: 5px;
  display: grid;
  align-content: center;
  justify-content: center;
  margin-bottom: 1em;
  text-align: center;
  padding: 2em;
`;

const FileInputLabel = styled.label`
  display: block;
  max-width: 200px;
  margin: auto;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.7em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.7em - 1px);
  text-align: center;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors[primary].shade["500"]};
  color: ${(props) => props.theme.colors[primary].font["500"]};
  font-weight: bold;
  :hover {
    background-color: ${(props) => props.theme.colors[primary].shade["900"]};
  }
  :focus {
    outline: 0;
  }
  border-radius: 4px;
`;
