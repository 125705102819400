import React, { createContext, useContext, useEffect } from "react";
import { transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext, useUserContext } from "../auth";
import { set } from "date-fns";
import { formatPointsLog, numPoints, pointReport } from "./formatting";

const PointsContext = createContext({});

export const PointsProvider = ({ children }) => {
  const firebase = useFirebase();
  const { claims } = useAuthContext();
  const user = useUserContext();

  useEffect(() => {
    const addLoginPoint = async () => {
      const batch = firebase.db.batch();
      //Get date one millisecond after midnight today
      const date = set(new Date(), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 1,
      });
      //Get any points from today
      const todaysPointsSnap = await firebase
        .pointsLog()
        .where("userLink", "==", `${user.id}__${user.displayName}`)
        .where("dateReceived", ">=", date)
        .get();
      const todaysPoints = transformCollectionSnap(todaysPointsSnap);
      console.log(todaysPoints);

      //Find login point for today
      const loginPoint = todaysPoints?.find((p) => p.reason === "login");
      //Add login point if there isn't one
      if (!loginPoint) {
        const reason = "login";
        await batch.update(firebase.user(user.id), {
          points: firebase.firestore.FieldValue.increment(numPoints(reason)),
        });
        await batch.set(
          firebase.pointsLog().doc(),
          pointReport({
            reason,
            userLink: `${user.id}__${user.displayName}`,
          })
        );
        return await batch.commit();
      }
    };
    //If system uses points assign the point
    if (user && process.env.REACT_APP_EARN_POINTS === "true") {
      addLoginPoint();
    }
  }, [firebase, user]);

  const getUserPointsLog = (user, setPointsLog) => {
    return user
      ? firebase
          .pointsLog()
          .where("userLink", "==", `${user.id}__${user.displayName}`)
          .onSnapshot((snap) =>
            setPointsLog(formatPointsLog(transformCollectionSnap(snap)))
          )
      : () => console.log("not logged in");
  };

  const getWholePointsLog = (setPointsLog) => {
    return claims?.isAdmin
      ? firebase
          .pointsLog()
          .onSnapshot((snap) =>
            setPointsLog(formatPointsLog(transformCollectionSnap(snap)))
          )
      : () => console.log("not allowed");
  };

  const getMonthsPointsLog = (setPointsLog) => {
    const date = set(new Date(), { date: 1, hours: 0, minutes: 1, seconds: 0 });

    return claims?.isAdmin
      ? firebase
          .pointsLog()
          .where("dateReceived", ">=", date)
          .onSnapshot((snap) =>
            setPointsLog(formatPointsLog(transformCollectionSnap(snap)))
          )
      : () => console.log("not logged in");
  };

  const getTodaysPointsLog = (setPointsLog) => {
    const date = set(new Date(), { hours: 0, minutes: 1, seconds: 0 });

    return claims?.isAdmin
      ? firebase
          .pointsLog()
          .where("dateReceived", ">=", date)
          .onSnapshot((snap) =>
            setPointsLog(formatPointsLog(transformCollectionSnap(snap)))
          )
      : () => console.log("not logged in");
  };

  //Get shop orders to work out number of points spent
  const getMonthsOrders = (setMonthsOrders) => {
    const date = set(new Date(), { date: 1, hours: 0, minutes: 1, seconds: 0 });
    return claims?.isAdmin
      ? firebase.db
          .collection("shopOrders")
          .where("dateCreated", ">=", date)
          .onSnapshot((snap) => setMonthsOrders(transformCollectionSnap(snap)))
      : () => console.log("not admin");
  };

  const updatePoints = async ({ points, description, user }) => {
    const batch = firebase.db.batch();

    await batch.update(firebase.user(user.id), {
      points: firebase.firestore.FieldValue.increment(points),
    });
    await batch.set(firebase.pointsLog().doc(), {
      reason: "manual_assignment",
      numPoints: points,
      dateReceived: new Date(),
      description,
      userLink: `${user.id}__${user.displayName}`,
    });

    return await batch.commit();
  };

  return (
    <PointsContext.Provider
      value={{
        getUserPointsLog,
        getWholePointsLog,
        getMonthsOrders,
        getMonthsPointsLog,
        getTodaysPointsLog,
        updatePoints,
      }}
    >
      {children}
    </PointsContext.Provider>
  );
};

export const PointsConsumer = PointsContext.Consumer;

export const usePoints = () => useContext(PointsContext);
