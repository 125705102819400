export const formatCompliment = ({ compliment, postingUser, forUser }) => {
  return {
    content: compliment,
    datePosted: new Date(),
    likes: [],
    postedBy: `${postingUser.id}__${postingUser.displayName}`,
    for: `${forUser.id}__${forUser.displayName}`,
    isApproved: false,
  };
};
