import React from "react";
import {
  Card,
  Divider,
  Flex,
  Button,
  Label,
  Checkbox,
  Image,
} from "@theme-ui/components";
import SliderItem from "./SliderItem";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import { useSliders } from "../../../app/context/sliders";
import { StyledLabel } from "../../../app/DSL/components/elements/styled";
import styled from "@emotion/styled";
import ReactTooltip from "react-tooltip";

export default function SliderCard({ slider, setView, setSelectedSlider }) {
  const { updateSlider } = useSliders();
  return (
    <Card style={{ padding: "1em", position: "relative" }}>
      <SliderItem slider={slider} thumbnail />
      <Divider />
      <Flex style={{ justifyContent: "space-between", alignItems: "center" }}>
        <Flex>
          <Button
            onClick={() => {
              setView("edit");
              setSelectedSlider(slider);
            }}
          >
            Edit
          </Button>
          <InputWrap style={{ marginLeft: "1em" }}>
            <Label variant="radioLabel">
              <Checkbox
                checked={slider.isPublished}
                onChange={(e) => {
                  updateSlider({ ...slider, isPublished: !slider.isPublished });
                }}
              />
              Publish
            </Label>
          </InputWrap>
        </Flex>
        <div className="not-mobile">
          <StyledLabel
            style={{ marginLeft: "1em" }}
            colour={slider.isPublished ? "lightGreen" : "tusk"}
            textColour={slider.isPublished ? "white" : "grey"}
          >
            {slider.isPublished ? "Published" : "Draft"}
          </StyledLabel>
        </div>
      </Flex>
      <PlatformImage
        data-tip={slider?.platform?.label ? slider?.platform?.label : "HQ"}
        data-for="platform"
        src={
          slider.platform
            ? `/assets/${slider.platform.value}.svg`
            : "/assets/HQ.svg"
        }
      />
      <ReactTooltip id="platform" effect="solid" />
    </Card>
  );
}

const PlatformImage = styled(Image)`
  position: absolute;
  top: 1em;
  right: 1em;
  width: 35px;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
`;
