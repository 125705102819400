import React, { createContext, useContext, useState, useEffect } from "react";
import { transformCollectionSnap, transformDocSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext } from "../auth";

import { dbUsers, birthdays } from "../data/birthdays";

const UsersContext = createContext({});

export const UsersProvider = ({ children }) => {
  const firebase = useFirebase();
  const [users, setUsers] = useState([]);
  const [stats, setStats] = useState({});
  const { user } = useAuthContext();

  useEffect(() => {
    const unsubscribe = user
      ? firebase
          .users()
          .onSnapshot((snap) =>
            setUsers(
              transformCollectionSnap(snap).map((u) =>
                u.regions ? { ...u, region: u.regions[0] } : u
              )
            )
          )
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  const convertBirthdays = () => {
    /* const birthdays = rawBirthdays.map((r) => {
      const comma = r.name.indexOf(",");
      const lastName = r.name.substring(0, comma);
      const firstName = r.name.substring(comma + 2);
      const birthdateString = `${r.Year} ${r.Month} ${r.Date}`;
      return {
        lastName,
        firstName,
        birthdateString,
        birthdate: new Date(birthdateString),
        displayName: `${firstName} ${lastName}`,
      };
    }); */
    const withBirthdays = birthdays.map((b) => b.displayName);

    const usersNoBirthday = dbUsers
      .filter((u) => !withBirthdays.includes(u.displayName))
      .map(({ displayName }) => ({
        displayName,
      }));
    console.log(usersNoBirthday);
  };

  //Gets user login stats
  useEffect(() => {
    const unsubscribe = user
      ? firebase.setting("HQlogins").onSnapshot((snap) => {
          const logins = transformDocSnap(snap);
          const today = new Date();
          const todaysLogins =
            logins[
              `${today.getDate()}-${
                today.getMonth() + 1
              }-${today.getFullYear()}`
            ];
          setStats((prev) => ({ ...prev, todaysLogins }));
        })
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  return (
    <UsersContext.Provider value={{ users, convertBirthdays, stats }}>
      {children}
    </UsersContext.Provider>
  );
};

export const UsersConsumer = UsersContext.Consumer;

export const useUsers = () => useContext(UsersContext);
