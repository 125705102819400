import React from "react";
import { Card } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import styled from "@emotion/styled";
import { useSliders } from "../../../app/context/sliders";
import SliderCard from "./SliderCard";
import { Ring } from "react-awesome-spinners";
import colours from "../../../app/DSL/styles/colours";
import { primary } from "../../../app/DSL/theme";

export default function ViewSliders({ setView, setSelectedSlider }) {
  const { sliders } = useSliders();
  return (
    <Card>
      <BlockHeaderContainer
        title={`Showing ${
          sliders?.length ? sliders?.filter((s) => !s.isArchived).length : 0
        } Slider${sliders?.length === 1 ? "" : "s"}`}
        actionType="button"
        action={() => setView("create")}
        buttonText="Create Slider"
      />
      {sliders ? (
        <Grid>
          {sliders
            .filter((s) => !s.isArchived)
            .map((s, idx) => (
              <SliderCard
                key={idx}
                slider={s}
                setView={setView}
                setSelectedSlider={setSelectedSlider}
              />
            ))}
        </Grid>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Ring color={colours[primary].shade["500"]} />
        </div>
      )}
    </Card>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
  max-height: 60vh;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 1em;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
