import React from "react";
import SliderForm from "./SliderForm";
import { Card } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { useSliders } from "../../../app/context/sliders";
import { useToasts } from "react-toast-notifications";

export default function CreateSlider({ setView }) {
  const { createSlider } = useSliders();
  const { addToast } = useToasts();
  const submit = (values, isPublished) => {
    createSlider({ ...values, isPublished }).then(() => {
      addToast("Slider Created", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
      setView("view");
    });
  };
  return (
    <Card>
      <BlockHeaderContainer
        title="Create Slider"
        actionType="button"
        action={() => setView("view")}
        buttonText="Back to sliders"
      />
      <SliderForm
        cancel={() => setView("view")}
        submit={submit}
        buttonText="Create Slider"
      />
    </Card>
  );
}
