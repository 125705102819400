import React from "react";
import { useTable, usePagination, useSortBy, useFilters } from "react-table";
import { RoundButton, InputPlaceholder, EmptyTableState } from "./styled";
import matchSorter from "match-sorter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Select } from "@theme-ui/components";

//TODO: cache filters/show option

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <Input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} documents...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

export default function Table({
  columns,
  data,
  loading,
  defaultRows,
  pagination,
  emptyMsg,
  emptyIcon,
  filterPlaceholder,
  hideFilters
}) {
  const options = [
    {
      key: 5,
      text: "Show 5",
      value: 5,
    },
    {
      key: 10,
      text: "Show 10",
      value: 10,
    },
    {
      key: 20,
      text: "Show 20",
      value: 20,
    },
    {
      key: 30,
      text: "Show 30",
      value: 30,
    },
    {
      key: 40,
      text: "Show 40",
      value: 40,
    },
    {
      key: 50,
      text: "Show 50",
      value: 50,
    },
  ];

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: defaultRows },
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const handleChange = (e) => setPageSize(e.target.value);
  let numCols = 0;
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => {
                numCols = headerGroup.headers.length;
                return (
                  <th key={idx} {...column.getHeaderProps()}>
                    {/* Add a sort direction indicator */}
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={["fal", "angle-up"]} />
                        ) : (
                          <FontAwesomeIcon icon={["fal", "angle-down"]} />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                    <div>
                      {column.canFilter ? (
                        column.render("Filter")
                      ) : (
                        hideFilters ? 
                        null:
                        <InputPlaceholder>-</InputPlaceholder>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        {loading ? (
          <tbody>
            <tr>
              <EmptyTableState colSpan={numCols}>
                <FontAwesomeIcon icon={["fal", "spinner"]} spin />
                <p>Loading...</p>
              </EmptyTableState>
            </tr>
          </tbody>
        ) : data.length === 0 ? (
          <tbody>
            <tr>
              <EmptyTableState colSpan={numCols}>
                <FontAwesomeIcon
                  icon={["fal", `${emptyIcon ? emptyIcon : "folder-open"}`]}
                  size="2x"
                />
                <h3>{emptyMsg ? emptyMsg : "Nothing here"}</h3>
              </EmptyTableState>
            </tr>
          </tbody>
        ) : (
          <tbody {...getTableBodyProps()}>
            {page.map((row, idx) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={idx}>
                  {row.cells.map((cell, idx) => (
                    <td {...cell.getCellProps()} key={idx}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {(pagination || pagination === undefined) && (
        <div className="pagination">
          <RoundButton
            color="orange"
            onClick={() => gotoPage(0)}
            variant={!canPreviousPage ? "disabled" : "primary"}
            icon
          >
            <FontAwesomeIcon icon={["fal", "angle-double-left"]} />
          </RoundButton>
          <RoundButton
            color="orange"
            onClick={() => previousPage()}
            variant={!canPreviousPage ? "disabled" : "primary"}
            icon
          >
            <FontAwesomeIcon icon={["fal", "angle-left"]} />
          </RoundButton>
          <span className="controls not-mobile">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span className="divider">|</span>
            <span>Go to page: </span>
            <Input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
            <Select value={pageSize} selection onChange={handleChange}>
              {options &&
                options.map((o) => (
                  <option value={o.value} key={o.key}>
                    {o.text}
                  </option>
                ))}
            </Select>
          </span>
          <div className="mobile">
            <p>Page</p>
            <p>
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </p>
          </div>
          <RoundButton
            color="orange"
            onClick={() => nextPage()}
            variant={!canNextPage ? "disabled" : "primary"}
            icon
          >
            <FontAwesomeIcon icon={["fal", "angle-right"]} />
          </RoundButton>
          <RoundButton
            color="orange"
            onClick={() => gotoPage(pageCount - 1)}
            variant={!canNextPage ? "disabled" : "primary"}
            icon
          >
            <FontAwesomeIcon icon={["fal", "angle-double-right"]} />
          </RoundButton>
        </div>
      )}
    </>
  );
}
