import React, { useState, useEffect, useMemo } from "react";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import styled from "@emotion/styled";
import { Card, Divider } from "@theme-ui/components";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { useNewsflashes } from "../../app/context/newsflash";
import NewsflashCard from "./NewsflashCard";
import { NavLink } from "react-router-dom";
import ArticleView from "./ArticleView";
import { SeeAllLink } from "../../app/DSL/components/elements/styled";
import { isBefore } from "date-fns";

export default function Newsflash({ match }) {
  var { state } = useNewsflashes();
  const newsflashList = useMemo(
    () =>
      state
        ? state
            .sort((a, b) =>
              isBefore(a.createdAt.toDate(), b.createdAt.toDate()) ? 1 : -1
            )
            .filter((s) => s.isPublished)
        : [],
    [state]
  );

  const [newsflash, setNewsflash] = useState();
  useEffect(() => {
    var id = match?.params?.id;
    if (id) {
      const flash = newsflashList.find((n) => n.id === id);
      if (flash) {
        setNewsflash(flash);
      } else {
        const allFlash = newsflashList.find((n) => n.id === parseInt(id));
        if (allFlash) {
          setNewsflash(allFlash);
        }
      }
    } else {
      setNewsflash(newsflashList?.length > 0 ? newsflashList[0] : {});
    }
  }, [match, newsflashList]);
  return (
    <div>
      <PageHeaderContainer>Newsflash</PageHeaderContainer>
      <Grid>
        <Card>
          <ArticleView newsflash={newsflash} />
        </Card>
        <Card style={{ position: "relative" }}>
          <BlockHeaderContainer title="Other articles" />
          <Articles>
            {newsflashList.slice(0, 3).map((n) => (
              <NewsflashCard newsflash={n} key={n.id} />
            ))}
          </Articles>
          <SeeAllLink>
            <Divider />
            <NavLink to="/newsflash/archive">See all</NavLink>
          </SeeAllLink>
        </Card>
      </Grid>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 1em;
  align-items: start;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;

const Articles = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1em;
  row-gap: 1em;
  align-items: start;
  margin-bottom: 3em;
  @media (max-width: 1366px) and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
