import React, { useState } from "react";

/**
 * Will initialize state with first value it recieves
 * that satisfies val => Boolean(val)
 * @param {*} val initially empty value
 */
export default function useDelayedState(val) {
  var [init, setInit] = useState(false);
  var [value, setValue] = useState();
  React.useEffect(() => {
    if (!init && val) {
      setInit(true);
      setValue(val);
    }
  }, [val, init]);
  return [value, setValue];
}
