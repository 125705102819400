import React, { useState } from "react";
import styled from "@emotion/styled";
import { Card, Image, Heading, Button, Flex } from "@theme-ui/components";
import { ImageBlock } from "../../app/DSL/components/elements/styled";
import { useUserContext } from "../../app/context/auth";
import ImageUploadModal from "../Profile/ImageUploadModal";
import { useFirebase } from "../../app/context/Firebase";
import { useToasts } from "react-toast-notifications";

export default function FirstImageUpload() {
  const user = useUserContext();
  const [modalOpen, setModalOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState();
  const firebase = useFirebase();
  const { addToast } = useToasts();
  const removeImage = () => {
    return firebase
      .user(user.id)
      .update({
        profileImageUrl: firebase.firestore.FieldValue.delete(),
      })

      .then(() => {
        addToast("Removed Successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
        });
      })
      .catch((error) => {
        //setLoading(false);

        //setError(error);
        console.log(error);
      });
  };
  return (
    <LoginBox>
      <LoginCard sx={{ textAlign: "center" }}>
        <div>
          <Image src="/logo.svg" variant="login" />
          <Heading as="h1" variant="display">
            {process.env.REACT_APP_SITE_TITLE.toUpperCase()}
          </Heading>
        </div>
        <Heading as="h4" color="blue.font.500">
          Update your display picture
        </Heading>
        <Grid>
          <ImageBlock
            size="250px"
            style={{ marginBottom: "1em" }}
            url={
              user && user.profileImageUrl
                ? user.profileImageUrl
                : "/user-circle-solid.svg"
            }
          >
            <div className="img"></div>
          </ImageBlock>
          <div>
            <p>
              {user && user.profileImageUrl
                ? `It looks like you already have a display picture on ${process.env.REACT_APP_SITE_TITLE}! You can remove this image or update it below`
                : "Please add a profile image below"}
            </p>
            {user && user.profileImageUrl ? (
              <Flex style={{ justifyContent: "space-between" }}>
                <Button
                  onClick={() => setModalOpen(true)}
                  key="chnage"
                  style={{ alignSelf: "end" }}
                >
                  Change Image
                </Button>
                <Button
                  onClick={() => removeImage()}
                  key="remove"
                  style={{ alignSelf: "start" }}
                >
                  Remove Image
                </Button>
              </Flex>
            ) : (
              <Button onClick={() => setModalOpen(true)}>Add Image</Button>
            )}
          </div>
        </Grid>
        <Button
          style={{ justifySelf: "flex-end" }}
          variant={user?.profileImageUrl ? "primary" : "disabled"}
          onClick={() => window.location.replace("/dashboard")}
        >
          Done
        </Button>
        <p
          onClick={() => window.location.replace("/dashboard")}
          style={{ textAlign: "right", cursor: "pointer" }}
        >
          Skip
        </p>
      </LoginCard>
      <ImageUploadModal isOpen={modalOpen} setOpen={setModalOpen} user={user} />
    </LoginBox>
  );
}

const LoginBox = styled.div`
  background-color: ${(props) => props.theme.colors.tusk.shade["500"]};
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-content: center;
  @media (max-width: 768px) {
    align-content: flex-start;
  }
`;

const LoginCard = styled(Card)`
  width: 50%;
  padding: 2em;
  display: grid;
  grid-gap: 24;
  grid-template-rows: 150px 100px 1fr 70px;
  align-content: center;
  align-items: center;
  & div:first-of-type {
    align-self: end;
  }
  @media (max-width: 768px) {
    grid-template-rows: 100px 100px 1fr 50px;
    width: 90%;
    margin: 5em 0;
    padding: 2em;

    img {
      width: 40%;
      margin-bottom: 0;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  align-items: center;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
    margin-bottom: 1em;
  }
`;
