import React, { useState } from "react";
import styled from "@emotion/styled";
import { Collapse } from "react-collapse";
import { Card, Divider } from "@theme-ui/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AccordionItem({ title, content }) {
    const [opened, setOpened] = useState(false);
    return (
        <Item>
            <div onClick={() => setOpened(!opened)}>
                <h4>{title}</h4>
                {opened ? (
                    <FontAwesomeIcon icon={["fal", "angle-up"]} />
                ) : (
                    <FontAwesomeIcon icon={["fal", "angle-down"]} />
                )}
            </div>
            <Collapse isOpened={opened}>
                <div>
                    <Divider />
                    {content}
                </div>
            </Collapse>
        </Item>
    );
}

const Item = styled(Card)`
    padding: 1em;
    margin-bottom: 1em;
    > div {
        cursor: pointer;
        align-content: center;
        display: grid;
        grid-template-columns: auto 20px;
        column-gap: 1em;
        h4,
        svg {
            margin: 0.5em 0;
        }
    }
`;
