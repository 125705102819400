import React, { createContext, useState, useContext, useEffect } from "react";
import { UsersProvider } from "../users";
import { HospitalsProvider } from "../hospitals";
import { DoctorsProvider } from "../doctors";
import { ComplimentsProvider } from "../compliments";
import { PointsProvider } from "../points";
import { MessagesProvider } from "../messages";
import { NewsflashesProvider } from "../newsflash";
import { MenuProvider } from "../menu";
import { SlidersProvider } from "../sliders";
import { PermissionsProvider } from "../permissions";

export const DataProvider = ({ children }) => {
  return (
    <MenuProvider>
      <UsersProvider>
        <HospitalsProvider>
          <DoctorsProvider>
            <ComplimentsProvider>
              <PointsProvider>
                <MessagesProvider>
                  <NewsflashesProvider>
                    <SlidersProvider>
                      <PermissionsProvider>{children}</PermissionsProvider>
                    </SlidersProvider>
                  </NewsflashesProvider>
                </MessagesProvider>
              </PointsProvider>
            </ComplimentsProvider>
          </DoctorsProvider>
        </HospitalsProvider>
      </UsersProvider>
    </MenuProvider>
  );
};

const LoadingContext = createContext({});

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadingArr = [];
    console.log(loadingArr);
    setLoading(
      !loadingArr.every((l) => {
        return l === false;
      })
    );
  }, []);

  return (
    <LoadingContext.Provider value={{ loading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const LoadingConsumer = LoadingContext.Consumer;

export const useLoading = () => useContext(LoadingContext);
