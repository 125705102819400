import React, { useState } from "react";
import CardModal from "../../../app/DSL/components/elements/CardModal";
import ImageUpload from "../../../app/DSL/components/elements/ImageUpload";
import { Input, Label, Button, Flex } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import copy from "copy-to-clipboard";
import styled from "@emotion/styled";

export default function ImageToLinkModal({ isOpen, close }) {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [copied, setCopied] = useState(false);

  const uploadFile = async (file) => {
    const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    setLoading(true);

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully

        const response = JSON.parse(xhr.responseText);
        // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg

        setUrl(response.secure_url);
        setLoading(false);
        return response.secure_url;
      }
    };

    fd.append("upload_preset", process.env.REACT_APP_PRESET);
    fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
    fd.append("file", file);
    xhr.send(fd);
  };

  return (
    <CardModal
      isOpen={isOpen}
      close={() => {
        close();
        setCopied(false);
      }}
    >
      <div>
        <BlockHeaderContainer title="Upload image for link" />
        {url ? (
          <div>
            <h3>Copy this link to add the image to the editor below</h3>
            <InputWrap>
              <Label>Image link</Label>
              <Grid>
                <Input value={url} readOnly />
                <Button
                  style={{ width: "fit-content" }}
                  onClick={() =>
                    copy(url, { debug: true, onCopy: setCopied(true) })
                  }
                >
                  {copied ? "Link Copied" : "Copy Link"}
                </Button>
              </Grid>
            </InputWrap>
          </div>
        ) : (
          <ImageUpload
            uploading={loading}
            upload={(file) => uploadFile(file)}
            image={url}
            name="link"
            autoSize
            fullwidth
          />
        )}
        <Flex style={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => {
              close();
              setCopied(false);
            }}
            variant="outline"
          >
            Cancel
          </Button>
          <div>
            {url && (
              <Button onClick={() => setUrl(null)}>Upload another image</Button>
            )}

            <Button
              style={{
                marginLeft: "10px",
              }}
              onClick={() => {
                close();
                setCopied(false);
              }}
            >
              Done
            </Button>
          </div>
        </Flex>
      </div>
    </CardModal>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1em;
  margin-bottom: 2em;
`;
