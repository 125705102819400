import React from "react";
import styled from "@emotion/styled";
import { Card, Image, Divider } from "@theme-ui/components";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { useUserContext } from "../../app/context/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPointString } from "../../app/context/points/formatting";
import DashNewsflash from "./DashNewsflash";
import Slider from "./Slider";
import PlatformLinks from "./PlatformLinks";
import AddMessage from "./AddMessage";
import { SeeAllLink } from "../../app/DSL/components/elements/styled";
import { NavLink } from "react-router-dom";
import { Ring } from "react-awesome-spinners";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";
//import BirthdayNotification from "./BirthdayNotification";

const Dashboard = () => {
  const user = useUserContext();
  return (
    <div>
      {/* TODO: release birthday message */}
      {/* uncomment bellow to get birthday-features */}
      {/* <BirthdayNotification user={user} /> */}

      <PageHeaderContainer image>
        <Image
          src={`/assets/${
            process.env.REACT_APP_LOGO
              ? process.env.REACT_APP_LOGO
              : "long-logo.svg"
          }`}
          width="390px"
        />
      </PageHeaderContainer>
      <TopGrid>
        <Card>
          <BlockHeaderContainer
            title={`Welcome ${user?.displayName ? user?.displayName : "back"}`}
          />
          {user ? (
            <div>
              {process.env.REACT_APP_VIEW_POINTS === "true" &&
                (user?.points ? (
                  <Points>
                    <h1>
                      <FontAwesomeIcon icon={["far", "star"]} />{" "}
                      {formatPointString(user.points, "spaces")}{" "}
                    </h1>
                    <h2>POINTS</h2>
                  </Points>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Ring color={colours[primary].shade["500"]} />
                  </div>
                ))}
              {(process.env.REACT_APP_SHOP === "true" ||
                process.env.REACT_APP_BROCHURE === "true" ||
                process.env.REACT_APP_WAREHOUSE === "true" ||
                process.env.REACT_APP_SALES === "true") && (
                <div>
                  <BlockHeaderContainer title="Platforms" />
                  <PlatformLinks />
                </div>
              )}
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Ring color={colours[primary].shade["500"]} />
            </div>
          )}
        </Card>
        {process.env.REACT_APP_SLIDERS === "true" && (
          <Card>
            <Slider />
          </Card>
        )}
      </TopGrid>

      <BotGrid>
        {process.env.REACT_APP_NEWSFLASH === "true" && (
          <Card style={{ position: "relative" }}>
            <BlockHeaderContainer title="Newsflash" />
            <DashNewsflash />
            <SeeAllLink>
              <Divider />
              <NavLink to="/newsflash/archive">See all</NavLink>
            </SeeAllLink>
          </Card>
        )}
        {process.env.REACT_APP_MESSAGES === "true" && (
          <Card>
            <BlockHeaderContainer title="Need help?" />
            <AddMessage />
          </Card>
        )}
      </BotGrid>
    </div>
  );
};

export default Dashboard;
const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 1em;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;

const BotGrid = styled.div`
  display: flex;
  flex: 2 1;
  column-gap: 1em;
  margin: 1em 0;
  @media (max-width: 1025px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
const Points = styled.div`
  text-align: center;
  h1 {
    svg {
      color: ${(props) => props.theme.colors.orange.shade["500"]};
      margin-right: 5px;
    }
    font-size: 3em;
    margin-bottom: 0;
  }
  h2 {
    font-size: 2em;
    margin-top: 0;
  }
`;
