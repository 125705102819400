import React, { useState, useMemo, useEffect } from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import styled from "@emotion/styled";
import {
  Card,
  Label,
  Input,
  Checkbox,
  Heading,
  Divider,
} from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import {
  InputWrap,
  IconLeft,
} from "../../../app/DSL/components/form/StyledInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateInput from "../../../app/DSL/components/form/DateInput";
import { isSameDay } from "date-fns";
import { useMessages } from "../../../app/context/messages";
import MessageList from "./MessageList";
import { Ring } from "react-awesome-spinners";
import colours from "../../../app/DSL/styles/colours";
import { primary } from "../../../app/DSL/theme";
import { customStyles } from "../../../app/forms/Form";
import Select from "react-select";
import { platforms } from "../../../app/context/data/platforms";
import { useHistory } from "react-router-dom";

export default function Messages() {
  const [search, setSearch] = useState("");
  const [date, setDate] = useState();
  const [onlyUnread, setOnlyUnread] = useState(false);
  const [type, setType] = useState();
  const [platform, setPlatform] = useState();
  const { messages } = useMessages();
  const history = useHistory();
  const platformOptions = [
    { label: "HQ", value: "HQ" },
    ...platforms

      .filter((p) => p.active)
      .map((p) => ({
        label: p.title,
        value: p.name,
      })),
  ];

  const typeOptions = [
    { label: "I need help", value: "help" },
    { label: "I have a feature suggestion", value: "feature" },
    { label: "I have encountered a problem", value: "bug" },
    { label: "Other", value: "other" },
  ];

  useEffect(() => {
    if (history.location.search === "?today") {
      setDate(new Date());
    }
  }, [history]);
  const messageList = useMemo(
    () =>
      messages
        ? messages
            .filter((m) => {
              return (
                m.content.toLowerCase().includes(search) ||
                m.sentBy?.name.toLowerCase().includes(search)
              );
            })
            .filter((m) =>
              date ? isSameDay(m.datePosted.toDate(), date) : true
            )
            .filter((m) => (type ? m.type.value === type.value : true))
            .filter((m) =>
              platform ? m.platform.value === platform.value : true
            )
            .filter((m) => (onlyUnread ? !m.isRead : true))
        : [],
    [search, messages, date, onlyUnread, type, platform]
  );
  return (
    <div>
      <PageHeaderContainer>Manage Messages</PageHeaderContainer>
      <Grid>
        <Card>
          <BlockHeaderContainer title="Find Messages" />
          {/* TODO: Extract filters to component */}
          <InputWrap icon="left">
            <Label>Search messages</Label>
            <IconLeft>
              <FontAwesomeIcon icon={["fas", "search"]} />
            </IconLeft>
            <Input
              placeholder="Search messages"
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputWrap>
          <InputWrap>
            <Label>Reason</Label>
            <Select
              styles={customStyles}
              isSearchable
              isClearable
              options={typeOptions}
              value={type}
              placeholder="Select a reason"
              onChange={(option) => setType(option)}
            />
          </InputWrap>
          <InputWrap>
            <Label>Platform</Label>
            <Select
              styles={customStyles}
              isSearchable
              isClearable
              options={platformOptions}
              value={platform}
              placeholder="Select a platform"
              onChange={(option) => setPlatform(option)}
            />
          </InputWrap>

          <InputWrap style={{ margin: "1em 0 2em" }}>
            <Label>Date Posted</Label>
            <DateInput onChange={(d) => setDate(d)} value={date} />
          </InputWrap>
        </Card>
        <Card>
          <BlockHeaderContainer
            classes="not-mobile"
            title={`Showing ${messageList.length} message${
              messageList.length === 1 ? "" : "s"
            }`}
            rightComponent={
              <InputWrap>
                <Label variant="radioLabel" style={{ textTransform: "none" }}>
                  <Checkbox
                    checked={onlyUnread}
                    onChange={(e) => {
                      setOnlyUnread(!onlyUnread);
                    }}
                  />
                  Only show unread messages?
                </Label>
              </InputWrap>
            }
          />
          <div className="mobile" style={{ marginBottom: "1em" }}>
            <Heading>
              Showing {messageList.length} message
              {messageList.length === 1 ? "" : "m"}
            </Heading>
            <InputWrap>
              <Label variant="radioLabel" style={{ textTransform: "none" }}>
                <Checkbox
                  checked={onlyUnread}
                  onChange={(e) => {
                    setOnlyUnread(!onlyUnread);
                  }}
                />
                Only show unread messages?
              </Label>
            </InputWrap>
            <Divider />
          </div>
          {messages ? (
            <MessageList messages={messageList} />
          ) : (
            <div style={{ textAlign: "center" }}>
              <Ring color={colours[primary].shade["500"]} />
            </div>
          )}
        </Card>
      </Grid>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 1em;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
