import React, { createContext, useContext, useState, useEffect } from "react";
import { transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext } from "../auth";

const HospitalsContext = createContext({});

export const HospitalsProvider = ({ children }) => {
  const firebase = useFirebase();
  const [hospitals, setHospitals] = useState();
  const { user } = useAuthContext();

  useEffect(() => {
    const unsubscribe = user
      ? firebase
          .hospitals()
          .onSnapshot((snap) => setHospitals(transformCollectionSnap(snap)))
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  return (
    <HospitalsContext.Provider value={{ hospitals }}>
      {children}
    </HospitalsContext.Provider>
  );
};

export const HospitalsConsumer = HospitalsContext.Consumer;

export const useHospitals = () => useContext(HospitalsContext);
