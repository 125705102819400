import React, { useState, useMemo } from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import {
    Card,
    Textarea,
    Label,
    Radio,
    Flex,
    Button,
} from "@theme-ui/components";
import styled from "@emotion/styled";
import FAQ from "./FAQ";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import { Collapse } from "react-collapse";
import { useFirebase } from "../../../app/context/Firebase";
import { useUserContext } from "../../../app/context/auth";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

export default function WarehouseHRSurveyForm() {
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(false);
    const { db } = useFirebase();
    const user = useUserContext();

    const { addToast } = useToasts();
    const history = useHistory();

    const canSubmit = useMemo(() => {
        if (values.member) {
            if (values.understood === "yes") {
                return true;
            } else if (values.understood === "no") {
                return values.questions?.length > 0;
            }
        } else {
            return false;
        }
    }, [values]);

    const submit = () => {
        setLoading(true);
        db.collection("Forms")
            .doc("HRSurvey-06-07-2020")
            .collection("submissions")
            .doc()
            .set({
                ...values,
                sentBy: {
                    displayName: user.displayName,
                    email: user.email,
                    id: user.id,
                },
            })
            .then((d) => {
                setLoading(false);
                addToast("Thank you! Your response has been recorded", {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                history.push("/dashboard");
            })
            .catch((err) => {
                setLoading(false);
                addToast("Oops! Something went wrong, please try again ", {
                    appearance: "warning",
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            });
    };
    return (
        <div>
            <PageHeaderContainer>Survey</PageHeaderContainer>
            <ImageHeader src="/assets/hr-survey-header.png" />
            <Card style={{ marginBottom: "1em", padding: "1em 2em" }}>
                <h4>
                    We have made a number of changes to our Gauteng Operation
                    Structure which are now being implemented. You continue to
                    remain an important part of our team and we can assure you
                    that the changes will bring many benefits, on both an
                    individual and organisational level. Naturally, you may have
                    questions or concerns regarding workplace issues such as;
                    contracts, benefits, tax and annual leave entitlement. We
                    are here to listen and answer any concerns that you may
                    have. Please take a moment to read through the frequently
                    asked questions section and then complete our quick survey
                    below.
                </h4>
            </Card>
            <Grid>
                <Card>
                    <BlockHeaderContainer title="Warehouse HR Survey - July 2020" />

                    <Fields>
                        <InputWrap>
                            <Label>Are you a member of UASA?</Label>
                            <Flex>
                                <InputWrap style={{ marginRight: "10px" }}>
                                    <Label
                                        variant="radioLabel"
                                        style={{ textTransform: "none" }}
                                    >
                                        <Radio
                                            name="member"
                                            value="yes"
                                            checked={values.member === "yes"}
                                            onChange={(e) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    member: "yes",
                                                }));
                                            }}
                                        />
                                        Yes
                                    </Label>
                                </InputWrap>
                                <InputWrap style={{ marginRight: "10px" }}>
                                    <Label
                                        variant="radioLabel"
                                        style={{ textTransform: "none" }}
                                    >
                                        <Radio
                                            name="member"
                                            value="no"
                                            checked={values.member === "no"}
                                            onChange={(e) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    member: "no",
                                                }));
                                            }}
                                        />
                                        No
                                    </Label>
                                </InputWrap>
                            </Flex>
                        </InputWrap>
                        <InputWrap>
                            <Label>
                                Was the message around the Operations
                                Reorganisation for Gauteng clear &
                                understandable?
                            </Label>
                            <Flex>
                                <InputWrap style={{ marginRight: "10px" }}>
                                    <Label
                                        variant="radioLabel"
                                        style={{ textTransform: "none" }}
                                    >
                                        <Radio
                                            name="understood"
                                            value="yes"
                                            checked={
                                                values.understood === "yes"
                                            }
                                            onChange={(e) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    understood: "yes",
                                                }));
                                            }}
                                        />
                                        Yes
                                    </Label>
                                </InputWrap>
                                <InputWrap style={{ marginRight: "10px" }}>
                                    <Label
                                        variant="radioLabel"
                                        style={{ textTransform: "none" }}
                                    >
                                        <Radio
                                            name="understood"
                                            value="no"
                                            checked={values.understood === "no"}
                                            onChange={(e) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    understood: "no",
                                                }));
                                            }}
                                        />
                                        No
                                    </Label>
                                </InputWrap>
                            </Flex>
                        </InputWrap>
                        <Collapse isOpened={values.understood === "no"}>
                            <InputWrap>
                                <Label>
                                    If No, list ALL the questions you have?
                                </Label>
                                <Textarea
                                    rows={5}
                                    value={values?.questions}
                                    onChange={(e) => {
                                        e.persist();
                                        setValues((prev) => ({
                                            ...prev,
                                            questions: e.target.value,
                                        }));
                                    }}
                                    placeholder="Your questions"
                                />
                            </InputWrap>
                        </Collapse>

                        <InputWrap>
                            <Label>
                                In terms of the proposed “New Structure” what
                                other proposals or recommendations do you have
                                that you would like the company to consider?
                            </Label>
                            <Textarea
                                rows={5}
                                value={values.recommendations}
                                onChange={(e) => {
                                    e.persist();
                                    setValues((prev) => ({
                                        ...prev,
                                        recommendations: e.target.value,
                                    }));
                                }}
                                placeholder="Your recommendations"
                            />
                        </InputWrap>
                    </Fields>
                    {loading ? (
                        <Button variant="disabled">Loading...</Button>
                    ) : (
                        <Button
                            onClick={submit}
                            variant={canSubmit ? "primary" : "disabled"}
                        >
                            Submit
                        </Button>
                    )}
                </Card>
                <Card>
                    <BlockHeaderContainer title="Gauteng Operations - Frequently asked questions" />
                    <FAQ />
                </Card>
            </Grid>
        </div>
    );
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
    row-gap: 1em;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const Fields = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    row-gap: 1em;
    margin-bottom: 1em;
`;

const ImageHeader = styled(Card)`
    width: 100%;
    height: 250px;
    background-image: url(${(props) => props.src});
    background-position: center;
    margin-top: -1em;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 1em;
`;
