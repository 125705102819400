import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faThumbsUp,
  faCalendarAlt,
  faStar,
  faLifeRing,
  faPortrait,
} from "@fortawesome/pro-regular-svg-icons";

library.add(faThumbsUp, faCalendarAlt, faStar, faLifeRing, faPortrait);

export { faThumbsUp, faCalendarAlt, faStar, faLifeRing, faPortrait };
