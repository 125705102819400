const primary = "darkBlue";
const forms = {
  input: {
    height: "2.5em",
    boxShadow: "inset 0 0.0625em 0.125em rgba(10,10,10,.05)",
    maxWidth: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderColor: "tusk.shade.500",
    borderRadius: 4,
    color: "#363636",
    paddingBottom: "calc(.5em - 1px)",
    paddingLeft: "calc(.75em - 1px)",
    paddingRight: "calc(.75em - 1px)",
    paddingTop: "calc(.5em - 1px)",
    font: "inherit",
    "&:active": {
      outline: 0,
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderColor: "tusk.shade.900",
      outline: 0,
    },
    "&:read-only": {
      backgroundColor: "transparent",
      borderColor: "tusk.shade.300",
      color: "midnight.shade.100",
      outline: 0,
    },
  },
  textarea: {
    boxShadow: "inset 0 0.0625em 0.125em rgba(10,10,10,.05)",
    maxWidth: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderColor: "tusk.shade.500",
    borderRadius: 4,
    color: "#363636",
    paddingBottom: "calc(.5em - 1px)",
    paddingLeft: "calc(.75em - 1px)",
    paddingRight: "calc(.75em - 1px)",
    paddingTop: "calc(.5em - 1px)",
    font: "inherit",
    "&:active": {
      outline: 0,
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderColor: "tusk.shade.900",
      outline: 0,
    },
    "&:read-only": {
      backgroundColor: "transparent",
      borderColor: "tusk.shade.300",
      color: "midnight.shade.100",
      outline: 0,
    },
  },
  select: {
    height: "2.5em",
    boxShadow: "inset 0 0.0625em 0.125em rgba(10,10,10,.05)",
    maxWidth: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderColor: "#dbdbdb",
    borderRadius: 4,
    color: "#363636",
    paddingBottom: "calc(.5em - 1px)",
    paddingLeft: "calc(.75em - 1px)",
    paddingRight: "calc(.75em - 1px)",
    paddingTop: "calc(.5em - 1px)",
    font: "inherit",
    "&:active": {
      outline: 0,
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderColor: "#b9b9b9",
      outline: 0,
    },
  },

  radio: {
    color: `${primary}.shade.600`,
    marginLeft: "10px",
  },
  checkbox: { color: `${primary}.shade.600` },
  infoLabel: {
    warning: {
      marginTop: "2px",
      fontSize: "0.8em",
      color: "warning.shade.500",
    },
    hidden: {
      marginTop: "2px",
      fontSize: "0.8em",
      color: "transparent",
    },
    fontSize: "0.8em",
  },
  label: {
    fontWeight: "bold",
    marginBottom: "0.5em",
    display: "block",
  },
  radioLabel: {
    fontWeight: "body",
    margin: 0,
  },
};
export default forms;
