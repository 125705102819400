import React from "react";
import StandardPageWrapper from "../../app/DSL/components/layout/StandardPageWrapper";

const Settings = () => {
	return (
		<StandardPageWrapper
			blockProps={{
				title: "Settings"
			}}
			mainHeader="Settings"
		></StandardPageWrapper>
	);
};

export default Settings;
