import React, { useMemo } from "react";
import { useNewsflashes } from "../../app/context/newsflash";
import styled from "@emotion/styled";
import NewsflashCard from "./NewsflashCard";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { Ring } from "react-awesome-spinners";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";
import { isBefore } from "date-fns";

export default function NewsflashArchive() {
  var { state } = useNewsflashes();
  const newsflashList = useMemo(
    () =>
      state
        ? state
            .sort((a, b) =>
              isBefore(a.createdAt.toDate(), b.createdAt.toDate()) ? 1 : -1
            )
            .filter((s) => s.isPublished)
        : [],
    [state]
  );
  return (
    <div>
      <PageHeaderContainer>Newsflash Archive</PageHeaderContainer>
      {newsflashList ? (
        <Grid>
          {newsflashList.map((n, idx) => (
            <NewsflashCard newsflash={n} key={idx} />
          ))}
        </Grid>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Ring color={colours[primary].shade["500"]} />
        </div>
      )}
    </div>
  );
}
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
  @media (max-width: 1023.9px) {
    grid-template-columns: 1fr;
  }
`;
