import { getObjFromLink } from "../helpers";

export const numPoints = (reason) => {
  switch (reason) {
    case "compliment__given":
      return 5;
    case "compliment__received":
      return 25;
    case "compliment__given__archived":
      return -5;
    case "compliment__received__archived":
      return -25;
    case "compliment__liked":
      return 1;
    case "compliment__unliked":
      return -1;
    case "login":
      return 1;
    case "joined":
      return 1;
    default:
      return 0;
  }
};

const getDescription = ({ reason, userTo, userFrom }) => {
  switch (reason) {
    case "compliment__given":
      return `Compliment given to ${getObjFromLink(userTo).name}`;
    case "compliment__received":
      return `Compliment received from ${getObjFromLink(userFrom).name}`;
    case "compliment__given__archived":
      return `Compliment to ${getObjFromLink(userTo).name} archived`;
    case "compliment__received__archived":
      return `Compliment from ${getObjFromLink(userFrom).name} archived`;
    case "compliment__liked":
      return `Compliment from ${getObjFromLink(userFrom).name}, to ${
        getObjFromLink(userTo).name
      }, liked`;
    case "compliment__unliked":
      return `Compliment from ${getObjFromLink(userFrom).name}, to ${
        getObjFromLink(userTo).name
      }, unliked`;
    case "login":
      return `Logged in to ${process.env.REACT_APP_SITE_TITLE}`;
    case "joined":
      return `Joined ${process.env.REACT_APP_SITE_TITLE}`;
    default:
      return "";
  }
};

export const pointReport = ({
  reason,
  userTo,
  userFrom,
  userLink,
  description,
}) => {
  return {
    reason,
    numPoints: numPoints(reason),
    dateReceived: new Date(),
    description: description
      ? description
      : getDescription({ reason, userTo, userFrom }),
    userLink,
  };
};

export const formatPointString = (points, format) => {
  return points
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, format === "spaces" ? " " : ",");
};

export const pointColor = (reason) => {
  switch (reason) {
    case "compliment__received":
      return "100";
    case "compliment__given":
      return "300";
    case "compliment__liked":
      return "500";
    case "login":
      return "700";
    case "joined":
      return "900";
    default:
      return "500";
  }
};

export const formatPointsLog = (points) =>
  points.map((p) =>
    p.reason === "order__placed" ? { ...p, numPoints: -p.numPoints } : p
  );
