import React from "react";
import styled from "@emotion/styled";
import { Image, Button } from "@theme-ui/components";

export default function SliderItem({ slider, thumbnail, preview }) {
    return (
        <Slider
            layout={slider.layout ? slider.layout : "text-image"}
            background={slider.background}
            thumbnail={thumbnail}
            preview={preview}
        >
            {
                {
                    "text-image": [
                        <Text key="text" slider={slider} />,
                        <Image
                            key="image"
                            src={slider.feature}
                            style={{ justifySelf: "center" }}
                        ></Image>,
                    ],
                    "image-text": [
                        <Image
                            key="image"
                            src={slider.feature}
                            style={{ justifySelf: "center" }}
                        ></Image>,
                        <Text key="text" slider={slider} />,
                    ],
                    text: <Text key="text" slider={slider} />,
                    image: (
                        <div style={{ textAlign: "center" }}>
                            <Image
                                key="image"
                                src={slider.feature}
                                style={{
                                    justifySelf: "center",
                                    height: thumbnail ? "170px" : "500px",
                                }}
                            ></Image>
                            <SliderButton slider={slider} key="button" />
                        </div>
                    ),
                }[slider.layout ? slider.layout : "text-image"]
            }
        </Slider>
    );
}

const Slider = styled.div`
    width: calc(100% - 8em);
    height: ${(props) => (props.thumbnail ? "200px" : "600px")};
    min-height: ${(props) => (props.thumbnail ? "200px" : "600px")};
    border-radius: 5px;
    background-image: url(${(props) => props.background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: grid;
    grid-template-columns: ${(props) =>
        !props?.layout?.includes("-") ? "1fr" : "1fr 1fr"};
    align-items: center;
    justify-items: center;
    padding: 0 4em;
    margin: ${(props) =>
        props.thumbnail ? "" : props.preview ? 0 : "1em 2em"};

    h1 {
        font-size: ${(props) => (props.thumbnail ? "1em" : "4em")};
        margin: 0;
        line-height: 1.5em;
    }
    h2 {
        font-size: ${(props) => (props.thumbnail ? "0.3em" : "inherit")};
    }
    button {
        font-size: ${(props) => (props.thumbnail ? "0.3em" : "inherit")};
    }
    button,
    a {
        display: block;

        cursor: ${(props) => (props.thumbnail ? "default" : "inherit")};
        margin: ${(props) =>
            !props?.layout?.includes("-") ? "auto" : "inherit"};
        pointer-events: ${(props) => (props.thumbnail ? "none" : "inherit")};
    }

    @media (max-width: 768px) {
        text-align: center;
        height: auto;
        grid-template-columns: 1fr;
        row-gap: 1em;
        width: calc(100% - 2em);
        padding: 0 1em;
        margin: 0;
    }
`;

const Text = ({ slider }) => (
    <div style={{ textAlign: slider.layout === "text" ? "center" : "left" }}>
        <h1 style={{ color: slider.title.colour }}>{slider.title.content}</h1>
        <h2 style={{ color: slider.subtitle.colour }}>
            {slider.subtitle.content}
        </h2>
        <SliderButton slider={slider} />
    </div>
);

const SliderButton = ({ slider }) =>
    slider?.button?.show && slider?.button?.content ? (
        <a
            href={
                slider?.button?.link?.includes("://")
                    ? slider.button.link
                    : `http://${slider.button.link}`
            }
            target="_blank"
            rel="noopener noreferrer"
        >
            <Button
                style={{
                    color: slider.button.text,
                    background: slider.button.background,
                }}
            >
                {slider.button.content}
            </Button>
        </a>
    ) : null;
