import React, { useState, useEffect } from "react";

import {
  Button,
  Label,
  Input,
  Textarea,
  Flex,
  Divider,
  Card,
  Checkbox,
} from "@theme-ui/components";
import ImageUpload from "../../../app/DSL/components/elements/ImageUpload";
import styled from "@emotion/styled";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import SliderItem from "./SliderItem";
import ColourPicker from "../../../app/DSL/components/form/ColourPicker";
import { Ring } from "react-awesome-spinners";
import Select from "react-select";
import { customStyles } from "../../../app/forms/Form";
import { platforms } from "../../../app/context/data/platforms";
import colours from "../../../app/DSL/styles/colours";
import { primary } from "../../../app/DSL/theme";

export default function SliderForm({ cancel, submit, remove, slider }) {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();

  const [pickerOpen, setPickerOpen] = useState();
  const bgCropper = React.createRef();
  const featCropper = React.createRef();

  useEffect(() => {
    if (!values) {
      if (slider) {
        setValues(slider);
      } else {
        setValues({
          layout: "text-image",
          background: null,
          title: {},
          subtitle: {},
          button: { show: false },
          feature: null,
          isPublished: false,
          platform: { label: "HQ", value: "HQ" },
        });
      }
    }
  }, [values, slider]);

  useEffect(() => {
    if (values && !values.layout) {
      setValues((prev) => ({
        ...prev,
        layout: "text-image",
      }));
    }
    if (values && !values.button) {
      setValues((prev) => ({
        ...prev,
        button: { show: false },
      }));
    }
  }, [values]);

  const platformOptions = [
    { label: "HQ", value: "HQ" },
    ...platforms
      .filter((p) => p.active)
      .map((p) => ({
        label: p.title,
        value: p.name,
      })),
  ];

  const uploadFile = async (type, cropper) => {
    const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    setLoading(type);

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully

        const response = JSON.parse(xhr.responseText);
        // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg

        const url = response.secure_url;
        setValues((prev) => ({ ...prev, [type]: url }));
        setLoading(false);
        return url;
        //addImage(url);
      }
    };

    fd.append("upload_preset", process.env.REACT_APP_PRESET);
    fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
    fd.append(
      "file",
      cropper?.current?.cropper
        ?.getCroppedCanvas(
          type === "background"
            ? { width: 1200, height: 600 }
            : { width: 600, height: 600 }
        )
        .toDataURL()
    );
    xhr.send(fd);
  };

  const layoutOptions = [
    { label: "Text Image", value: "text-image" },
    { label: "Image Text", value: "image-text" },
    { label: "Text", value: "text" },
    { label: "Image", value: "image" },
  ];
  return values ? (
    <Container>
      <InputWrap>
        <Label>Slider Layout</Label>
        <Options>
          {layoutOptions.map((l, idx) => (
            <Option
              key={idx}
              isActive={values.layout === l.value}
              onClick={() =>
                setValues((prev) => ({
                  ...prev,
                  layout: l.value,
                }))
              }
            >
              <img src={`/assets/slider/${l.value}.svg`} alt={l.label} />
            </Option>
          ))}
        </Options>
      </InputWrap>
      <InputWrap>
        <Label>Platform</Label>
        <Select
          styles={customStyles}
          isSearchable
          options={platformOptions}
          value={values.platform}
          placeholder="Select a platform"
          onChange={(option) =>
            setValues((prev) => ({
              ...prev,
              platform: option,
            }))
          }
        />
      </InputWrap>
      <InputWrap>
        <Label>Background Image</Label>
        <ImageUpload
          fullwidth
          image={values.background}
          aspectRatio={2 / 1}
          cropper={bgCropper}
          dimensions={{ width: "600px", height: "300px" }}
          mobileDimensions={{ width: "200px", height: "100px" }}
          upload={async () => await uploadFile("background", bgCropper)}
          uploading={loading === "background"}
          name="background"
        />
      </InputWrap>

      <Grid>
        {values?.layout?.includes("text") && (
          // TODO: exxtract to component
          <div>
            <Grid>
              <InputWrap>
                <Label>Title</Label>
                <Input
                  value={values.title.content}
                  onChange={(e) => {
                    e.persist();
                    setValues((prev) => ({
                      ...prev,
                      title: {
                        ...prev.title,
                        content: e.target.value,
                      },
                    }));
                  }}
                ></Input>
              </InputWrap>
              <ColourPicker
                isOpen={pickerOpen === "title"}
                open={() => setPickerOpen("title")}
                close={() => setPickerOpen(false)}
                colour={values.title.colour ? values.title.colour : "#000"}
                setColour={(colour) =>
                  setValues((prev) => ({
                    ...prev,
                    title: { ...prev.title, colour },
                  }))
                }
                label="Colour"
              />
            </Grid>
            <Grid>
              <InputWrap>
                <Label>Subtitle</Label>
                <Textarea
                  value={values.subtitle.content}
                  onChange={(e) => {
                    e.persist();
                    setValues((prev) => ({
                      ...prev,
                      subtitle: {
                        ...prev.subtitle,
                        content: e.target.value,
                      },
                    }));
                  }}
                  rows={6}
                ></Textarea>
              </InputWrap>
              <ColourPicker
                isOpen={pickerOpen === "subtitle"}
                open={() => setPickerOpen("subtitle")}
                close={() => setPickerOpen(false)}
                colour={
                  values.subtitle.colour ? values.subtitle.colour : "#000"
                }
                setColour={(colour) =>
                  setValues((prev) => ({
                    ...prev,
                    subtitle: { ...prev.subtitle, colour },
                  }))
                }
                label="Colour"
              />
            </Grid>
          </div>
        )}
        {values?.layout?.includes("image") && (
          <div>
            <InputWrap>
              <Label>Featured Image</Label>

              <ImageUpload
                image={values.feature}
                cropper={featCropper}
                dimensions={{ width: "300px", height: "300px" }}
                upload={() => uploadFile("feature", featCropper)}
                uploading={loading === "feature"}
                name="feature"
              />
            </InputWrap>
          </div>
        )}
      </Grid>
      <InputWrap>
        <Label variant="radioLabel">
          <Checkbox
            checked={values?.button?.show}
            onChange={(e) => {
              setValues((prev) => ({
                ...prev,
                button: {
                  ...prev.button,
                  show: !prev.button.show,
                },
              }));
            }}
          />
          Call to action button?
        </Label>
      </InputWrap>
      {values?.button?.show && (
        <Grid>
          <div>
            <InputWrap>
              <Label>Button Text</Label>
              <Input
                value={values.button.content}
                onChange={(e) => {
                  e.persist();
                  setValues((prev) => ({
                    ...prev,
                    button: {
                      ...prev.button,
                      content: e.target.value,
                    },
                  }));
                }}
              ></Input>
            </InputWrap>
            <InputWrap>
              <Label>Button Link</Label>
              <Input
                value={values.button.link}
                onChange={(e) => {
                  e.persist();
                  setValues((prev) => ({
                    ...prev,
                    button: {
                      ...prev.button,
                      link: e.target.value,
                    },
                  }));
                }}
              ></Input>
            </InputWrap>
          </div>
          <div>
            <ColourPicker
              isOpen={pickerOpen === "buttonText"}
              open={() => setPickerOpen("buttonText")}
              close={() => setPickerOpen(false)}
              colour={
                values.button.text
                  ? values.button.text
                  : colours[primary].font[500]
              }
              setColour={(colour) =>
                setValues((prev) => ({
                  ...prev,
                  button: { ...prev.button, text: colour },
                }))
              }
              label="Text Colour"
            />
            <ColourPicker
              isOpen={pickerOpen === "buttonColour"}
              open={() => setPickerOpen("buttonColour")}
              close={() => setPickerOpen(false)}
              colour={
                values.button.background
                  ? values.button.background
                  : colours[primary].shade[500]
              }
              setColour={(colour) =>
                setValues((prev) => ({
                  ...prev,
                  button: {
                    ...prev.button,
                    background: colour,
                  },
                }))
              }
              label="Button Colour"
            />
          </div>
        </Grid>
      )}
      <h3>Preview</h3>
      <Divider />
      <Card style={{ padding: "1em", margin: "1em 0" }}>
        <div>
          <SliderItem slider={values} preview />
        </div>
      </Card>
      <Divider />
      <Flex style={{ justifyContent: "space-between" }}>
        <div>
          <Button onClick={cancel} variant="outline">
            Cancel
          </Button>
          {remove && (
            <Button
              style={{ marginLeft: "1em" }}
              onClick={() => remove(slider)}
              variant={values.background ? "primary" : "disabled"}
            >
              Archive
            </Button>
          )}
        </div>
        <div>
          <Button
            onClick={() => submit(values, false)}
            variant={values.background ? "primary" : "disabled"}
          >
            Save as draft
          </Button>
          <Button
            style={{ marginLeft: "1em" }}
            onClick={() => submit(values, true)}
            variant={values.background ? "primary" : "disabled"}
          >
            Publish
          </Button>
        </div>
      </Flex>
    </Container>
  ) : (
    <div>
      <Ring color="blue" />
    </div>
  );
}

const Container = styled.div`
  max-width: calc(100vw - 325px - 15em);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1em;
  align-content: center;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1em;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;

const Option = styled(Card)`
  border-radius: 10px;
  height: 100%;
  padding: 0.5em;
  background-color: ${(props) => props.theme.colors.tusk.shade["300"]};
  color: ${(props) => props.theme.colors.tusk.font["300"]};
  img {
    margin-bottom: -3px;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors[primary].shade["600"]};
    color: ${(props) => props.theme.colors[primary].font["600"]};
  }
  ${(props) =>
    props.isActive &&
    `background-color:${props.theme.colors[primary].shade["400"]};
    color :${props.theme.colors[primary].font["400"]} `}
`;
