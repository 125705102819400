import React from "react";
import { useState } from "react";
import { Card } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import UsersTable from "./UsersTable";
import CreateUser from "./CreateUser";
import EditUser from "./EditUser";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import CardModal from "../../../app/DSL/components/elements/CardModal";

export default function Users() {
  const [view, setView] = useState("list");
  const [item, setItem] = useState();

  const toggleView = () => {
    setItem(null);
    if (view !== "list") {
      setView("list");
    } else {
      setView("create");
    }
  };
  const setEdit = (itemToSet) => {
    setItem(itemToSet);
    setView("edit");
  };
  const goBack = () => {
    setView("list");
    setItem(null);
  };
  return (
    <div>
      <PageHeaderContainer>Manage Users</PageHeaderContainer>
      <Card>
        <BlockHeaderContainer
          actionType="button"
          action={toggleView}
          buttonText="Create User"
          title="Users List"
        />

        <UsersTable editUser={(item) => setEdit(item)} />
      </Card>
      <CardModal isOpen={view === "create" || view === "edit"} close={goBack}>
        <BlockHeaderContainer title={`${view} User`} />

        {
          {
            create: <CreateUser goBack={goBack} />,
            edit: <EditUser user={item} goBack={goBack} />,
          }[view]
        }
      </CardModal>
    </div>
  );
}
