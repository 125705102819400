import React, { createContext, useContext } from "react";
import { transformCollectionSnap, getObjFromLink } from "../helpers";
import { useFirebase } from "../Firebase";
import { useUserContext } from "../auth";
import { formatCompliment } from "./formatting";
import { numPoints, pointReport } from "../points/formatting";
import { set } from "date-fns";

const ComplimentsContext = createContext({});

export const ComplimentsProvider = ({ children }) => {
  const firebase = useFirebase();
  const userData = useUserContext();

  const getCompliments = (user, setCompliments) =>
    user
      ? firebase
          .compliments()
          .where("for", "==", `${user.id}__${user.displayName}`)
          .onSnapshot((snap) =>
            setCompliments(
              transformCollectionSnap(snap).filter((c) => !c.isArchived)
            )
          )
      : () => console.log("not logged in");

  const getAllCompliments = (setCompliments) =>
    userData?.id
      ? firebase
          .compliments()
          .onSnapshot((snap) =>
            setCompliments(
              transformCollectionSnap(snap).filter((c) => !c.isArchived)
            )
          )
      : () => console.log("not logged in");

  const getMonthsCompliments = (setCompliments) => {
    const date = set(new Date(), { date: 1, hours: 0, minutes: 0, seconds: 0 });

    return userData?.id
      ? firebase
          .compliments()
          .where("datePosted", ">=", date)
          .onSnapshot((snap) =>
            setCompliments(
              transformCollectionSnap(snap).filter((c) => !c.isArchived)
            )
          )
      : () => console.log("not logged in");
  };
  const getTodaysCompliments = (setCompliments) => {
    const date = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });

    return userData?.id
      ? firebase
          .compliments()
          .where("datePosted", ">=", date)
          .onSnapshot((snap) =>
            setCompliments(
              transformCollectionSnap(snap).filter((c) => !c.isArchived)
            )
          )
      : () => console.log("not logged in");
  };

  const getUnapprovedCompliments = (setCompliments) => {
    return userData?.id
      ? firebase
          .compliments()
          .where("isApproved", "==", false)
          .onSnapshot((snap) =>
            setCompliments(
              transformCollectionSnap(snap).filter((c) => !c.isArchived)
            )
          )
      : () => console.log("not logged in");
  };

  const addCompliment = async ({ forUser, compliment }) => {
    return await firebase
      .compliments()
      .add(formatCompliment({ compliment, postingUser: userData, forUser }));
  };

  const likeCompliment = async (compliment) => {
    const reason = "compliment__liked";
    const batch = firebase.db.batch();
    await batch.update(firebase.compliment(compliment.id), {
      likes: firebase.firestore.FieldValue.arrayUnion(
        `${userData.id}__${userData.displayName}`
      ),
    });
    if (process.env.REACT_APP_EARN_POINTS === "true") {
      await batch.update(firebase.user(userData.id), {
        points: firebase.firestore.FieldValue.increment(numPoints(reason)),
      });
      await batch.set(
        firebase.pointsLog().doc(),
        pointReport({
          reason,
          userTo: compliment.for,
          userFrom: compliment.postedBy,
          userLink: `${userData.id}__${userData.displayName}`,
        })
      );
    }
    return await batch.commit();
  };

  const unlikeCompliment = async (compliment) => {
    const reason = "compliment__unliked";
    const batch = firebase.db.batch();
    await batch.update(firebase.compliment(compliment.id), {
      likes: firebase.firestore.FieldValue.arrayRemove(
        `${userData.id}__${userData.displayName}`
      ),
    });
    if (process.env.REACT_APP_EARN_POINTS === "true") {
      await batch.update(firebase.user(userData.id), {
        points: firebase.firestore.FieldValue.increment(numPoints(reason)),
      });
      await batch.set(
        firebase.pointsLog().doc(),
        pointReport({
          reason,
          userTo: compliment.for,
          userFrom: compliment.postedBy,
          userLink: `${userData.id}__${userData.displayName}`,
        })
      );
    }
    return await batch.commit();
  };

  const approveCompliment = async (compliment) => {
    const batch = firebase.db.batch();
    await batch.update(firebase.compliment(compliment.id), {
      isApproved: true,
      dateApproved: new Date(),
    });
    if (process.env.REACT_APP_EARN_POINTS === "true") {
      //points for giving compliment
      await batch.update(
        firebase.user(getObjFromLink(compliment.postedBy).id),
        {
          points: firebase.firestore.FieldValue.increment(
            numPoints("compliment__given")
          ),
        }
      );
      await batch.set(
        firebase.pointsLog().doc(),
        pointReport({
          reason: "compliment__given",
          userTo: compliment.for,
          userLink: compliment.postedBy,
        })
      );

      //points for receiving compliment

      await batch.update(firebase.user(getObjFromLink(compliment.for).id), {
        points: firebase.firestore.FieldValue.increment(
          numPoints("compliment__received")
        ),
      });
      await batch.set(
        firebase.pointsLog().doc(),
        pointReport({
          reason: "compliment__received",
          userFrom: compliment.postedBy,
          userLink: compliment.for,
        })
      );
    }

    return await batch.commit();
  };

  const archiveCompliment = async (compliment) => {
    const batch = firebase.db.batch();
    await batch.update(firebase.compliment(compliment.id), {
      isArchived: true,
      dateArchived: new Date(),
    });

    if (compliment.isApproved) {
      //points for giving compliment
      if (process.env.REACT_APP_EARN_POINTS === "true") {
        await batch.update(
          firebase.user(getObjFromLink(compliment.postedBy).id),
          {
            points: firebase.firestore.FieldValue.increment(
              numPoints("compliment__given__archived")
            ),
          }
        );
        await batch.set(
          firebase.pointsLog().doc(),
          pointReport({
            reason: "compliment__given__archived",
            userTo: compliment.for,
            userLink: compliment.postedBy,
          })
        );

        //points for receiving compliment
        await batch.update(firebase.user(getObjFromLink(compliment.for).id), {
          points: firebase.firestore.FieldValue.increment(
            numPoints("compliment__received__archived")
          ),
        });
        await batch.set(
          firebase.pointsLog().doc(),
          pointReport({
            reason: "compliment__received__archived",
            userFrom: compliment.postedBy,
            userLink: compliment.for,
          })
        );
      }
    }

    return await batch.commit();
  };

  return (
    <ComplimentsContext.Provider
      value={{
        getCompliments,
        getAllCompliments,
        addCompliment,
        likeCompliment,
        unlikeCompliment,
        approveCompliment,
        getMonthsCompliments,
        getUnapprovedCompliments,
        getTodaysCompliments,
        archiveCompliment,
      }}
    >
      {children}
    </ComplimentsContext.Provider>
  );
};

export const ComplimentsConsumer = ComplimentsContext.Consumer;

export const useCompliments = () => useContext(ComplimentsContext);
