import React from "react";
import styled from "@emotion/styled";

export default function RoleLabel({ role }) {
    return <Label role={role}>{role.toUpperCase()}</Label>;
}

const Label = styled.div`
    padding: 0.3em 1em;
    border-radius: 20px;
    text-align: center;
    font-weight: bold;
    background: ${(props) => {
        switch (props.role) {
            case "admin":
                return props.theme.colors.blue.shade["600"];
            case "manager":
                return props.theme.colors.teal.shade["500"];
            case "employee":
                return props.theme.colors.lightTeal.shade["500"];

            default:
                return props.theme.colors.tusk.shade["500"];
        }
    }};
    color: ${(props) => {
        switch (props.role) {
            case "admin":
                return props.theme.colors.blue.font["600"];
            case "manager":
                return props.theme.colors.teal.font["500"];
            case "employee":
                return props.theme.colors.lightTeal.font["900"];
            default:
                return props.theme.colors.tusk.font["500"];
        }
    }};
`;
