import React, { useEffect, useState, useMemo } from "react";
import CardModal from "../../../app/DSL/components/elements/CardModal";
import { useFirebase } from "../../../app/context/Firebase";
import { transformDocSnap } from "../../../app/context/helpers";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { Checkbox, Label, Flex, Button } from "@theme-ui/components";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import styled from "@emotion/styled";
import { customStyles } from "../../../app/forms/Form";
import Select from "react-select";
import { departments } from "../../../app/context/data/departments";
import { useUsers } from "../../../app/context/users";
import { usePermissions } from "../../../app/context/permissions";
import { useToasts } from "react-toast-notifications";
import { Ring } from "react-awesome-spinners";
import colours from "../../../app/DSL/styles/colours";
import { primary } from "../../../app/DSL/theme";

export default function PermissionsModal({ open, close, platform }) {
  const firebase = useFirebase();
  const [permissions, setPermissions] = useState();
  const { users } = useUsers();
  const { updatePermissions } = usePermissions();

  const { addToast } = useToasts();

  useEffect(() => {
    const unsubscribe = platform
      ? firebase
          .setting(`${platform.name}Permissions`)
          .onSnapshot((snap) => setPermissions(transformDocSnap(snap)))
      : () => console.log("no Platform");

    return () => unsubscribe();
  }, [firebase, platform]);

  const usersOptions = useMemo(
    () =>
      users
        ? users
            .filter((u) => {
              if (permissions?.departments) {
                return !permissions?.departments
                  .map((d) => d.value)
                  .includes(u.department);
              } else {
                return true;
              }
            })
            .map((u) => ({
              label: u.displayName,
              value: `${u.id}__${u.displayName}`,
            }))
        : [],
    [permissions, users]
  );

  const update = () => {
    updatePermissions(platform, permissions).then(() => {
      addToast("Permissions Updated", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
      setPermissions({});
      close();
    });
  };

  return (
    <CardModal
      isOpen={open}
      close={() => {
        setPermissions({});
        close();
      }}
    >
      {platform && permissions ? (
        <div>
          <BlockHeaderContainer
            title={`Manage ${platform.title} permissions`}
          />
          <h3>Company Wide access</h3>
          <p>
            All users registered on {process.env.REACT_APP_SITE_TITLE} will have
            access to this platform
          </p>
          <InputWrap>
            <Label variant="radioLabel">
              <Checkbox
                checked={permissions.allowAll}
                onChange={(e) => {
                  setPermissions((prev) => ({
                    ...prev,
                    allowAll: !prev.allowAll,
                  }));
                }}
              />
              Allow all users access
            </Label>
          </InputWrap>

          <PermisisonBlock disabled={permissions.allowAll}>
            {process.env.REACT_APP_USER_TYPE === "internal" && (
              <>
                <h3>Departments</h3>
                <p>
                  All users within this department will have access to this
                  platform
                </p>
                <InputWrap style={{ margin: "1em 0" }}>
                  <Select
                    isDisabled={permissions.allowAll}
                    styles={customStyles}
                    isClearable
                    isSearchable
                    isMulti
                    options={departments}
                    value={permissions.departments}
                    placeholder="Add a department"
                    onChange={(options) =>
                      setPermissions((prev) => ({
                        ...prev,
                        departments: options,
                      }))
                    }
                  />
                </InputWrap>
              </>
            )}
            <h3>Users</h3>
            <p>Add specific users not in one of the specified departments</p>
            <InputWrap style={{ margin: "1em 0" }}>
              <Select
                isDisabled={permissions.allowAll}
                styles={customStyles}
                isClearable
                isSearchable
                isMulti
                options={usersOptions}
                value={permissions.users}
                placeholder="Add a user"
                onChange={(options) =>
                  setPermissions((prev) => ({
                    ...prev,
                    users: options,
                  }))
                }
              />
            </InputWrap>
          </PermisisonBlock>
          <Flex sx={{ justifyContent: "space-between" }}>
            <Button
              variant="outline"
              onClick={() => {
                setPermissions({});
                close();
              }}
            >
              Cancel
            </Button>

            <Button onClick={update}>Update Permissions</Button>
          </Flex>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Ring color={colours[primary].shade["500"]} />
        </div>
      )}
    </CardModal>
  );
}

const PermisisonBlock = styled.div`
  * {
    color: ${(props) =>
      props.disabled ? props.theme.colors.tusk.shade[500] : "black"};
  }
`;
