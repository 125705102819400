import React, { createContext, useContext } from "react";
import { useFirebase } from "../Firebase";

const PermissionsContext = createContext({});

//TODO: Move assigning permissions here

export const PermissionsProvider = ({ children }) => {
  const firebase = useFirebase();
  const updatePermissions = (platform, permissions) => {
    return firebase
      .setting(`${platform.name}Permissions`)
      .set(permissions, { merge: true });
  };

  return (
    <PermissionsContext.Provider value={{ updatePermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const PermissionsConsumer = PermissionsContext.Consumer;

export const usePermissions = () => useContext(PermissionsContext);
