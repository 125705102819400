import React from "react";
import AdminSideBar from "../navigation/AdminSideBar";
import styled from "@emotion/styled";

export default function SideBarWrapper({ children, showBar }) {
  return (
    <Grid show={showBar}>
      <AdminSideBar show={showBar} />
      <div>{children}</div>
    </Grid>
  );
}

const Grid = styled.div`
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${(props) => (props.show ? "2em" : "0px")};
  transition: all ease-out 0.3s;
  z-index: 2;
`;
