/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import { useState } from "react";
import { useForm } from "./useForm";
import {
  Button,
  Label,
  Input,
  Radio,
  Message,
  Checkbox,
} from "@theme-ui/components";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";

import Select from "react-select";
import Field from "../DSL/components/form/Field";
import DateInput from "../DSL/components/form/DateInput";

export default function Form({
  initialValues,
  handleSubmit,
  loading,
  error,
  submitText,
  formFields,
  create,
  type,
  cancel,
  register,
}) {
  const [createAnother, setCreateAnother] = useState(true);

  const {
    formValid,
    inputProps,
    formattedValues,
    values,
    reset,
    setAllTouched,
  } = useForm(formFields, initialValues);

  const invalidSubmit = (e) => {
    e.preventDefault();
    setAllTouched();
  };

  return (
    <form
      onSubmit={(e) =>
        formValid
          ? handleSubmit(e, formattedValues(), reset, create && createAnother)
          : invalidSubmit(e)
      }
    >
      {formFields
        .filter(({ hidden }) => !hidden)
        .map(
          (
            { title, name, placeholder, type, options, readOnly, isMulti },
            idx
          ) => {
            const fieldProps = { name, values, error, title, key: idx };
            switch (type) {
              case "date":
                return (
                  <Field {...fieldProps}>
                    <DateInput inputProps={inputProps(name)} />
                  </Field>
                );

              case "radio":
                //TODO:Extract to component
                return (
                  <Field {...fieldProps}>
                    <Flex>
                      {options.map((o, idx) => (
                        <InputWrap key={idx}>
                          <Label
                            variant="radioLabel"
                            sx={{
                              textTransform: "capitalize",
                            }}
                          >
                            <Radio
                              {...inputProps(name)}
                              id={o}
                              value={o}
                              checked={values[name].value === o}
                              defaultChecked={
                                initialValues &&
                                initialValues[name] &&
                                o === initialValues[name]
                              }
                            />
                            {o}
                          </Label>
                        </InputWrap>
                      ))}
                    </Flex>
                  </Field>
                );

              case "select":
                //TODO:Extract to component
                return (
                  <Field {...fieldProps}>
                    <Select
                      styles={customStyles}
                      {...inputProps(name)}
                      options={options}
                      value={values[name].selectedOption}
                      placeholder={placeholder}
                    />
                  </Field>
                );

              case "multiselect":
                //TODO:Extract to component
                return (
                  <Field {...fieldProps}>
                    <Select
                      styles={customStyles}
                      {...inputProps(name)}
                      options={options}
                      value={values[name].selectedOptions}
                      placeholder={placeholder}
                      isMulti
                    />
                  </Field>
                );

              default:
                return (
                  <Field key={idx} {...fieldProps}>
                    <Input {...inputProps(name)} placeholder={placeholder} />
                  </Field>
                );
            }
          }
        )}

      {create && (
        //TODO:Extract to component
        <InputWrap style={{ gridArea: "checkbox" }}>
          <Label variant="radioLabel">
            <Checkbox
              checked={createAnother}
              onChange={(e) => {
                setCreateAnother(!createAnother);
              }}
            />
            Create another {type}?
          </Label>
        </InputWrap>
      )}

      {error && (
        <Message variant="warning" style={{ gridArea: "error" }}>
          {error.message}
        </Message>
      )}
      {register && (
        <small style={{ gridArea: "message" }}>
          <a
            href={process.env.REACT_APP_TERMS}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>{" "}
          apply
        </small>
      )}

      <InputWrap style={{ gridArea: "button" }}>
        {cancel ? (
          <Flex sx={{ justifyContent: "space-between" }}>
            <Button variant="outline" onClick={cancel}>
              Cancel
            </Button>
            {loading ? (
              <Button disabled={loading}>Loading</Button>
            ) : (
              <Button
                type="submit"
                variant={!formValid ? "disabled" : "primary"}
              >
                {submitText}
              </Button>
            )}
          </Flex>
        ) : loading ? (
          <Button disabled={loading}>Loading</Button>
        ) : (
          <Button type="submit" variant={!formValid ? "disabled" : "primary"}>
            {submitText}
          </Button>
        )}
      </InputWrap>
    </form>
  );
}
//TODO:Move to styled/form
export const customStyles = {
  control: (provided, state) => ({
    display: "flex",
    alignItems: "center",
    boxShadow: "inset 0 0.0625em 0.125em rgba(10,10,10,.05)",
    backgroundColor: "#fff",
    borderColor: state.isFocused
      ? "var(--theme-ui-colors-tusk-shade-900,#CDCDD6) !important"
      : "var(--theme-ui-colors-tusk-shade-500,#E2E2E7) !important",
    border: "1px solid",
    borderRadius: 4,
    color: "#363636",
    font: "inherit",
    outline: 0,
  }),
  input: (provided, state) => ({
    ...provided,
    paddingBottom: "calc(.5em - 2px)",
    paddingLeft: "calc(.75em - 2px)",
    paddingRight: "calc(.75em - 2px)",
    paddingTop: "calc(.5em - 2px)",
  }),
  container: (provided, state) => ({
    ...provided,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
