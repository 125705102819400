import React from "react";
import SliderForm from "./SliderForm";
import { Card } from "@theme-ui/components";
import { useSliders } from "../../../app/context/sliders";
import { useToasts } from "react-toast-notifications";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";

export default function EditSlider({ selectedSlider, setView }) {
  const { updateSlider } = useSliders();
  const { addToast } = useToasts();
  const submit = (values, isPublished) => {
    updateSlider({ ...values, isPublished }).then(() => {
      addToast("Slider Updated", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
      setView("view");
    });
  };

  const remove = () => {
    updateSlider({
      ...selectedSlider,
      isArchived: true,
      isPublished: false,
    }).then(() => {
      addToast("Slider Archived", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
      setView("view");
    });
  };
  return (
    <Card>
      <BlockHeaderContainer
        title="Edit Slider"
        actionType="button"
        action={() => setView("view")}
        buttonText="Back to sliders"
      />
      <SliderForm
        cancel={() => setView("view")}
        slider={selectedSlider}
        buttonText="Update"
        submit={submit}
        remove={remove}
      />
    </Card>
  );
}
