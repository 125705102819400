import React, { createContext, useContext, useState, useEffect } from "react";
import { transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext, useUserContext } from "../auth";
import { formatMessage } from "./formatting";

const MessagesContext = createContext({});

export const MessagesProvider = ({ children }) => {
  const firebase = useFirebase();
  const [messages, setMessages] = useState();
  const { user } = useAuthContext();
  const userData = useUserContext();

  useEffect(() => {
    const unsubscribe = user
      ? firebase
          .messages()
          .onSnapshot((snap) => setMessages(transformCollectionSnap(snap)))
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  const addMessage = async (message) => {
    return await firebase
      .messages()
      .add(formatMessage({ message, user: userData }));
  };

  const readMessage = async (message) => {
    return await firebase.message(message.id).update({ isRead: true });
  };

  return (
    <MessagesContext.Provider value={{ messages, addMessage, readMessage }}>
      {children}
    </MessagesContext.Provider>
  );
};

export const MessagesConsumer = MessagesContext.Consumer;

export const useMessages = () => useContext(MessagesContext);
