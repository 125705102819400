import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faUsers,
  faUserMd,
  faHospital,
  faSignOut,
  faCheck,
  faAngleDown,
  faAngleUp,
  faSpinner,
  faFolderOpen,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faHourglassStart,
  faCircle,
  faUserCircle,
  faTh,
  faHospitalUser,
  faStar,
  faNewspaper,
  faUserFriends,
  faSearch,
  faThumbsUp,
  faCalendar,
  faTimes,
  faExclamationCircle,
  faHourglassHalf,
  faCommentLines,
  faCommentDots,
  faBringForward,
  faShapes,
  faPortrait,
  faLifeRing,
  faComputerClassic,
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faUsers,
  faUserMd,
  faHospital,
  faSignOut,
  faCheck,
  faAngleDown,
  faAngleUp,
  faSpinner,
  faFolderOpen,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faHourglassStart,
  faCircle,
  faUserCircle,
  faTh,
  faHospitalUser,
  faStar,
  faNewspaper,
  faUserFriends,
  faSearch,
  faThumbsUp,
  faCalendar,
  faTimes,
  faExclamationCircle,
  faHourglassHalf,
  faCommentLines,
  faCommentDots,
  faBringForward,
  faShapes,
  faPortrait,
  faLifeRing,
  faComputerClassic
);

export {
  faUsers,
  faUserMd,
  faHospital,
  faSignOut,
  faCheck,
  faAngleDown,
  faAngleUp,
  faSpinner,
  faFolderOpen,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faHourglassStart,
  faCircle,
  faUserCircle,
  faTh,
  faHospitalUser,
  faStar,
  faNewspaper,
  faUserFriends,
  faSearch,
  faThumbsUp,
  faCalendar,
  faTimes,
  faExclamationCircle,
  faHourglassHalf,
  faCommentLines,
  faCommentDots,
  faBringForward,
  faShapes,
  faPortrait,
  faLifeRing,
  faComputerClassic,
};
