export const birthdays = [
  {
    birthdateString: "1976 November 20",
    birthdate: "1976-11-19T23:00:00.000Z",
    displayName: "Natasha Kotze",
  },
  {
    birthdateString: "1974 November 19",
    birthdate: "1974-11-18T23:00:00.000Z",
    displayName: "Chrisna Harmse",
  },
  {
    birthdateString: "1979 March 20",
    birthdate: "1979-03-19T23:00:00.000Z",
    displayName: "Catherine Mompati",
  },
  {
    birthdateString: "1986 June 20",
    birthdate: "1986-06-19T22:00:00.000Z",
    displayName: "Renier Van Rooyen",
  },
  {
    birthdateString: "1970 March 04",
    birthdate: "1970-03-03T23:00:00.000Z",
    displayName: "Roché Swiegers",
  },
  {
    birthdateString: "1988 August 08",
    birthdate: "1988-08-07T22:00:00.000Z",
    displayName: "Keagan Robinson",
  },
  {
    birthdateString: "1985 February 23",
    birthdate: "1985-02-22T23:00:00.000Z",
    displayName: "Morne Odendaal",
  },
  {
    birthdateString: "1980 December 18",
    birthdate: "1980-12-17T23:00:00.000Z",
    displayName: "John Ndhlovu",
  },
  {
    birthdateString: "1978 September 26",
    birthdate: "1978-09-25T22:00:00.000Z",
    displayName: "Jaco Erasmus",
  },
  {
    birthdateString: "1983 November 27",
    birthdate: "1983-11-26T23:00:00.000Z",
    displayName: "Elizabeth Phatlane",
  },
  {
    birthdateString: "1960 June 06",
    birthdate: "1960-06-05T23:00:00.000Z",
    displayName: "Cathy Smith",
  },
  {
    birthdateString: "1969 February 07",
    birthdate: "1969-02-06T23:00:00.000Z",
    displayName: "Don Froneman",
  },
  {
    birthdateString: "1969 July 19",
    birthdate: "1969-07-18T23:00:00.000Z",
    displayName: "Kevin Archary",
  },
  {
    birthdateString: "1964 February 06",
    birthdate: "1964-02-05T23:00:00.000Z",
    displayName: "Uluande Marais",
  },
  {
    birthdateString: "1974 April 27",
    birthdate: "1974-04-26T23:00:00.000Z",
    displayName: "Lenard Khumalo",
  },
  {
    birthdateString: "1989 October 29",
    birthdate: "1989-10-28T23:00:00.000Z",
    displayName: "Thulani Ndlovu",
  },
  {
    birthdateString: "1988 November 26",
    birthdate: "1988-11-25T23:00:00.000Z",
    displayName: "Ray Pillay",
  },
  {
    birthdateString: "1978 October 03",
    birthdate: "1978-10-02T23:00:00.000Z",
    displayName: "Maylene Gavin",
  },
  {
    birthdateString: "1986 April 22",
    birthdate: "1986-04-21T22:00:00.000Z",
    displayName: "JP Zwiegelaar",
  },
  {
    birthdateString: "1990 March 11",
    birthdate: "1990-03-10T23:00:00.000Z",
    displayName: "Nkanyiso Mbhedje",
  },
  {
    birthdateString: "1983 January 24",
    birthdate: "1983-01-23T23:00:00.000Z",
    displayName: "Graeme Brebner",
  },
  {
    birthdateString: "1985 July 17",
    birthdate: "1985-07-16T22:00:00.000Z",
    displayName: "Pieter Delport",
  },
  {
    birthdateString: "1989 June 26",
    birthdate: "1989-06-25T22:00:00.000Z",
    displayName: "Ntombifuthi Bele",
  },
  {
    birthdateString: "1981 January 17",
    birthdate: "1981-01-16T23:00:00.000Z",
    displayName: "Christine van Niekerk",
  },
  {
    birthdateString: "1962 December 04",
    birthdate: "1962-12-03T23:00:00.000Z",
    displayName: "vusi ndlazi",
  },
  {
    birthdateString: "1989 April 21",
    birthdate: "1989-04-20T22:00:00.000Z",
    displayName: "Nathan Johnston",
  },
  {
    birthdateString: "1994 August 03",
    birthdate: "1994-08-02T22:00:00.000Z",
    displayName: "Ancke Oelofse",
  },
  {
    birthdateString: "1994 March 15",
    birthdate: "1994-03-14T23:00:00.000Z",
    displayName: "Synthia Mantsi",
  },
  {
    birthdateString: "1991 October 06",
    birthdate: "1991-10-05T23:00:00.000Z",
    displayName: "Andisiwe Hlahla",
  },
  {
    birthdateString: "1979 January 18",
    birthdate: "1979-01-17T23:00:00.000Z",
    displayName: "Craig Allchurch",
  },
  {
    birthdateString: "1986 November 1",
    birthdate: "1986-10-31T23:00:00.000Z",
    displayName: "William Mkhatshwa",
  },
  {
    birthdateString: "1988 September 16",
    birthdate: "1988-09-15T22:00:00.000Z",
    displayName: "Willem Serfontein",
  },
  {
    birthdateString: "1978 March 08",
    birthdate: "1978-03-07T23:00:00.000Z",
    displayName: "Philip Heyns",
  },
  {
    birthdateString: "1988 May 17",
    birthdate: "1988-05-16T22:00:00.000Z",
    displayName: "Katleho Gavin Manyelo",
  },
  {
    birthdateString: "1972 September 12",
    birthdate: "1972-09-11T23:00:00.000Z",
    displayName: "Johan Killian",
  },
  {
    lastName: "De Klerk",
    firstName: "Allison",
    birthdateString: "1979 January 2",
    birthdate: "1979-01-01T23:00:00.000Z",
    displayName: "Allison De Klerk",
  },
  {
    lastName: "Ramlakhan",
    firstName: "Shakira",
    birthdateString: "1982 January 5",
    birthdate: "1982-01-04T23:00:00.000Z",
    displayName: "Shakira Ramlakhan",
  },
  {
    lastName: "Thabethe",
    firstName: "Jane",
    birthdateString: "1978 January 6",
    birthdate: "1978-01-05T23:00:00.000Z",
    displayName: "Jane Thabethe",
  },
  {
    lastName: "Vusumusi",
    firstName: "Brian",
    birthdateString: "1979 January 15",
    birthdate: "1979-01-14T23:00:00.000Z",
    displayName: "Brian Vusumusi",
  },
  {
    lastName: "Le Roux",
    firstName: "Brandon",
    birthdateString: "1990 January 15",
    birthdate: "1990-01-14T23:00:00.000Z",
    displayName: "Brandon Le Roux",
  },
  {
    lastName: "Van Aarde",
    firstName: "Le Roux",
    birthdateString: "1977 January 16",
    birthdate: "1977-01-15T23:00:00.000Z",
    displayName: "Le Roux Van Aarde",
  },
  {
    lastName: "Van Niekerk",
    firstName: "Christine",
    birthdateString: "1981 January 17",
    birthdate: "1981-01-16T23:00:00.000Z",
    displayName: "Christine Van Niekerk",
  },
  {
    lastName: "Ntuli",
    firstName: "Paulina",
    birthdateString: "1980 January 17",
    birthdate: "1980-01-16T23:00:00.000Z",
    displayName: "Paulina Ntuli",
  },
  {
    lastName: "Allchurch",
    firstName: "John Craig",
    birthdateString: "1979 January 18",
    birthdate: "1979-01-17T23:00:00.000Z",
    displayName: "John Craig Allchurch",
  },
  {
    lastName: "Sereo",
    firstName: "Priscilla",
    birthdateString: "1992 February 2",
    birthdate: "1992-02-01T23:00:00.000Z",
    displayName: "Priscilla Sereo",
  },
  {
    lastName: "Molangoana",
    firstName: "Patrick",
    birthdateString: "1963 February 4",
    birthdate: "1963-02-03T23:00:00.000Z",
    displayName: "Patrick Molangoana",
  },
  {
    lastName: "Rosin",
    firstName: "Laetitia",
    birthdateString: "1963 February 6",
    birthdate: "1963-02-05T23:00:00.000Z",
    displayName: "Laetitia Rosin",
  },
  {
    lastName: "Marais",
    firstName: "Urscheluande",
    birthdateString: "1964 February 6",
    birthdate: "1964-02-05T23:00:00.000Z",
    displayName: "Urscheluande Marais",
  },
  {
    lastName: "Froneman",
    firstName: "Donald",
    birthdateString: "1969 February 7",
    birthdate: "1969-02-06T23:00:00.000Z",
    displayName: "Donald Froneman",
  },
  {
    lastName: "Smith",
    firstName: "Jacques",
    birthdateString: "1989 February 13",
    birthdate: "1989-02-12T23:00:00.000Z",
    displayName: "Jacques Smith",
  },
  {
    lastName: "Thamba Vuma",
    firstName: "Alex",
    birthdateString: "1970 February 15",
    birthdate: "1970-02-14T23:00:00.000Z",
    displayName: "Alex Thamba Vuma",
  },
  {
    lastName: "Maluleka",
    firstName: "Sandra",
    birthdateString: "1972 February 17",
    birthdate: "1972-02-16T23:00:00.000Z",
    displayName: "Sandra Maluleka",
  },
  {
    lastName: "Breed",
    firstName: "Mariessa",
    birthdateString: "1972 February 23",
    birthdate: "1972-02-22T23:00:00.000Z",
    displayName: "Mariessa Breed",
  },
  {
    lastName: "Odendaal",
    firstName: "Morne Johann",
    birthdateString: "1985 February 23",
    birthdate: "1985-02-22T23:00:00.000Z",
    displayName: "Morne Johann Odendaal",
  },
  {
    lastName: "De Klerk",
    firstName: "Johannes",
    birthdateString: "1970 February 28",
    birthdate: "1970-02-27T23:00:00.000Z",
    displayName: "Johannes De Klerk",
  },
  {
    lastName: "Van Der Merwe",
    firstName: "Morne",
    birthdateString: "1976 March 1",
    birthdate: "1976-02-29T23:00:00.000Z",
    displayName: "Morne Van Der Merwe",
  },
  {
    lastName: "Van der Bank",
    firstName: "Hilda",
    birthdateString: "1973 March 3",
    birthdate: "1973-03-02T23:00:00.000Z",
    displayName: "Hilda Van der Bank",
  },
  {
    lastName: "Swiegers",
    firstName: "Roche",
    birthdateString: "1970 March 4",
    birthdate: "1970-03-03T23:00:00.000Z",
    displayName: "Roche Swiegers",
  },
  {
    lastName: "Mamfengu",
    firstName: "Kaizer",
    birthdateString: "1979 March 5",
    birthdate: "1979-03-04T23:00:00.000Z",
    displayName: "Kaizer Mamfengu",
  },
  {
    lastName: "Morifi",
    firstName: "Ernest",
    birthdateString: "1977 March 7",
    birthdate: "1977-03-06T23:00:00.000Z",
    displayName: "Ernest Morifi",
  },
  {
    lastName: "Hadebe",
    firstName: "Claude",
    birthdateString: "1977 March 8",
    birthdate: "1977-03-07T23:00:00.000Z",
    displayName: "Claude Hadebe",
  },
  {
    lastName: "Lottering",
    firstName: "Warren",
    birthdateString: "1981 March 9",
    birthdate: "1981-03-08T23:00:00.000Z",
    displayName: "Warren Lottering",
  },
  {
    lastName: "Ratshibvumo",
    firstName: "Johannes",
    birthdateString: "1972 March 10",
    birthdate: "1972-03-09T23:00:00.000Z",
    displayName: "Johannes Ratshibvumo",
  },
  {
    lastName: "Mbhedje",
    firstName: "Nkanyiso (Wanda)",
    birthdateString: "1990 March 11",
    birthdate: "1990-03-10T23:00:00.000Z",
    displayName: "Nkanyiso (Wanda) Mbhedje",
  },
  {
    lastName: "Mkhasibe",
    firstName: "Jabulane",
    birthdateString: "1986 March 12",
    birthdate: "1986-03-11T23:00:00.000Z",
    displayName: "Jabulane Mkhasibe",
  },
  {
    lastName: "Peterson",
    firstName: "Jacob",
    birthdateString: "1962 March 12",
    birthdate: "1962-03-11T23:00:00.000Z",
    displayName: "Jacob Peterson",
  },
  {
    lastName: "Dedekind",
    firstName: "Anke",
    birthdateString: "1978 March 13",
    birthdate: "1978-03-12T23:00:00.000Z",
    displayName: "Anke Dedekind",
  },
  {
    lastName: "Hanekom",
    firstName: "Jacqueline",
    birthdateString: "1973 March 20",
    birthdate: "1973-03-19T23:00:00.000Z",
    displayName: "Jacqueline Hanekom",
  },
  {
    lastName: "Wecke",
    firstName: "Brendon",
    birthdateString: "1980 March 20",
    birthdate: "1980-03-19T23:00:00.000Z",
    displayName: "Brendon Wecke",
  },
  {
    lastName: "Mompati",
    firstName: "Cathrine",
    birthdateString: "1979 March 20",
    birthdate: "1979-03-19T23:00:00.000Z",
    displayName: "Cathrine Mompati",
  },
  {
    lastName: "Phiri",
    firstName: "Billy",
    birthdateString: "1963 March 21",
    birthdate: "1963-03-20T23:00:00.000Z",
    displayName: "Billy Phiri",
  },
  {
    lastName: "Naidoo",
    firstName: "Lesley",
    birthdateString: "1985 March 28",
    birthdate: "1985-03-27T23:00:00.000Z",
    displayName: "Lesley Naidoo",
  },
  {
    lastName: "Bezuidenhout",
    firstName: "Minette",
    birthdateString: "1980 April 2",
    birthdate: "1980-04-01T23:00:00.000Z",
    displayName: "Minette Bezuidenhout",
  },
  {
    lastName: "Du Toit",
    firstName: "Pieter",
    birthdateString: "1978 April 6",
    birthdate: "1978-04-05T22:00:00.000Z",
    displayName: "Pieter Du Toit",
  },
  {
    lastName: "Mbizane",
    firstName: "Zwelethu",
    birthdateString: "1982 April 9",
    birthdate: "1982-04-08T22:00:00.000Z",
    displayName: "Zwelethu Mbizane",
  },
  {
    lastName: "Mathebula",
    firstName: "Samuel",
    birthdateString: "1972 April 10",
    birthdate: "1972-04-09T23:00:00.000Z",
    displayName: "Samuel Mathebula",
  },
  {
    lastName: "Senaone",
    firstName: "Lesley",
    birthdateString: "1990 April 11",
    birthdate: "1990-04-10T22:00:00.000Z",
    displayName: "Lesley Senaone",
  },
  {
    lastName: "Sebati",
    firstName: "Keabetswe",
    birthdateString: "1992 April 11",
    birthdate: "1992-04-10T22:00:00.000Z",
    displayName: "Keabetswe Sebati",
  },
  {
    lastName: "Strauss",
    firstName: "Jean",
    birthdateString: "1984 April 12",
    birthdate: "1984-04-11T22:00:00.000Z",
    displayName: "Jean Strauss",
  },
  {
    lastName: "Mosikidi",
    firstName: "Refiloe",
    birthdateString: "1988 April 19",
    birthdate: "1988-04-18T22:00:00.000Z",
    displayName: "Refiloe Mosikidi",
  },
  {
    lastName: "Johnston",
    firstName: "Nathan Dale",
    birthdateString: "1989 April 21",
    birthdate: "1989-04-20T22:00:00.000Z",
    displayName: "Nathan Dale Johnston",
  },
  {
    lastName: "Zwiegelaar",
    firstName: "Johan",
    birthdateString: "1986 April 22",
    birthdate: "1986-04-21T22:00:00.000Z",
    displayName: "Johan Zwiegelaar",
  },
  {
    lastName: "Lelaka",
    firstName: "Phipps",
    birthdateString: "1986 April 23",
    birthdate: "1986-04-22T22:00:00.000Z",
    displayName: "Phipps Lelaka",
  },
  {
    lastName: "Fraser",
    firstName: "Jason",
    birthdateString: "1978 April 27",
    birthdate: "1978-04-26T22:00:00.000Z",
    displayName: "Jason Fraser",
  },
  {
    lastName: "Khumalo",
    firstName: "Khulahi (Lenard)",
    birthdateString: "1974 April 27",
    birthdate: "1974-04-26T23:00:00.000Z",
    displayName: "Khulahi (Lenard) Khumalo",
  },
  {
    lastName: "Pitse",
    firstName: "Thulane",
    birthdateString: "1983 May 9",
    birthdate: "1983-05-08T22:00:00.000Z",
    displayName: "Thulane Pitse",
  },
  {
    lastName: "Ndlovu",
    firstName: "Musi",
    birthdateString: "1992 May 14",
    birthdate: "1992-05-13T22:00:00.000Z",
    displayName: "Musi Ndlovu",
  },
  {
    lastName: "Mothapo",
    firstName: "Jonas",
    birthdateString: "1986 May 16",
    birthdate: "1986-05-15T22:00:00.000Z",
    displayName: "Jonas Mothapo",
  },
  {
    lastName: "Manyelo",
    firstName: "Kathelo Gavin",
    birthdateString: "1988 May 17",
    birthdate: "1988-05-16T22:00:00.000Z",
    displayName: "Kathelo Gavin Manyelo",
  },
  {
    lastName: "Chabalala",
    firstName: "Morris Tinyiko",
    birthdateString: "1971 May 24",
    birthdate: "1971-05-23T23:00:00.000Z",
    displayName: "Morris Tinyiko Chabalala",
  },
  {
    lastName: "Wilkinson",
    firstName: "Michaela",
    birthdateString: "1994 May 24",
    birthdate: "1994-05-23T22:00:00.000Z",
    displayName: "Michaela Wilkinson",
  },
  {
    lastName: "Olivier",
    firstName: "Frank",
    birthdateString: "1973 May 25",
    birthdate: "1973-05-24T23:00:00.000Z",
    displayName: "Frank Olivier",
  },
  {
    lastName: "Rademeyer",
    firstName: "Rudi",
    birthdateString: "1987 May 28",
    birthdate: "1987-05-27T22:00:00.000Z",
    displayName: "Rudi Rademeyer",
  },
  {
    lastName: "Louw",
    firstName: "Ricardo",
    birthdateString: "1984 May 28",
    birthdate: "1984-05-27T22:00:00.000Z",
    displayName: "Ricardo Louw",
  },
  {
    lastName: "Thomas",
    firstName: "Alister",
    birthdateString: "1983 May 29",
    birthdate: "1983-05-28T22:00:00.000Z",
    displayName: "Alister Thomas",
  },
  {
    lastName: "Lourens",
    firstName: "Neil",
    birthdateString: "1981 May 30",
    birthdate: "1981-05-29T22:00:00.000Z",
    displayName: "Neil Lourens",
  },
  {
    lastName: "Ravat",
    firstName: "Mohamed",
    birthdateString: "1953 June 1",
    birthdate: "1953-05-31T23:00:00.000Z",
    displayName: "Mohamed Ravat",
  },
  {
    lastName: "Scheepers",
    firstName: "Heinrich",
    birthdateString: "1984 June 6",
    birthdate: "1984-06-05T22:00:00.000Z",
    displayName: "Heinrich Scheepers",
  },
  {
    lastName: "Smith",
    firstName: "Catherine",
    birthdateString: "1960 June 6",
    birthdate: "1960-06-05T23:00:00.000Z",
    displayName: "Catherine Smith",
  },
  {
    lastName: "Benjamin",
    firstName: "Jonathan",
    birthdateString: "1967 June 6",
    birthdate: "1967-06-05T23:00:00.000Z",
    displayName: "Jonathan Benjamin",
  },
  {
    lastName: "Ncube",
    firstName: "Silindile",
    birthdateString: "1986 June 7",
    birthdate: "1986-06-06T22:00:00.000Z",
    displayName: "Silindile Ncube",
  },
  {
    lastName: "Jeftha",
    firstName: "Diego",
    birthdateString: "1993 June 9",
    birthdate: "1993-06-08T22:00:00.000Z",
    displayName: "Diego Jeftha",
  },
  {
    lastName: "Mogopa",
    firstName: "Kate",
    birthdateString: "1974 June 11",
    birthdate: "1974-06-10T23:00:00.000Z",
    displayName: "Kate Mogopa",
  },
  {
    lastName: "Ismail",
    firstName: "Roeshdeen",
    birthdateString: "1993 June 16",
    birthdate: "1993-06-15T22:00:00.000Z",
    displayName: "Roeshdeen Ismail",
  },
  {
    lastName: "Khumalo",
    firstName: "Siphiwe",
    birthdateString: "1987 June 16",
    birthdate: "1987-06-15T22:00:00.000Z",
    displayName: "Siphiwe Khumalo",
  },
  {
    lastName: "Labuschagne",
    firstName: "Byron",
    birthdateString: "1988 June 17",
    birthdate: "1988-06-16T22:00:00.000Z",
    displayName: "Byron Labuschagne",
  },
  {
    lastName: "Van Rooyen",
    firstName: "Ranier",
    birthdateString: "1986 June 20",
    birthdate: "1986-06-19T22:00:00.000Z",
    displayName: "Ranier Van Rooyen",
  },
  {
    lastName: "Diamandis",
    firstName: "Helen",
    birthdateString: "1969 June 22",
    birthdate: "1969-06-21T23:00:00.000Z",
    displayName: "Helen Diamandis",
  },
  {
    lastName: "Victor",
    firstName: "Maronel",
    birthdateString: "1976 June 23",
    birthdate: "1976-06-22T23:00:00.000Z",
    displayName: "Maronel Victor",
  },
  {
    lastName: "Bele",
    firstName: "Ntombifuthi Girl Again",
    birthdateString: "1989 June 26",
    birthdate: "1989-06-25T22:00:00.000Z",
    displayName: "Ntombifuthi Girl Again Bele",
  },
  {
    lastName: "White",
    firstName: "Stephen",
    birthdateString: "1970 June 27",
    birthdate: "1970-06-26T23:00:00.000Z",
    displayName: "Stephen White",
  },
  {
    lastName: "Griesel",
    firstName: "Werner",
    birthdateString: "1986 July 1",
    birthdate: "1986-06-30T22:00:00.000Z",
    displayName: "Werner Griesel",
  },
  {
    lastName: "Louw",
    firstName: "Barend",
    birthdateString: "1981 July 1",
    birthdate: "1981-06-30T22:00:00.000Z",
    displayName: "Barend Louw",
  },
  {
    lastName: "Maluleka",
    firstName: "Fortunate",
    birthdateString: "1994 July 4",
    birthdate: "1994-07-03T22:00:00.000Z",
    displayName: "Fortunate Maluleka",
  },
  {
    lastName: "Rothschild",
    firstName: "Ryan",
    birthdateString: "1985 July 5",
    birthdate: "1985-07-04T22:00:00.000Z",
    displayName: "Ryan Rothschild",
  },
  {
    lastName: "Pillay",
    firstName: "Alston",
    birthdateString: "1992 July 7",
    birthdate: "1992-07-06T22:00:00.000Z",
    displayName: "Alston Pillay",
  },
  {
    lastName: "Whittal",
    firstName: "Shaun",
    birthdateString: "1982 July 8",
    birthdate: "1982-07-07T22:00:00.000Z",
    displayName: "Shaun Whittal",
  },
  {
    lastName: "Govender",
    firstName: "Devashen",
    birthdateString: "1989 July 9",
    birthdate: "1989-07-08T22:00:00.000Z",
    displayName: "Devashen Govender",
  },
  {
    lastName: "Van Eden",
    firstName: "Melville",
    birthdateString: "1974 July 12",
    birthdate: "1974-07-11T23:00:00.000Z",
    displayName: "Melville Van Eden",
  },
  {
    lastName: "Monyeki",
    firstName: "Derrick",
    birthdateString: "1982 July 13",
    birthdate: "1982-07-12T22:00:00.000Z",
    displayName: "Derrick Monyeki",
  },
  {
    lastName: "Da Silva",
    firstName: "Nicholas",
    birthdateString: "1983 July 14",
    birthdate: "1983-07-13T22:00:00.000Z",
    displayName: "Nicholas Da Silva",
  },
  {
    lastName: "Rargwase",
    firstName: "Refiloe",
    birthdateString: "1983 July 14",
    birthdate: "1983-07-13T22:00:00.000Z",
    displayName: "Refiloe Rargwase",
  },
  {
    lastName: "Archary",
    firstName: "Kumaran",
    birthdateString: "1969 July 19",
    birthdate: "1969-07-18T23:00:00.000Z",
    displayName: "Kumaran Archary",
  },
  {
    lastName: "Sambo",
    firstName: "Bernard",
    birthdateString: "1988 July 20",
    birthdate: "1988-07-19T22:00:00.000Z",
    displayName: "Bernard Sambo",
  },
  {
    lastName: "Wheeler",
    firstName: "Nanette",
    birthdateString: "1989 July 21",
    birthdate: "1989-07-20T22:00:00.000Z",
    displayName: "Nanette Wheeler",
  },
  {
    lastName: "Tau",
    firstName: "Tsibela",
    birthdateString: "1984 July 21",
    birthdate: "1984-07-20T22:00:00.000Z",
    displayName: "Tsibela Tau",
  },
  {
    lastName: "Venter",
    firstName: "Chris",
    birthdateString: "1984 July 28",
    birthdate: "1984-07-27T22:00:00.000Z",
    displayName: "Chris Venter",
  },
  {
    lastName: "Birnie",
    firstName: "Justin",
    birthdateString: "1984 August 1",
    birthdate: "1984-07-31T22:00:00.000Z",
    displayName: "Justin Birnie",
  },
  {
    lastName: "Moroke",
    firstName: "Vincent",
    birthdateString: "1974 August 4",
    birthdate: "1974-08-03T23:00:00.000Z",
    displayName: "Vincent Moroke",
  },
  {
    lastName: "Lebotsa",
    firstName: "James",
    birthdateString: "1969 August 5",
    birthdate: "1969-08-04T23:00:00.000Z",
    displayName: "James Lebotsa",
  },
  {
    lastName: "Lee",
    firstName: "Nevin (Fabian)",
    birthdateString: "1977 August 8",
    birthdate: "1977-08-07T22:00:00.000Z",
    displayName: "Nevin (Fabian) Lee",
  },
  {
    lastName: "Robinson",
    firstName: "Keagan Rhys",
    birthdateString: "1988 August 8",
    birthdate: "1988-08-07T22:00:00.000Z",
    displayName: "Keagan Rhys Robinson",
  },
  {
    lastName: "Ramabulana",
    firstName: "Tendani",
    birthdateString: "1988 August 10",
    birthdate: "1988-08-09T22:00:00.000Z",
    displayName: "Tendani Ramabulana",
  },
  {
    lastName: "Botha",
    firstName: "Kobus",
    birthdateString: "1963 August 12",
    birthdate: "1963-08-11T23:00:00.000Z",
    displayName: "Kobus Botha",
  },
  {
    lastName: "Leseke",
    firstName: "Tonnie",
    birthdateString: "1977 August 12",
    birthdate: "1977-08-11T22:00:00.000Z",
    displayName: "Tonnie Leseke",
  },
  {
    lastName: "Laurence",
    firstName: "Alastair",
    birthdateString: "1969 August 12",
    birthdate: "1969-08-11T23:00:00.000Z",
    displayName: "Alastair Laurence",
  },
  {
    lastName: "Khan Oliver",
    firstName: "Sherwin",
    birthdateString: "1994 August 15",
    birthdate: "1994-08-14T22:00:00.000Z",
    displayName: "Sherwin Khan Oliver",
  },
  {
    lastName: "Matoba",
    firstName: "Innocent",
    birthdateString: "1992 August 23",
    birthdate: "1992-08-22T22:00:00.000Z",
    displayName: "Innocent Matoba",
  },
  {
    lastName: "Nenzhelele",
    firstName: "Calvin",
    birthdateString: "1977 August 28",
    birthdate: "1977-08-27T22:00:00.000Z",
    displayName: "Calvin Nenzhelele",
  },
  {
    lastName: "Harling",
    firstName: "Lauren",
    birthdateString: "1987 August 28",
    birthdate: "1987-08-27T22:00:00.000Z",
    displayName: "Lauren Harling",
  },
  {
    lastName: "Nkosi",
    firstName: "Derrick",
    birthdateString: "1974 September 1",
    birthdate: "1974-08-31T23:00:00.000Z",
    displayName: "Derrick Nkosi",
  },
  {
    lastName: "Kilian",
    firstName: "Johan",
    birthdateString: "1972 September 12",
    birthdate: "1972-09-11T23:00:00.000Z",
    displayName: "Johan Kilian",
  },
  {
    lastName: "Miya",
    firstName: "Taryn",
    birthdateString: "1990 September 13",
    birthdate: "1990-09-12T22:00:00.000Z",
    displayName: "Taryn Miya",
  },
  {
    lastName: "Sivell",
    firstName: "Mark",
    birthdateString: "1979 September 14",
    birthdate: "1979-09-13T22:00:00.000Z",
    displayName: "Mark Sivell",
  },
  {
    lastName: "De Abreu",
    firstName: "Firmino",
    birthdateString: "1975 September 15",
    birthdate: "1975-09-14T23:00:00.000Z",
    displayName: "Firmino De Abreu",
  },
  {
    lastName: "Chauke",
    firstName: "Charles",
    birthdateString: "1979 September 16",
    birthdate: "1979-09-15T22:00:00.000Z",
    displayName: "Charles Chauke",
  },
  {
    lastName: "Swanepoel",
    firstName: "Johan",
    birthdateString: "1990 September 20",
    birthdate: "1990-09-19T22:00:00.000Z",
    displayName: "Johan Swanepoel",
  },
  {
    lastName: "Witbooi",
    firstName: "Mark Anthony",
    birthdateString: "1983 September 21",
    birthdate: "1983-09-20T22:00:00.000Z",
    displayName: "Mark Anthony Witbooi",
  },
  {
    lastName: "Masuku",
    firstName: "Ndondana",
    birthdateString: "1975 September 23",
    birthdate: "1975-09-22T23:00:00.000Z",
    displayName: "Ndondana Masuku",
  },
  {
    lastName: "Lalchund",
    firstName: "Jayshree",
    birthdateString: "1978 September 28",
    birthdate: "1978-09-27T22:00:00.000Z",
    displayName: "Jayshree Lalchund",
  },
  {
    lastName: "Forrester-Jones",
    firstName: "Warren",
    birthdateString: "1980 September 29",
    birthdate: "1980-09-28T23:00:00.000Z",
    displayName: "Warren Forrester-Jones",
  },
  {
    lastName: "Cele",
    firstName: "Vusi",
    birthdateString: "1965 September 29",
    birthdate: "1965-09-28T23:00:00.000Z",
    displayName: "Vusi Cele",
  },
  {
    lastName: "Amod",
    firstName: "Inayath",
    birthdateString: "1989 September 30",
    birthdate: "1989-09-29T23:00:00.000Z",
    displayName: "Inayath Amod",
  },
  {
    lastName: "Khawula",
    firstName: "Mlungisi",
    birthdateString: "1986 September 30",
    birthdate: "1986-09-29T23:00:00.000Z",
    displayName: "Mlungisi Khawula",
  },
  {
    lastName: "Pepper",
    firstName: "Craig",
    birthdateString: "1972 September 30",
    birthdate: "1972-09-29T23:00:00.000Z",
    displayName: "Craig Pepper",
  },
  {
    lastName: "Oosthuyzen",
    firstName: "Larry",
    birthdateString: "1987 October 1",
    birthdate: "1987-09-30T23:00:00.000Z",
    displayName: "Larry Oosthuyzen",
  },
  {
    lastName: "Mzamani",
    firstName: "Musa",
    birthdateString: "1983 October 5",
    birthdate: "1983-10-04T23:00:00.000Z",
    displayName: "Musa Mzamani",
  },
  {
    lastName: "Phuthini",
    firstName: "Joseph",
    birthdateString: "1964 October 6",
    birthdate: "1964-10-05T23:00:00.000Z",
    displayName: "Joseph Phuthini",
  },
  {
    lastName: "HlaHla",
    firstName: "Andisiwe",
    birthdateString: "1991 October 6",
    birthdate: "1991-10-05T23:00:00.000Z",
    displayName: "Andisiwe HlaHla",
  },
  {
    lastName: "Bessinger",
    firstName: "Liesl",
    birthdateString: "1976 October 8",
    birthdate: "1976-10-07T23:00:00.000Z",
    displayName: "Liesl Bessinger",
  },
  {
    lastName: "Coetzer",
    firstName: "Charl",
    birthdateString: "1988 October 9",
    birthdate: "1988-10-08T23:00:00.000Z",
    displayName: "Charl Coetzer",
  },
  {
    lastName: "Haskins",
    firstName: "John",
    birthdateString: "1985 October 13",
    birthdate: "1985-10-12T23:00:00.000Z",
    displayName: "John Haskins",
  },
  {
    lastName: "Weeber",
    firstName: "Werner",
    birthdateString: "1966 October 16",
    birthdate: "1966-10-15T23:00:00.000Z",
    displayName: "Werner Weeber",
  },
  {
    lastName: "Prince",
    firstName: "Lucille",
    birthdateString: "1954 October 16",
    birthdate: "1954-10-15T23:00:00.000Z",
    displayName: "Lucille Prince",
  },
  {
    lastName: "Mohasa",
    firstName: "Agnes",
    birthdateString: "1989 October 17",
    birthdate: "1989-10-16T23:00:00.000Z",
    displayName: "Agnes Mohasa",
  },
  {
    lastName: "Breet",
    firstName: "Madelein",
    birthdateString: "1982 October 22",
    birthdate: "1982-10-21T23:00:00.000Z",
    displayName: "Madelein Breet",
  },
  {
    lastName: "Prinsloo ",
    firstName: "Dirk",
    birthdateString: "1987 October 27",
    birthdate: "1987-10-26T23:00:00.000Z",
    displayName: "Dirk Prinsloo ",
  },
  {
    lastName: "Ndlovu",
    firstName: "Piet Thulani",
    birthdateString: "1989 October 29",
    birthdate: "1989-10-28T23:00:00.000Z",
    displayName: "Piet Thulani Ndlovu",
  },
  {
    lastName: "Davids",
    firstName: "Wade",
    birthdateString: "1975 October 31",
    birthdate: "1975-10-30T23:00:00.000Z",
    displayName: "Wade Davids",
  },
  {
    lastName: "Mkhathswa",
    firstName: "William",
    birthdateString: "1986 November 1",
    birthdate: "1986-10-31T23:00:00.000Z",
    displayName: "William Mkhathswa",
  },
  {
    lastName: "Matlapeng",
    firstName: "Naome",
    birthdateString: "1968 November 17",
    birthdate: "1968-11-16T23:00:00.000Z",
    displayName: "Naome Matlapeng",
  },
  {
    lastName: "Harmse",
    firstName: "Crisna",
    birthdateString: "1974 November 19",
    birthdate: "1974-11-18T23:00:00.000Z",
    displayName: "Crisna Harmse",
  },
  {
    lastName: "Nkosi",
    firstName: "Nkululeko",
    birthdateString: "1984 November 24",
    birthdate: "1984-11-23T23:00:00.000Z",
    displayName: "Nkululeko Nkosi",
  },
  {
    lastName: "Pillay",
    firstName: "Ray Lucre",
    birthdateString: "1988 November 26",
    birthdate: "1988-11-25T23:00:00.000Z",
    displayName: "Ray Lucre Pillay",
  },
  {
    lastName: "Sigonyela",
    firstName: "Neliswa",
    birthdateString: "1981 November 27",
    birthdate: "1981-11-26T23:00:00.000Z",
    displayName: "Neliswa Sigonyela",
  },
  {
    lastName: "Beyers",
    firstName: "Shanene",
    birthdateString: "1981 November 30",
    birthdate: "1981-11-29T23:00:00.000Z",
    displayName: "Shanene Beyers",
  },
  {
    lastName: "Ndlazi",
    firstName: "Vusi",
    birthdateString: "1962 December 4",
    birthdate: "1962-12-03T23:00:00.000Z",
    displayName: "Vusi Ndlazi",
  },
  {
    lastName: "Moloi",
    firstName: "Thabo",
    birthdateString: "1978 December 5",
    birthdate: "1978-12-04T23:00:00.000Z",
    displayName: "Thabo Moloi",
  },
  {
    lastName: "Wissner",
    firstName: "Yolanda",
    birthdateString: "1972 December 9",
    birthdate: "1972-12-08T23:00:00.000Z",
    displayName: "Yolanda Wissner",
  },
  {
    lastName: "Sennelo",
    firstName: "Kabelo",
    birthdateString: "1983 December 9",
    birthdate: "1983-12-08T23:00:00.000Z",
    displayName: "Kabelo Sennelo",
  },
  {
    lastName: "Mwale",
    firstName: "Sam",
    birthdateString: "1961 December 10",
    birthdate: "1961-12-09T23:00:00.000Z",
    displayName: "Sam Mwale",
  },
  {
    lastName: "Regel",
    firstName: "Chris",
    birthdateString: "1968 December 10",
    birthdate: "1968-12-09T23:00:00.000Z",
    displayName: "Chris Regel",
  },
  {
    lastName: "Mcqueen",
    firstName: "Owen",
    birthdateString: "1964 December 11",
    birthdate: "1964-12-10T23:00:00.000Z",
    displayName: "Owen Mcqueen",
  },
  {
    lastName: "Naidoo",
    firstName: "Melissa",
    birthdateString: "1980 December 13",
    birthdate: "1980-12-12T23:00:00.000Z",
    displayName: "Melissa Naidoo",
  },
  {
    lastName: "Vorster",
    firstName: "Suzette",
    birthdateString: "1978 December 17",
    birthdate: "1978-12-16T23:00:00.000Z",
    displayName: "Suzette Vorster",
  },
  {
    lastName: "Ndhlovo",
    firstName: "John",
    birthdateString: "1980 December 18",
    birthdate: "1980-12-17T23:00:00.000Z",
    displayName: "John Ndhlovo",
  },
  {
    lastName: "Lomas",
    firstName: "Chantal",
    birthdateString: "1972 December 22",
    birthdate: "1972-12-21T23:00:00.000Z",
    displayName: "Chantal Lomas",
  },
  {
    lastName: "Makiva",
    firstName: "Sibusiso",
    birthdateString: "1978 December 25",
    birthdate: "1978-12-24T23:00:00.000Z",
    displayName: "Sibusiso Makiva",
  },
  {
    lastName: "Mash",
    firstName: "Ryan",
    birthdateString: "1989 December 25",
    birthdate: "1989-12-24T23:00:00.000Z",
    displayName: "Ryan Mash",
  },
  {
    lastName: "Jugmohan",
    firstName: "Kiran",
    birthdateString: "1990 December 26",
    birthdate: "1990-12-25T23:00:00.000Z",
    displayName: "Kiran Jugmohan",
  },
  {
    lastName: "Anderson",
    firstName: "Sharmaine",
    birthdateString: "1969 December 26",
    birthdate: "1969-12-25T23:00:00.000Z",
    displayName: "Sharmaine Anderson",
  },
  {
    lastName: "Lombard",
    firstName: "Alexia",
    birthdateString: "1987 December 26",
    birthdate: "1987-12-25T23:00:00.000Z",
    displayName: "Alexia Lombard",
  },
  {
    lastName: "Kraayeveld",
    firstName: "Lionel",
    birthdateString: "1990 December 30",
    birthdate: "1990-12-29T23:00:00.000Z",
    displayName: "Lionel Kraayeveld",
  },
  {
    lastName: "Orange",
    firstName: "Steven",
    birthdateString: "1972 January 22",
    birthdate: "1972-01-21T23:00:00.000Z",
    displayName: "Steven Orange",
  },
  {
    lastName: "Mbizane",
    firstName: "Zwelethu",
    birthdateString: "1982 April 9",
    birthdate: "1982-04-08T22:00:00.000Z",
    displayName: "Zwelethu Mbizane",
  },
  {
    lastName: "Zithwana",
    firstName: "Christopher",
    birthdateString: "1986 May 6",
    birthdate: "1986-05-05T22:00:00.000Z",
    displayName: "Christopher Zithwana",
  },
  {
    lastName: "Ellis",
    firstName: "Nanette",
    birthdateString: "1989 July 21",
    birthdate: "1989-07-20T22:00:00.000Z",
    displayName: "Nanette Ellis",
  },
  {
    lastName: "Nemakwarani",
    firstName: "Neliswa",
    birthdateString: "1981 November 27",
    birthdate: "1981-11-26T23:00:00.000Z",
    displayName: "Neliswa Nemakwarani",
  },
];

export const missingBirthdays = [
  {
    name: "Natasha Kotze",
    year: 1976,
    month: "November",
    day: 20,
  },
  {
    name: "Chrisna Harmse",
    year: 1974,
    month: "November",
    day: 19,
  },
  {
    name: "Catherine Mompati",
    year: 1979,
    month: "March",
    day: 20,
  },
  {
    name: "Renier Van Rooyen",
    year: 1986,
    month: "June",
    day: 20,
  },
  {
    name: "Roché Swiegers",
    year: 1970,
    month: "March",
    day: "04",
  },
  {
    name: "Keagan Robinson",
    year: 1988,
    month: "August",
    day: "08",
  },
  {
    name: "Morne Odendaal",
    year: 1985,
    month: "February",
    day: 23,
  },
  {
    name: "John Ndhlovu",
    year: 1980,
    month: "December",
    day: 18,
  },
  {
    name: "Jaco Erasmus",
    year: 1978,
    month: "September",
    day: 26,
  },
  {
    name: "Elizabeth Phatlane",
    year: 1983,
    month: "November",
    day: 27,
  },
  {
    name: "Cathy Smith",
    year: 1960,
    month: "June",
    day: "06",
  },
  {
    name: "Don Froneman",
    year: 1969,
    month: "February",
    day: "07",
  },
  {
    name: "Kevin Archary",
    year: 1969,
    month: "July",
    day: 19,
  },
  {
    name: "Uluande Marais",
    year: 1964,
    month: "February",
    day: "06",
  },
  {
    name: "Lenard Khumalo",
    year: 1974,
    month: "April",
    day: 27,
  },
  {
    name: "Thulani Ndlovu",
    year: 1989,
    month: "October",
    day: 29,
  },
  {
    name: "Ray Pillay",
    year: 1988,
    month: "November",
    day: 26,
  },
  {
    name: "Maylene Gavin",
    year: 1978,
    month: "October",
    day: "03",
  },
  {
    name: "JP Zwiegelaar",
    year: 1986,
    month: "April",
    day: 22,
  },
  {
    name: "Nkanyiso Mbhedje",
    year: 1990,
    month: "March",
    day: 11,
  },
  {
    name: "Graeme Brebner",
    year: 1983,
    month: "January",
    day: 24,
  },
  {
    name: "Pieter Delport",
    year: 1985,
    month: "July",
    day: 17,
  },
  {
    name: "Ntombifuthi Bele",
    year: 1989,
    month: "June",
    day: 26,
  },
  {
    name: "Christine van Niekerk",
    year: 1981,
    month: "January",
    day: 17,
  },
  {
    name: "vusi ndlazi",
    year: 1962,
    month: "December",
    day: "04",
  },
  {
    name: "Nathan Johnston",
    year: 1989,
    month: "April",
    day: 21,
  },
  {
    name: "Ancke Oelofse",
    year: 1994,
    month: "August",
    day: "03",
  },
  {
    name: "Synthia Mantsi",
    year: 1994,
    month: "March",
    day: 15,
  },
  {
    name: "Andisiwe Hlahla",
    year: 1991,
    month: "October",
    day: "06",
  },
  {
    name: "Craig Allchurch",
    year: 1979,
    month: "January",
    day: 18,
  },
  {
    name: "William Mkhatshwa",
    year: 1986,
    month: "November",
    day: 1,
  },
  {
    name: "Willem Serfontein",
    year: 1988,
    month: "September",
    day: 16,
  },
  {
    name: "Philip Heyns",
    year: 1978,
    month: "March",
    day: "08",
  },
  {
    name: "Katleho Gavin Manyelo",
    year: 1988,
    month: "May",
    day: 17,
  },
  {
    name: "Johan Killian",
    year: 1972,
    month: "September",
    day: 12,
  },
];

export const rawBirthdays = [
  {
    name: "De Klerk, Allison",
    __1: "De Klerk, Allison",
    Year: 1979,
    Month: "January",
    Date: 2,
    __2: "",
    Age: 40,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Ramlakhan, Shakira",
    __1: "Ramlakhan, Shakira",
    Year: 1982,
    Month: "January",
    Date: 5,
    __2: "",
    Age: 37,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Thabethe, Jane",
    __1: "Thabethe Jane Smangele",
    Year: 1978,
    Month: "January",
    Date: 6,
    __2: "",
    Age: 41,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Vusumusi, Brian",
    __1: "Maphaga Vusumuzi Brian",
    Year: 1979,
    Month: "January",
    Date: 15,
    __2: "",
    Age: 40,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Le Roux, Brandon",
    __1: "Le Roux, Brandon",
    Year: 1990,
    Month: "January",
    Date: 15,
    __2: "",
    Age: 29,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Van Aarde, Le Roux",
    __1: "Van Aarde, Le Roux",
    Year: 1977,
    Month: "January",
    Date: 16,
    __2: "",
    Age: 42,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Van Niekerk, Christine",
    __1: "C Van Niekerk",
    Year: 1981,
    Month: "January",
    Date: 17,
    __2: "",
    Age: 38,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Ntuli, Paulina",
    __1: "Ntuli, Paulina",
    Year: 1980,
    Month: "January",
    Date: 17,
    __2: "",
    Age: 39,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Allchurch, John Craig",
    __1: "Allchurch, John Craig",
    Year: 1979,
    Month: "January",
    Date: 18,
    __2: "",
    Age: 40,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Sereo, Priscilla",
    __1: "P Sereo",
    Year: 1992,
    Month: "February",
    Date: 2,
    __2: "",
    Age: 27,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Molangoana, Patrick",
    __1: "Molangoana, Patrick",
    Year: 1963,
    Month: "February",
    Date: 4,
    __2: "",
    Age: 56,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Rosin, Laetitia",
    __1: "L Rosin",
    Year: 1963,
    Month: "February",
    Date: 6,
    __2: "",
    Age: 56,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Marais, Urscheluande",
    __1: "U Marais",
    Year: 1964,
    Month: "February",
    Date: 6,
    __2: "",
    Age: 55,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Froneman, Donald",
    __1: "Froneman, Donald",
    Year: 1969,
    Month: "February",
    Date: 7,
    __2: "",
    Age: 50,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Smith, Jacques",
    __1: "J Smith",
    Year: 1989,
    Month: "February",
    Date: 13,
    __2: "",
    Age: 30,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Thamba Vuma, Alex",
    __1: "AT Vuma",
    Year: 1970,
    Month: "February",
    Date: 15,
    __2: "",
    Age: 49,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Maluleka, Sandra",
    __1: "Maluleka Sandra",
    Year: 1972,
    Month: "February",
    Date: 17,
    __2: "",
    Age: 47,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Breed, Mariessa",
    __1: "M Breed",
    Year: 1972,
    Month: "February",
    Date: 23,
    __2: "",
    Age: 47,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Odendaal, Morne Johann",
    __1: "MJ Odendaal",
    Year: 1985,
    Month: "February",
    Date: 23,
    __2: "",
    Age: 34,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "De Klerk, Johannes",
    __1: "H de Klerk",
    Year: 1970,
    Month: "February",
    Date: 28,
    __2: "",
    Age: 49,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Van Der Merwe, Morne",
    __1: "M Van Der Merwe",
    Year: 1976,
    Month: "March",
    Date: 1,
    __2: "",
    Age: 43,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Van der Bank, Hilda",
    __1: "H Van der Bank",
    Year: 1973,
    Month: "March",
    Date: 3,
    __2: "",
    Age: 46,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Swiegers, Roche",
    __1: "R Swiegers",
    Year: 1970,
    Month: "March",
    Date: 4,
    __2: "",
    Age: 49,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mamfengu, Kaizer",
    __1: "K Mamfengu",
    Year: 1979,
    Month: "March",
    Date: 5,
    __2: "",
    Age: 40,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Morifi, Ernest",
    __1: "Morifi, Ernest",
    Year: 1977,
    Month: "March",
    Date: 7,
    __2: "",
    Age: 42,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Hadebe, Claude",
    __1: "Claude Hadebe",
    Year: 1977,
    Month: "March",
    Date: 8,
    __2: "",
    Age: 42,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Lottering, Warren",
    __1: "Lottering, Warren",
    Year: 1981,
    Month: "March",
    Date: 9,
    __2: "",
    Age: 38,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Ratshibvumo, Johannes",
    __1: "Ratshibvumo, Jonathan",
    Year: 1972,
    Month: "March",
    Date: 10,
    __2: "",
    Age: 47,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mbhedje, Nkanyiso (Wanda)",
    __1: "Nkanyiso (Wanda)",
    Year: 1990,
    Month: "March",
    Date: 11,
    __2: "",
    Age: 29,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mkhasibe, Jabulane",
    __1: "J Mkhasibe",
    Year: 1986,
    Month: "March",
    Date: 12,
    __2: "",
    Age: 33,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Peterson, Jacob",
    __1: "Peterson, Jacob",
    Year: 1962,
    Month: "March",
    Date: 12,
    __2: "",
    Age: 57,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Dedekind, Anke",
    __1: "A Dedekind",
    Year: 1978,
    Month: "March",
    Date: 13,
    __2: "",
    Age: 41,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Hanekom, Jacqueline",
    __1: "J Hanekom",
    Year: 1973,
    Month: "March",
    Date: 20,
    __2: "",
    Age: 46,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Wecke, Brendon",
    __1: "Wecke, Brendon",
    Year: 1980,
    Month: "March",
    Date: 20,
    __2: "",
    Age: 39,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mompati, Cathrine",
    __1: "Mompati, Cathrine",
    Year: 1979,
    Month: "March",
    Date: 20,
    __2: "",
    Age: 40,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Phiri, Billy",
    __1: "Phiri, Billy",
    Year: 1963,
    Month: "March",
    Date: 21,
    __2: "",
    Age: 56,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Naidoo, Lesley",
    __1: "Naidoo, Lesley",
    Year: 1985,
    Month: "March",
    Date: 28,
    __2: "",
    Age: 34,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Bezuidenhout, Minette",
    __1: "M Bezuidenhout",
    Year: 1980,
    Month: "April",
    Date: 2,
    __2: "",
    Age: 39,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Du Toit, Pieter",
    __1: "Pieter du Toit",
    Year: 1978,
    Month: "April",
    Date: 6,
    __2: "",
    Age: 41,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mbizane, Zwelethu",
    __1: "Z Mbizane",
    Year: 1982,
    Month: "April",
    Date: 9,
    __2: "",
    Age: 37,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mathebula, Samuel",
    __1: "Mathebula, Samuel",
    Year: 1972,
    Month: "April",
    Date: 10,
    __2: "",
    Age: 47,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Senaone, Lesley",
    __1: "Senaoane Lebohang Lesley",
    Year: 1990,
    Month: "April",
    Date: 11,
    __2: "",
    Age: 29,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Sebati, Keabetswe",
    __1: "Keabetswe Sebati",
    Year: 1992,
    Month: "April",
    Date: 11,
    __2: "",
    Age: 27,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Strauss, Jean",
    __1: "J Strauss",
    Year: 1984,
    Month: "April",
    Date: 12,
    __2: "",
    Age: 35,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mosikidi, Refiloe",
    __1: "Refiloe Mosikidi",
    Year: 1988,
    Month: "April",
    Date: 19,
    __2: "",
    Age: 31,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Johnston, Nathan Dale",
    __1: "Johnston, Nathan Dale",
    Year: 1989,
    Month: "April",
    Date: 21,
    __2: "",
    Age: 30,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Zwiegelaar, Johan",
    __1: "Zwiegelaar, Johan",
    Year: 1986,
    Month: "April",
    Date: 22,
    __2: "",
    Age: 33,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Lelaka, Phipps",
    __1: "Lelaka Phipps",
    Year: 1986,
    Month: "April",
    Date: 23,
    __2: "",
    Age: 33,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Fraser, Jason",
    __1: "J Fraser",
    Year: 1978,
    Month: "April",
    Date: 27,
    __2: "",
    Age: 41,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Khumalo, Khulahi (Lenard)",
    __1: "Khumalo Khulani",
    Year: 1974,
    Month: "April",
    Date: 27,
    __2: "",
    Age: 45,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Pitse, Thulane",
    __1: "Pitse, Thulane",
    Year: 1983,
    Month: "May",
    Date: 9,
    __2: "",
    Age: 36,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Ndlovu, Musi",
    __1: "Ndlovu Muzi",
    Year: 1992,
    Month: "May",
    Date: 14,
    __2: "",
    Age: 27,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mothapo, Jonas",
    __1: "Mothapo Jonas",
    Year: 1986,
    Month: "May",
    Date: 16,
    __2: "",
    Age: 33,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Manyelo, Kathelo Gavin",
    __1: "KG Manyelo",
    Year: 1988,
    Month: "May",
    Date: 17,
    __2: "",
    Age: 31,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Chabalala, Morris Tinyiko",
    __1: "Chabalala Tinyiko",
    Year: 1971,
    Month: "May",
    Date: 24,
    __2: "",
    Age: 48,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Wilkinson, Michaela",
    __1: "M Wilkinson",
    Year: 1994,
    Month: "May",
    Date: 24,
    __2: "",
    Age: 25,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Olivier, Frank",
    __1: "Olivier, Frank",
    Year: 1973,
    Month: "May",
    Date: 25,
    __2: "",
    Age: 46,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Rademeyer, Rudi",
    __1: "R Rademeyer",
    Year: 1987,
    Month: "May",
    Date: 28,
    __2: "",
    Age: 32,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Louw, Ricardo",
    __1: "RL Louw",
    Year: 1984,
    Month: "May",
    Date: 28,
    __2: "",
    Age: 35,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Thomas, Alister",
    __1: "A Thomas",
    Year: 1983,
    Month: "May",
    Date: 29,
    __2: "",
    Age: 36,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Lourens, Neil",
    __1: "Lourens, Neil",
    Year: 1981,
    Month: "May",
    Date: 30,
    __2: "",
    Age: 38,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Ravat, Mohamed",
    __1: "M Ravat",
    Year: 1953,
    Month: "June",
    Date: 1,
    __2: "",
    Age: 66,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Scheepers, Heinrich",
    __1: "Scheepers Heinrich",
    Year: 1984,
    Month: "June",
    Date: 6,
    __2: "",
    Age: 35,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Smith, Catherine",
    __1: "CI Smith",
    Year: 1960,
    Month: "June",
    Date: 6,
    __2: "",
    Age: 59,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Benjamin, Jonathan",
    __1: "Benjamin, Jonathan",
    Year: 1967,
    Month: "June",
    Date: 6,
    __2: "",
    Age: 52,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Ncube, Silindile",
    __1: "S Ncube",
    Year: 1986,
    Month: "June",
    Date: 7,
    __2: "",
    Age: 33,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Jeftha, Diego",
    __1: "Jeftha, Diego",
    Year: 1993,
    Month: "June",
    Date: 9,
    __2: "",
    Age: 26,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mogopa, Kate",
    __1: "K Mogopa",
    Year: 1974,
    Month: "June",
    Date: 11,
    __2: "",
    Age: 45,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Ismail, Roeshdeen",
    __1: "Roeshdeen",
    Year: 1993,
    Month: "June",
    Date: 16,
    __2: "",
    Age: 26,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Khumalo, Siphiwe",
    __1: "Khumalo, Siphiwe",
    Year: 1987,
    Month: "June",
    Date: 16,
    __2: "",
    Age: 32,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Labuschagne, Byron",
    __1: "B Labuschagne",
    Year: 1988,
    Month: "June",
    Date: 17,
    __2: "",
    Age: 31,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Van Rooyen, Ranier",
    __1: "Van Rooyen Renier",
    Year: 1986,
    Month: "June",
    Date: 20,
    __2: "",
    Age: 33,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Diamandis, Helen",
    __1: "Diamandis, Helen",
    Year: 1969,
    Month: "June",
    Date: 22,
    __2: "",
    Age: 50,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Victor, Maronel",
    __1: "M Victor",
    Year: 1976,
    Month: "June",
    Date: 23,
    __2: "",
    Age: 43,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Bele, Ntombifuthi Girl Again",
    __1: "Bele Ntombifuthi",
    Year: 1989,
    Month: "June",
    Date: 26,
    __2: "",
    Age: 30,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "White, Stephen",
    __1: "White, Stephen",
    Year: 1970,
    Month: "June",
    Date: 27,
    __2: "",
    Age: 49,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Griesel, Werner",
    __1: "W Griesel",
    Year: 1986,
    Month: "July",
    Date: 1,
    __2: "",
    Age: 33,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Louw, Barend",
    __1: "Louw, Barend",
    Year: 1981,
    Month: "July",
    Date: 1,
    __2: "",
    Age: 38,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Maluleka, Fortunate",
    __1: "Maluleka Fortunate",
    Year: 1994,
    Month: "July",
    Date: 4,
    __2: "",
    Age: 25,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Rothschild, Ryan",
    __1: "Rothschild, Ryan",
    Year: 1985,
    Month: "July",
    Date: 5,
    __2: "",
    Age: 34,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Pillay, Alston",
    __1: "Pillay, Alston",
    Year: 1992,
    Month: "July",
    Date: 7,
    __2: "",
    Age: 27,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Whittal, Shaun",
    __1: "SA Whittal",
    Year: 1982,
    Month: "July",
    Date: 8,
    __2: "",
    Age: 37,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Govender, Devashen",
    __1: "Devashen Govender",
    Year: 1989,
    Month: "July",
    Date: 9,
    __2: "",
    Age: 30,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Van Eden, Melville",
    __1: "Melville van Eden",
    Year: 1974,
    Month: "July",
    Date: 12,
    __2: "",
    Age: 45,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Monyeki, Derrick",
    __1: "Monyeki, Derrick",
    Year: 1982,
    Month: "July",
    Date: 13,
    __2: "",
    Age: 37,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Da Silva, Nicholas",
    __1: "N Da Silva",
    Year: 1983,
    Month: "July",
    Date: 14,
    __2: "",
    Age: 36,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Rargwase, Refiloe",
    __1: "Rargwase, Refiloe",
    Year: 1983,
    Month: "July",
    Date: 14,
    __2: "",
    Age: 36,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Archary, Kumaran",
    __1: "Archary, Kevin",
    Year: 1969,
    Month: "July",
    Date: 19,
    __2: "",
    Age: 50,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Sambo, Bernard",
    __1: "Sambo Bernard",
    Year: 1988,
    Month: "July",
    Date: 20,
    __2: "",
    Age: 31,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Wheeler, Nanette",
    __1: "N Wheeler",
    Year: 1989,
    Month: "July",
    Date: 21,
    __2: "",
    Age: 30,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Tau, Tsibela",
    __1: "Tsibela Tau",
    Year: 1984,
    Month: "July",
    Date: 21,
    __2: "",
    Age: 35,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Venter, Chris",
    __1: "C Venter",
    Year: 1984,
    Month: "July",
    Date: 28,
    __2: "",
    Age: 35,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Birnie, Justin",
    __1: "J Birnie",
    Year: 1984,
    Month: "August",
    Date: 1,
    __2: "",
    Age: 35,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Moroke, Vincent",
    __1: "V Moroke",
    Year: 1974,
    Month: "August",
    Date: 4,
    __2: "",
    Age: 45,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Lebotsa, James",
    __1: "Lebotsa Mpho James",
    Year: 1969,
    Month: "August",
    Date: 5,
    __2: "",
    Age: 50,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Lee, Nevin (Fabian)",
    __1: "NF Lee",
    Year: 1977,
    Month: "August",
    Date: 8,
    __2: "",
    Age: 42,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Robinson, Keagan Rhys",
    __1: "KR Robinson",
    Year: 1988,
    Month: "August",
    Date: 8,
    __2: "",
    Age: 31,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Ramabulana, Tendani",
    __1: "Ramabulana, Tendani",
    Year: 1988,
    Month: "August",
    Date: 10,
    __2: "",
    Age: 31,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Botha, Kobus",
    __1: "K Botha",
    Year: 1963,
    Month: "August",
    Date: 12,
    __2: "",
    Age: 56,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Leseke, Tonnie",
    __1: "Leseke, Tonnie",
    Year: 1977,
    Month: "August",
    Date: 12,
    __2: "",
    Age: 42,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Laurence, Alastair",
    __1: "Alastair Laurence",
    Year: 1969,
    Month: "August",
    Date: 12,
    __2: "",
    Age: 50,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Khan Oliver, Sherwin",
    __1: "Oliver Sherwin",
    Year: 1994,
    Month: "August",
    Date: 15,
    __2: "",
    Age: 25,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Matoba, Innocent",
    __1: "Matoba, Innocent",
    Year: 1992,
    Month: "August",
    Date: 23,
    __2: "",
    Age: 27,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Nenzhelele, Calvin",
    __1: "Nenzhelele, Calvin",
    Year: 1977,
    Month: "August",
    Date: 28,
    __2: "",
    Age: 42,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Hutchinson, Lauren-Lee",
    __1: "Hutchinson Lauren-Lee",
    Year: 1987,
    Month: "August",
    Date: 28,
    __2: "",
    Age: 32,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Nkosi, Derrick",
    __1: "Nkosi Derrick",
    Year: 1974,
    Month: "September",
    Date: 1,
    __2: "",
    Age: 45,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Kilian, Johan",
    __1: "Kilian, Johan",
    Year: 1972,
    Month: "September",
    Date: 12,
    __2: "",
    Age: 47,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Miya, Taryn",
    __1: "T Miya",
    Year: 1990,
    Month: "September",
    Date: 13,
    __2: "",
    Age: 29,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Sivell, Mark",
    __1: "M Sivell",
    Year: 1979,
    Month: "September",
    Date: 14,
    __2: "",
    Age: 40,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "De Abreu, Firmino",
    __1: "De Abreu, Firmino",
    Year: 1975,
    Month: "September",
    Date: 15,
    __2: "",
    Age: 44,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Chauke, Charles",
    __1: "Chauke Mkhacau Benneth",
    Year: 1979,
    Month: "September",
    Date: 16,
    __2: "",
    Age: 40,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Swanepoel, Johan",
    __1: "J Swanepoel",
    Year: 1990,
    Month: "September",
    Date: 20,
    __2: "",
    Age: 29,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Witbooi, Mark Anthony",
    __1: "Witbooi Mark Anthony",
    Year: 1983,
    Month: "September",
    Date: 21,
    __2: "",
    Age: 36,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Masuku, Ndondana",
    __1: "Masuku, Ndondana",
    Year: 1975,
    Month: "September",
    Date: 23,
    __2: "",
    Age: 44,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Lalchund, Jayshree",
    __1: "Lalchund, Jayshree",
    Year: 1978,
    Month: "September",
    Date: 28,
    __2: "",
    Age: 41,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Forrester-Jones, Warren",
    __1: "Forrester-Jones, Warren",
    Year: 1980,
    Month: "September",
    Date: 29,
    __2: "",
    Age: 39,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Cele, Vusi",
    __1: "Cele, Vusi",
    Year: 1965,
    Month: "September",
    Date: 29,
    __2: "",
    Age: 54,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Amod, Inayath",
    __1: "I Amod",
    Year: 1989,
    Month: "September",
    Date: 30,
    __2: "",
    Age: 30,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Khawula, Mlungisi",
    __1: "Khwela Mthokozisi Mlungisi",
    Year: 1986,
    Month: "September",
    Date: 30,
    __2: "",
    Age: 33,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Pepper, Craig",
    __1: "C Pepper",
    Year: 1972,
    Month: "September",
    Date: 30,
    __2: "",
    Age: 47,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Oosthuyzen, Larry",
    __1: "Larry Oosthuyzen",
    Year: 1987,
    Month: "October",
    Date: 1,
    __2: "",
    Age: 32,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mzamani, Musa",
    __1: "Chauke Mzamani Musa",
    Year: 1983,
    Month: "October",
    Date: 5,
    __2: "",
    Age: 36,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Phuthini, Joseph",
    __1: "Phuthini Joseph Siphiwe",
    Year: 1964,
    Month: "October",
    Date: 6,
    __2: "",
    Age: 55,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "HlaHla, Andisiwe",
    __1: "Hlahla Andisiwe",
    Year: 1991,
    Month: "October",
    Date: 6,
    __2: "",
    Age: 28,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Bessinger, Liesl",
    __1: "L Bessinger",
    Year: 1976,
    Month: "October",
    Date: 8,
    __2: "",
    Age: 43,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Coetzer, Charl",
    __1: "Coetzer Charl",
    Year: 1988,
    Month: "October",
    Date: 9,
    __2: "",
    Age: 31,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Haskins, John",
    __1: "J Haskins",
    Year: 1985,
    Month: "October",
    Date: 13,
    __2: "",
    Age: 34,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Weeber, Werner",
    __1: "Weeber, Werner",
    Year: 1966,
    Month: "October",
    Date: 16,
    __2: "",
    Age: 53,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Prince, Lucille",
    __1: "Prince, Lucille",
    Year: 1954,
    Month: "October",
    Date: 16,
    __2: "",
    Age: 65,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mohasa, Agnes",
    __1: "Mohasa, Agnes",
    Year: 1989,
    Month: "October",
    Date: 17,
    __2: "",
    Age: 30,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Breet, Madelein",
    __1: "M Breet",
    Year: 1982,
    Month: "October",
    Date: 22,
    __2: "",
    Age: 37,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Prinsloo , Dirk",
    __1: "Dirk Prinsloo",
    Year: 1987,
    Month: "October",
    Date: 27,
    __2: "",
    Age: 32,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Ndlovu, Piet Thulani",
    __1: "PT Ndlovu",
    Year: 1989,
    Month: "October",
    Date: 29,
    __2: "",
    Age: 30,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Davids, Wade",
    __1: "Wade Davids",
    Year: 1975,
    Month: "October",
    Date: 31,
    __2: "",
    Age: 44,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mkhathswa, William",
    __1: "W Mkhatshwa",
    Year: 1986,
    Month: "November",
    Date: 1,
    __2: "",
    Age: 33,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Matlapeng, Naome",
    __1: "Matlapeng, Naome",
    Year: 1968,
    Month: "November",
    Date: 17,
    __2: "",
    Age: 51,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Harmse, Crisna",
    __1: "C Harmse",
    Year: 1974,
    Month: "November",
    Date: 19,
    __2: "",
    Age: 45,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Nkosi, Nkululeko",
    __1: "Nkululeko Nkosi",
    Year: 1984,
    Month: "November",
    Date: 24,
    __2: "",
    Age: 35,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Pillay, Ray Lucre",
    __1: "Ray Pillay",
    Year: 1988,
    Month: "November",
    Date: 26,
    __2: "",
    Age: 31,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Sigonyela, Neliswa",
    __1: "Sigonyela Neliswa",
    Year: 1981,
    Month: "November",
    Date: 27,
    __2: "",
    Age: 38,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Beyers, Shanene",
    __1: "S Beyers",
    Year: 1981,
    Month: "November",
    Date: 30,
    __2: "",
    Age: 38,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Ndlazi, Vusi",
    __1: "Ndlazi, Vusi",
    Year: 1962,
    Month: "December",
    Date: 4,
    __2: "",
    Age: 57,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Moloi, Thabo",
    __1: "Moloi, Thabo",
    Year: 1978,
    Month: "December",
    Date: 5,
    __2: "",
    Age: 41,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Wissner, Yolanda",
    __1: "Y Wissner",
    Year: 1972,
    Month: "December",
    Date: 9,
    __2: "",
    Age: 47,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Sennelo, Kabelo",
    __1: "Sennelo, Kabelo",
    Year: 1983,
    Month: "December",
    Date: 9,
    __2: "",
    Age: 36,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mwale, Sam",
    __1: "S Mwale",
    Year: 1961,
    Month: "December",
    Date: 10,
    __2: "",
    Age: 58,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Regel, Chris",
    __1: "Regel, Chris",
    Year: 1968,
    Month: "December",
    Date: 10,
    __2: "",
    Age: 51,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mcqueen, Owen",
    __1: "Mcqueen, Owen",
    Year: 1964,
    Month: "December",
    Date: 11,
    __2: "",
    Age: 55,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Naidoo, Melissa",
    __1: "Naidoo, Melissa",
    Year: 1980,
    Month: "December",
    Date: 13,
    __2: "",
    Age: 39,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Vorster, Suzette",
    __1: "S Vorster",
    Year: 1978,
    Month: "December",
    Date: 17,
    __2: "",
    Age: 41,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Ndhlovo, John",
    __1: "John Ndhlovo",
    Year: 1980,
    Month: "December",
    Date: 18,
    __2: "",
    Age: 39,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Lomas, Chantal",
    __1: "C Lomas",
    Year: 1972,
    Month: "December",
    Date: 22,
    __2: "",
    Age: 47,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Makiva, Sibusiso",
    __1: "S Makiva",
    Year: 1978,
    Month: "December",
    Date: 25,
    __2: "",
    Age: 41,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Mash, Ryan",
    __1: "R Mash",
    Year: 1989,
    Month: "December",
    Date: 25,
    __2: "",
    Age: 30,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Jugmohan, Kiran",
    __1: "Jugmohan Kiran",
    Year: 1990,
    Month: "December",
    Date: 26,
    __2: "",
    Age: 29,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Anderson, Sharmaine",
    __1: "S Anderson",
    Year: 1969,
    Month: "December",
    Date: 26,
    __2: "",
    Age: 50,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Lombard, Alexia",
    __1: "A Lombard",
    Year: 1987,
    Month: "December",
    Date: 26,
    __2: "",
    Age: 32,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },
  {
    name: "Kraayeveld, Lionel",
    __1: "L Kraayeveld",
    Year: 1990,
    Month: "December",
    Date: 30,
    __2: "",
    Age: 29,
    __3: "",
    __4: "",
    __5: "",
    __6: "",
    __7: "",
    __8: "",
    __9: "",
  },

  {
    name: "Orange, Steven",
    __1: "S Orange",
    IDNum: 7201225408084,
    Year: 1972,
    Month: "January",
    Date: 22,
    Age: "M",
    __3: 47,
    __4: "",
    __5: "Addition",
    __6: "01/10/1998",
    __7: "1998/10/01",
    __8: "20 years, 11 months",
    __9: "20 years, 11 months",
  },
  {
    name: "Mbizane, Zwelethu",
    __1: "Z Mbizane",
    IDNum: 8204095488086,
    Year: 1982,
    Month: "April",
    Date: 9,
    Age: "M",
    __3: 37,
    __4: "",
    __5: "",
    __6: "01/05/2016",
    __7: "05/01/2016",
    __8: "3 years, 4 months",
    __9: "3 years, 4 months",
  },
  {
    name: "Zithwana, Christopher",
    __1: "Zithwana, Christopher",
    IDNum: 8605065701086,
    Year: 1986,
    Month: "May",
    Date: 6,
    Age: "M",
    __3: 33,
    __4: "",
    __5: "Addition",
    __6: "01/07/2018",
    __7: "2018/07/01",
    __8: "1 years, 2 months",
    __9: "",
  },
  {
    name: "Ellis, Nanette",
    __1: "N Ellis",
    IDNum: 8907210063085,
    Year: 1989,
    Month: "July",
    Date: 21,
    Age: "F",
    __3: 30,
    __4: "",
    __5: "",
    __6: "02/12/2013",
    __7: "2013/12/02",
    __8: "5 years, 9 months",
    __9: "",
  },
  {
    name: "Nemakwarani, Neliswa",
    __1: "Sigonyela Neliswa",
    IDNum: 8111271075084,
    Year: 1981,
    Month: "November",
    Date: 27,
    Age: "F",
    __3: 38,
    __4: "",
    __5: "",
    __6: "01/07/2016",
    __7: "2016/07/01",
    __8: "3 years, 2 months",
    __9: "",
  },
];

export const dbUsers = [
  {
    lastName: "Bessinger",
    displayName: "Liesl Bessinger",
    firstName: "Liesl",
    designation: "Events & Marketing Manager",
    user_id: 5,
    teammate_id: 47,
    region: "gauteng",
    department: "marketing-and-events",
    role: "employee",
    email: "liesl.bessinger@zimmerbiomet.com",
    points: 1837,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Liesl.jpg",
    birthdate: "1976-10-07T23:00:00.000Z",
  },
  {
    lastName: "Louw",
    displayName: "Ricardo Louw",
    firstName: "Ricardo",
    designation: "Sales Executive",
    user_id: 9,
    teammate_id: 129,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Ricardo.louw@zimmerbiomet.com",
    points: 100,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Ricardo.jpg",
    birthdate: "1984-05-27T22:00:00.000Z",
  },
  {
    lastName: "Rosin",
    displayName: "Laetitia Rosin",
    firstName: "Laetitia",
    designation: "Customer Services Manager",
    user_id: 11,
    teammate_id: 277,
    region: "gauteng",
    department: "customer-service",
    role: "employee",
    email: "laetitia.rosin@zimmerbiomet.com",
    points: 220,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Laetitia.jpg",
    birthdate: "1963-02-05T23:00:00.000Z",
  },
  {
    lastName: "Ncube",
    displayName: "Silindile Ncube",
    firstName: "Silindile",
    designation: "Customer Services Executive",
    user_id: 15,
    teammate_id: 66,
    region: "gauteng",
    department: "customer-service",
    role: "employee",
    email: "silindile.ncube@zimmerbiomet.com",
    points: 287,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Lindy.jpg",
    birthdate: "1986-06-06T22:00:00.000Z",
  },
  {
    lastName: "Thomas",
    displayName: "Alister Thomas",
    firstName: "Alister",
    designation: "Jnr. Product Manager- RECON and FEST",
    user_id: 17,
    teammate_id: 35,
    region: "gauteng",
    department: "marketing",
    role: "employee",
    email: "alister.thomas@zimmerbiomet.com",
    points: 317,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Alistar-Thomas-Junior-Accountant-.jpg",
    birthdate: "1983-05-28T22:00:00.000Z",
  },
  {
    lastName: "Lomas",
    displayName: "Chantal Lomas",
    firstName: "Chantal",
    designation: "Senior Finance Accountant",
    user_id: 20,
    teammate_id: 37,
    region: "gauteng",
    department: "finance",
    role: "employee",
    email: "chantal.lomas@zimmerbiomet.com",
    points: 377,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Chantal.jpg",
    birthdate: "1972-12-21T23:00:00.000Z",
  },
  {
    lastName: "Anderson",
    displayName: "Sharmaine Anderson",
    firstName: "Sharmaine",
    designation: "Customer Service Executive",
    user_id: 21,
    teammate_id: 285,
    region: "gauteng",
    department: "customer-service",
    role: "employee",
    email: "sharmaine.anderson@zimmerbiomet.com",
    points: 413,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Sharmaine.jpg",
    birthdate: "1969-12-25T23:00:00.000Z",
  },
  {
    lastName: "Regel",
    displayName: "Chris Regel",
    firstName: "Chris",
    designation: "Key Accounts Manager",
    user_id: 23,
    teammate_id: 45,
    region: "cape-town",
    department: "sales-cape-town",
    role: "employee",
    email: "chris.regel@zimmerbiomet.com",
    points: 230,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Chris-Regel.jpg",
    birthdate: "1968-12-09T23:00:00.000Z",
  },
  {
    lastName: "Amod",
    displayName: "Inayath Amod",
    firstName: "Inayath",
    designation: "Warehouse Manager",
    user_id: 26,
    teammate_id: 254,
    region: "kwazulu-natal",
    department: "operations-kwazulu-natal",
    role: "employee",
    email: "Inayath.amod@zimmerbiomet.com",
    points: 548,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/im-1.jpg",
    birthdate: "1989-09-29T23:00:00.000Z",
  },
  {
    lastName: "Mash",
    displayName: "Ryan Mash",
    firstName: "Ryan",
    designation: "Key Accounts Manager",
    user_id: 27,
    teammate_id: 145,
    region: "kwazulu-natal",
    department: "sales-kwazulu-natal",
    role: "employee",
    email: "ryan.mash@zimmerbiomet.com",
    points: 100,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Ryan-Mash-Sales-Executive-.jpg",
    birthdate: "1989-12-24T23:00:00.000Z",
  },
  {
    lastName: "Wissner",
    displayName: "Yolanda Wissner",
    firstName: "Yolanda",
    designation: "Business Administration Manager",
    user_id: 29,
    teammate_id: 58,
    region: "gauteng",
    department: "head-office",
    role: "employee",
    email: "yolanda.wissner@zimmerbiomet.com",
    points: 2094,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Yolanda.jpg",
    birthdate: "1972-12-08T23:00:00.000Z",
  },
  {
    lastName: "Jugmohan",
    displayName: "Kiran Jugmohan",
    firstName: "Kiran",
    designation: "Rotating Kit Coordinator",
    user_id: 31,
    teammate_id: 256,
    region: "kwazulu-natal",
    department: "operations-kwazulu-natal",
    role: "employee",
    email: "Kiran.Jugmohan@zimmerbiomet.com",
    points: 253,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Kiran-1.jpg",
    birthdate: "1990-12-25T23:00:00.000Z",
  },
  {
    lastName: "Bele",
    displayName: "Ntombifuthi Bele",
    firstName: "Ntombifuthi",
    designation: "Rotating Kit Coordinator",
    user_id: 32,
    teammate_id: 258,
    region: "kwazulu-natal",
    department: "operations-kwazulu-natal",
    role: "employee",
    email: "Ntombifuthi.Bele@zimmerbiomet.com",
    points: 195,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Ntombifuthi-Bele-Warehouse-Logistics.jpg",
    birthdate: "1989-06-25T22:00:00.000Z",
  },
  {
    lastName: "ndlazi",
    displayName: "vusi ndlazi",
    firstName: "vusi",
    designation: "Rotating Kit Coordinator",
    user_id: 35,
    teammate_id: 250,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "vusi.ndlazi@zimmerbiomet.com",
    points: 671,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Vusi-Ndla.jpg",
    birthdate: "1962-12-03T23:00:00.000Z",
  },
  {
    lastName: "Zwiegelaar",
    displayName: "JP Zwiegelaar",
    firstName: "JP",
    designation: "Regional Sales Manager",
    user_id: 37,
    teammate_id: 117,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Johan.Zwiegelaar@zimmerbiomet.com",
    points: 425,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/JP.jpg",
    birthdate: "1986-04-21T22:00:00.000Z",
  },
  {
    lastName: "Cele",
    displayName: "Vusi Cele",
    firstName: "Vusi",
    designation: "Rotating Kit Coordinator",
    user_id: 39,
    teammate_id: 248,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "vusi.cele@zimmerbiomet.com",
    points: 1534,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Vusi.jpg",
    birthdate: "1965-09-28T23:00:00.000Z",
  },
  {
    lastName: "Leseke",
    displayName: "Tonnie Leseke",
    firstName: "Tonnie",
    designation: "Loan Set Planning Manager",
    user_id: 40,
    teammate_id: 246,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "tonnie.leseke@zimmerbiomet.com",
    points: 292,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Tonnie.jpg",
    birthdate: "1977-08-11T22:00:00.000Z",
  },
  {
    lastName: "Odendaal",
    displayName: "Morne Odendaal",
    firstName: "Morne",
    designation: "Sales Executive",
    user_id: 42,
    teammate_id: 190,
    region: "free-state",
    department: "sales-free-state",
    role: "employee",
    email: "mornejohann.odendaal@zimmerbiomet.com",
    points: 26,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Morne-Odendaal-Warehouse-Logisticsl.jpg",
    birthdate: "1985-02-22T23:00:00.000Z",
  },
  {
    lastName: "Mogopa",
    displayName: "Kate Mogopa",
    firstName: "Kate",
    designation: "Customer Services Executive",
    user_id: 43,
    teammate_id: 273,
    region: "gauteng",
    department: "customer-service",
    role: "employee",
    email: "kate.mogopa@zimmerbiomet.com",
    points: 845,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Kate.jpg",
    birthdate: "1974-06-10T23:00:00.000Z",
  },
  {
    lastName: "Pitse",
    displayName: "Thulane Pitse",
    firstName: "Thulane",
    designation: "Loan Set Planner",
    user_id: 46,
    teammate_id: 244,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "thulane.pitse@zimmerbiomet.com",
    points: 417,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Thulane.jpg",
    birthdate: "1983-05-08T22:00:00.000Z",
  },
  {
    lastName: "Lottering",
    displayName: "Warren Lottering",
    firstName: "Warren",
    designation: "Product Lead - RECON & FEST",
    user_id: 47,
    teammate_id: 52,
    region: "gauteng",
    department: "marketing",
    role: "employee",
    email: "warren.lottering@zimmerbiomet.com",
    points: 331,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Warren-Lottering.jpg",
    birthdate: "1981-03-08T23:00:00.000Z",
  },
  {
    lastName: "Froneman",
    displayName: "Don Froneman",
    firstName: "Don",
    designation: "Sales Executive",
    user_id: 49,
    teammate_id: 70,
    region: "free-state",
    department: "sales-free-state",
    role: "employee",
    email: "donald.froneman@zimmerbiomet.com",
    points: 1844,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Donald-Froneman-Sales-Executive.jpg",
    birthdate: "1969-02-06T23:00:00.000Z",
  },
  {
    lastName: "Harmse",
    displayName: "Chrisna Harmse",
    firstName: "Chrisna",
    designation: "Key Accounts Manager",
    user_id: 50,
    teammate_id: 103,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "crisna.harmse@zimmerbiomet.com",
    points: 100,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Chrisna.jpg",
    birthdate: "1974-11-18T23:00:00.000Z",
  },
  {
    lastName: "van Niekerk",
    displayName: "Christine van Niekerk",
    firstName: "Christine",
    designation: "Sales Executive",
    user_id: 56,
    teammate_id: 105,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "christine.vanniekerk@zimmerbiomet.com",
    points: 51,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Christine-van-Niekerk-Sales-Executive-.jpg",
    birthdate: "1981-01-16T23:00:00.000Z",
  },
  {
    lastName: "Whittal",
    displayName: "Shaun Whittal",
    firstName: "Shaun",
    designation: "Regional Sales Manager",
    user_id: 58,
    teammate_id: 135,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Shaun.whittal@zimmerbiomet.com",
    points: 31,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Shaun.jpg",
    birthdate: "1982-07-07T22:00:00.000Z",
  },
  {
    lastName: "Van der Bank",
    displayName: "Hilda Van der Bank",
    firstName: "Hilda",
    designation: "Sales Executive",
    user_id: 63,
    teammate_id: 111,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "hilda.vanderbank@zimmerbiomet.com",
    points: 242,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Hilda.jpg",
    birthdate: "1973-03-02T23:00:00.000Z",
  },
  {
    lastName: "Smith",
    displayName: "Cathy Smith",
    firstName: "Cathy",
    designation: "Sales Executive",
    user_id: 65,
    teammate_id: 99,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "catherine.smith@zimmerbiomet.com",
    points: 265,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Cathy.jpg",
    birthdate: "1960-06-05T23:00:00.000Z",
  },
  {
    lastName: "Diamandis",
    displayName: "Helen Diamandis",
    firstName: "Helen",
    designation: "Consignment and Special Projects Manager",
    user_id: 68,
    teammate_id: 54,
    region: "gauteng",
    department: "operations",
    role: "employee",
    email: "helen.diamandis@zimmerbiomet.com",
    points: 275,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Helen.jpg",
    birthdate: "1969-06-21T23:00:00.000Z",
  },
  {
    lastName: "Dedekind",
    displayName: "Anke Dedekind",
    firstName: "Anke",
    designation: "Sales Executive",
    user_id: 71,
    teammate_id: 321,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "anke.dedekind@zimmerbiomet.com",
    points: 130,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/12/Anke-1.jpg",
    birthdate: "1978-03-12T23:00:00.000Z",
  },
  {
    lastName: "Venter",
    displayName: "Chris Venter",
    firstName: "Chris",
    designation: "Sales Executive",
    user_id: 74,
    teammate_id: 160,
    region: "kwazulu-natal",
    department: "sales-trauma-agents",
    role: "employee",
    email: "chris.venter@zimmerbiomet.com",
    points: 220,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Christopher-Venter-Sales-Executive.jpg",
    birthdate: "1984-07-27T22:00:00.000Z",
  },
  {
    lastName: "Bezuidenhout",
    displayName: "Minette Bezuidenhout",
    firstName: "Minette",
    designation: "Customer Services Executive ",
    user_id: 76,
    teammate_id: 389,
    region: "gauteng",
    department: "customer-service",
    role: "employee",
    email: "Minette.Bezuidenhout@zimmerbiomet.com",
    points: 1331,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2016/08/Minette.jpg",
    birthdate: "1980-04-01T23:00:00.000Z",
  },
  {
    lastName: "Harling",
    displayName: "Lauren Harling",
    firstName: "Lauren",
    designation: "Events & Marketing Coordinator",
    user_id: 77,
    teammate_id: 1014,
    region: "gauteng",
    department: "marketing-and-events",
    role: "employee",
    email: "LaurenLee.Hutchinson@zimmerbiomet.com",
    points: 2151,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Lauren-Lee.jpg",
    birthdate: "1987-08-27T22:00:00.000Z",
  },
  {
    lastName: "Breet",
    displayName: "Madelein Breet",
    firstName: "Madelein",
    designation: "Transactional Finance Manager",
    user_id: 78,
    teammate_id: 392,
    region: "gauteng",
    department: "finance",
    role: "employee",
    email: "Madelein.Breet@zimmerbiomet.com",
    points: 233,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2016/08/Madelein.jpg",
    birthdate: "1982-10-21T23:00:00.000Z",
  },
  {
    lastName: "Marais",
    displayName: "Uluande Marais",
    firstName: "Uluande",
    designation: "Sales Executive",
    user_id: 79,
    teammate_id: 82,
    region: "cape-town",
    department: "sales-cape-town",
    role: "employee",
    email: "Urscheluande.marais@zimmerbiomet.com",
    points: 547,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Uluandi-Marais-Sales-Executive-.jpg",
    birthdate: "1964-02-05T23:00:00.000Z",
  },
  {
    lastName: "Lombard",
    displayName: "Alexia Lombard",
    firstName: "Alexia",
    designation: "Theatre Technician",
    user_id: 84,
    teammate_id: 375,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Alexia.Lombard@zimmerbiomet.com",
    points: 79,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2016/05/Alexia-Profile.jpg",
    birthdate: "1987-12-25T23:00:00.000Z",
  },
  {
    lastName: "Hlahla",
    displayName: "Andisiwe Hlahla",
    firstName: "Andisiwe",
    designation: "Loan Set Planner",
    user_id: 87,
    teammate_id: 552,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Andisiwe.HlaHla@zimmerbiomet.com",
    points: 1045,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2017/06/Andisiwe.jpg",
    birthdate: "1991-10-05T23:00:00.000Z",
  },
  {
    lastName: "Sambo",
    displayName: "Bernard Sambo",
    firstName: "Bernard",
    designation: "Set Building",
    user_id: 88,
    teammate_id: 195,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Bernard.Sambo@zimmerbiomet.com",
    points: 713,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Bernard.jpg",
    birthdate: "1988-07-19T22:00:00.000Z",
  },
  {
    lastName: "Le Roux",
    displayName: "Brandon Le Roux",
    firstName: "Brandon",
    designation: "Sales Executive",
    user_id: 89,
    teammate_id: 95,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Brandon.LeRoux@zimmerbiomet.com",
    points: 200,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Brandon-LeRoux-Sales-Eexutive.jpg",
    birthdate: "1990-01-14T23:00:00.000Z",
  },
  {
    lastName: "Wecke",
    displayName: "Brendon Wecke",
    firstName: "Brendon",
    designation: "Key Accounts Manager",
    user_id: 90,
    teammate_id: 97,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Brendon.Wecke@zimmerbiomet.com",
    points: 245,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Brendon.jpg",
    birthdate: "1980-03-19T23:00:00.000Z",
  },
  {
    lastName: "Vusumusi",
    displayName: "Brian Vusumusi",
    firstName: "Brian",
    designation: "Rotating Kit Coordinator",
    user_id: 91,
    teammate_id: 622,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Brian.Vusumusi@zimmerbiomet.com",
    points: 934,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2017/06/15827154781151843147701.jpg",
    birthdate: "1979-01-14T23:00:00.000Z",
  },
  {
    lastName: "Labuschagne",
    displayName: "Byron Labuschagne",
    firstName: "Byron",
    designation: "Sales Executive",
    user_id: 93,
    teammate_id: 562,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Byron.Labuschagne@zimmerbiomet.com",
    points: 75,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Byron-Labuschagne-Gaureng-Sales.jpg",
    birthdate: "1988-06-16T22:00:00.000Z",
  },
  {
    lastName: "Coetzer",
    displayName: "Charl Coetzer",
    firstName: "Charl",
    designation: "Theater Technician",
    user_id: 94,
    teammate_id: 548,
    region: "free-state",
    department: "sales-free-state",
    role: "employee",
    email: "Charl.Coetzer@zimmerbiomet.com",
    points: 30,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Charl-Coetzer-Bloemfontein-Warehouse.jpg",
    birthdate: "1988-10-08T23:00:00.000Z",
  },
  {
    lastName: "Chauke",
    displayName: "Charles Chauke",
    firstName: "Charles",
    designation: "Set Building",
    user_id: 95,
    teammate_id: 546,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Charles.Chauke@zimmerbiomet.com",
    points: 2894,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2017/06/Benneth.jpg",
    birthdate: "1979-09-15T22:00:00.000Z",
  },
  {
    lastName: "Pepper",
    displayName: "Craig Pepper",
    firstName: "Craig",
    designation: "New Business Development Manager",
    user_id: 98,
    teammate_id: 260,
    region: "sub-saharan-africa",
    department: "sales-cape-town",
    role: "employee",
    email: "Craig.Pepper@zimmerbiomet.com",
    points: 1904,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Craig-Pepper.jpg",
    birthdate: "1972-09-29T23:00:00.000Z",
  },
  {
    lastName: "Nkosi",
    displayName: "Derrick Nkosi",
    firstName: "Derrick",
    designation: "Rotating Kit Coordinator",
    user_id: 100,
    teammate_id: 208,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Derrick.Nkosi@zimmerbiomet.com",
    points: 1221,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Derrick.jpg",
    birthdate: "1974-08-31T23:00:00.000Z",
  },
  {
    lastName: "Maluleka",
    displayName: "Fortunate Maluleka",
    firstName: "Fortunate",
    designation: "Inbound Team",
    user_id: 102,
    teammate_id: 386,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Fortunate.Maluleka@zimmerbiomet.com",
    points: 252,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2016/06/Fortunate-1.jpg",
    birthdate: "1994-07-03T22:00:00.000Z",
  },
  {
    lastName: "De Klerk",
    displayName: "Johannes De Klerk",
    firstName: "Johannes",
    designation: "Key Accounts Manager",
    user_id: 104,
    teammate_id: 1761,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Hein.DeKlerk@zimmerbiomet.com",
    points: 381,
    birthdate: "1970-02-27T23:00:00.000Z",
  },
  {
    lastName: "Scheepers",
    displayName: "Heinrich Scheepers",
    firstName: "Heinrich",
    designation: "Rotating Kit Coordinator",
    user_id: 105,
    teammate_id: 172,
    region: "cape-town",
    department: "operations-cape-town",
    role: "employee",
    email: "Heinrich.Scheepers@zimmerbiomet.com",
    points: 140,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Heinrich-Scheepers-Warehouse-Logistics-and-Driver.jpg",
    birthdate: "1984-06-05T22:00:00.000Z",
  },
  {
    lastName: "Peterson",
    displayName: "Jacob Peterson",
    firstName: "Jacob",
    designation: "Driver",
    user_id: 106,
    teammate_id: 212,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Jacob.Peterson@zimmerbiomet.com",
    points: 425,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Jacob-Peterson-Driver.jpg",
    birthdate: "1962-03-11T23:00:00.000Z",
  },
  {
    lastName: "Smith",
    displayName: "Jacques Smith",
    firstName: "Jacques",
    designation: "Sales Executive",
    user_id: 108,
    teammate_id: 581,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Jacques.Smith@zimmerbiomet.com",
    points: 31,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Jacques-Smith-Gauteng-Sales.jpg",
    birthdate: "1989-02-12T23:00:00.000Z",
  },
  {
    lastName: "Lebotsa",
    displayName: "James Lebotsa",
    firstName: "James",
    designation: "Driver",
    user_id: 109,
    teammate_id: 610,
    region: "free-state",
    department: "operations-free-state",
    role: "employee",
    email: "James.Lebotsa@zimmerbiomet.com",
    points: 100,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/James-Lebotsa-Warehouse-Bloemfontein.jpg",
    birthdate: "1969-08-04T23:00:00.000Z",
  },
  {
    lastName: "Thabethe",
    displayName: "Jane Thabethe",
    firstName: "Jane",
    designation: "Rotating Kit Coordinator",
    user_id: 110,
    teammate_id: 587,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Jane.Thabethe@zimmerbiomet.com",
    points: 170,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2017/06/Jane.jpg",
    birthdate: "1978-01-05T23:00:00.000Z",
  },
  {
    lastName: "Fraser",
    displayName: "Jason Fraser",
    firstName: "Jason",
    designation: "Finance Leader",
    user_id: 112,
    teammate_id: 39,
    region: "gauteng",
    department: "finance",
    role: "employee",
    email: "Jason.Fraser@zimmerbiomet.com",
    points: 269,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Jason.jpg",
    birthdate: "1978-04-26T22:00:00.000Z",
  },
  {
    lastName: "Strauss",
    displayName: "Jean Strauss",
    firstName: "Jean",
    designation: "Sales Executive",
    user_id: 113,
    teammate_id: 583,
    region: "free-state",
    department: "sales-free-state",
    role: "employee",
    email: "JeanJaeques.Strauss@zimmerbiomet.com",
    points: 25,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Jean-Strauss-Bloemfontein-Sales.jpg",
    birthdate: "1984-04-11T22:00:00.000Z",
  },
  {
    lastName: "Swanepoel",
    displayName: "Johan Swanepoel",
    firstName: "Johan",
    designation: "Sales Executive",
    user_id: 114,
    teammate_id: 585,
    region: "free-state",
    department: "sales-free-state",
    role: "employee",
    email: "Johan.Swanepoel@zimmerbiomet.com",
    points: 25,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Johan-Swanepoel-Bloemfontein-Sales.jpg",
    birthdate: "1990-09-19T22:00:00.000Z",
  },
  {
    lastName: "Ratshibvumo",
    displayName: "Johannes Ratshibvumo",
    firstName: "Johannes",
    designation: "Driver",
    user_id: 115,
    teammate_id: 214,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Johannes.Ratshibvumo@zimmerbiomet.com",
    points: 150,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Johannes.jpg",
    birthdate: "1972-03-09T23:00:00.000Z",
  },
  {
    lastName: "@zimmerbiomet.com",
    displayName: "Jonas @zimmerbiomet.com",
    firstName: "Jonas",
    designation: "Washer",
    user_id: 116,
    teammate_id: 614,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Jonas.Mothapo@zimmerbiomet.com",
    points: 1253,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2017/06/Jonas.jpg",
  },
  {
    lastName: "Benjamin",
    displayName: "Jonathan Benjamin",
    firstName: "Jonathan",
    designation: "Driver",
    user_id: 117,
    teammate_id: 174,
    region: "cape-town",
    department: "operations-cape-town",
    role: "employee",
    email: "Jonathan.Benjamin@zimmerbiomet.com",
    points: 145,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Jonathan-Benjamin-Driver-Loan-Set-Clerk.jpg",
    birthdate: "1967-06-05T23:00:00.000Z",
  },
  {
    lastName: "Phuthini",
    displayName: "Joseph Phuthini",
    firstName: "Joseph",
    designation: "Driver",
    user_id: 119,
    teammate_id: 216,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "joseph.Phuthini@zimmerbiomet.com",
    points: 259,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Joseph.jpg",
    birthdate: "1964-10-05T23:00:00.000Z",
  },
  {
    lastName: "Birnie",
    displayName: "Justin Birnie",
    firstName: "Justin",
    designation: "Rotating Kit Coordinator",
    user_id: 120,
    teammate_id: 544,
    region: "kwazulu-natal",
    department: "operations-kwazulu-natal",
    role: "employee",
    email: "Justin.Birnie@zimmerbiomet.com",
    points: 2217,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Justin-Birnie-KZN-Warehouse.jpg",
    birthdate: "1984-07-31T22:00:00.000Z",
  },
  {
    lastName: "Mamfengu",
    displayName: "Kaizer Mamfengu",
    firstName: "Kaizer",
    designation: "Night Shift Supervisor",
    user_id: 122,
    teammate_id: 218,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Kaizer.Mamfengu@zimmerbiomet.com",
    points: 701,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Kaizer.jpg",
    birthdate: "1979-03-04T23:00:00.000Z",
  },
  {
    lastName: "Manyelo",
    displayName: "Katleho Gavin Manyelo",
    firstName: "Katleho Gavin",
    designation: "Sales Executive",
    user_id: 123,
    teammate_id: 564,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Gavin.Manyelo@zimmerbiomet.com",
    points: 211,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2017/06/KG.jpg",
    birthdate: "1988-05-16T22:00:00.000Z",
  },
  {
    lastName: "Robinson",
    displayName: "Keagan Robinson",
    firstName: "Keagan",
    designation: "Sales Executive",
    user_id: 124,
    teammate_id: 328,
    region: "kwazulu-natal",
    department: "sales-kwazulu-natal",
    role: "employee",
    email: "Keagan.Robinson@zimmerbiomet.com",
    points: 135,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/12/Keagan-Robinson-.jpg",
    birthdate: "1988-08-07T22:00:00.000Z",
  },
  {
    lastName: "Van Aarde",
    displayName: "Le Roux Van Aarde",
    firstName: "Le Roux",
    designation: "Sales Executive",
    user_id: 127,
    teammate_id: 76,
    region: "cape-town",
    department: "sales-cape-town",
    role: "employee",
    email: "LeRoux.VanAarde@zimmerbiomet.com",
    points: 50,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Le-Roux-van-Aarde-Sales-Executive.jpg",
    birthdate: "1977-01-15T23:00:00.000Z",
  },
  {
    lastName: "Senaone",
    displayName: "Lesley Senaone",
    firstName: "Lesley",
    designation: "Driver",
    user_id: 128,
    teammate_id: 188,
    region: "free-state",
    department: "operations-free-state",
    role: "employee",
    email: "Lesley.Senaone@zimmerbiomet.com",
    points: 100,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Lesley-Senaoane-Driver2.jpg",
    birthdate: "1990-04-10T22:00:00.000Z",
  },
  {
    lastName: "Kraayeveld",
    displayName: "Lionel Kraayeveld",
    firstName: "Lionel",
    designation: "Sales Executive",
    user_id: 129,
    teammate_id: 560,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Lionel.Kraayeveld@zimmerbiomet.com",
    points: 53,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Lionel-Kraayeveld-Gauteng-Sales.jpg",
    birthdate: "1990-12-29T23:00:00.000Z",
  },
  {
    lastName: "Prince",
    displayName: "Lucille Prince",
    firstName: "Lucille",
    designation: "Finance Clerk",
    user_id: 130,
    teammate_id: 281,
    region: "gauteng",
    department: "finance",
    role: "employee",
    email: "Lucille.Prince@zimmerbiomet.com",
    points: 123,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Lucille.jpg",
    birthdate: "1954-10-15T23:00:00.000Z",
  },
  {
    lastName: "Mbizane",
    displayName: "Zwelethu Mbizane",
    firstName: "Zwelethu",
    designation: "Sales Executive",
    user_id: 131,
    teammate_id: 372,
    region: "sub-saharan-africa",
    department: "sales-sub-saharan-africa",
    role: "employee",
    email: "Zwelethu.Mbizane@zimmerbiomet.com",
    points: 546,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2016/05/Zwelethu-Mbizane.jpg",
    birthdate: "1982-04-08T22:00:00.000Z",
  },
  {
    lastName: "Mkhatshwa",
    displayName: "William Mkhatshwa",
    firstName: "William",
    designation: "Sales Executive",
    user_id: 132,
    teammate_id: 566,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "William.Mkhatshwa@zimmerbiomet.com",
    points: 1548,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/William-Mkhathswa-Gauteng-Sales.jpg",
    birthdate: "1986-10-31T23:00:00.000Z",
  },
  {
    lastName: "Griesel",
    displayName: "Werner Griesel",
    firstName: "Werner",
    designation: "Regional Sales Manager",
    user_id: 133,
    teammate_id: 322,
    region: "free-state",
    department: "sales-free-state",
    role: "employee",
    email: "Werner.Griesel@zimmerbiomet.com",
    points: 125,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/12/Werner-Griesel.jpg",
    birthdate: "1986-06-30T22:00:00.000Z",
  },
  {
    lastName: "Breed",
    displayName: "Mariessa Breed",
    firstName: "Mariessa",
    designation: "Theatre Technician",
    user_id: 137,
    teammate_id: 400,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Mariessa.Breed@zimmerbiomet.com",
    points: 50,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2017/02/Mariessa.jpg",
    birthdate: "1972-02-22T23:00:00.000Z",
  },
  {
    lastName: "Witbooi",
    displayName: "Mark Anthony Witbooi",
    firstName: "Mark Anthony",
    designation: "Rotating Kit Coordinator",
    user_id: 139,
    teammate_id: 176,
    region: "cape-town",
    department: "operations-cape-town",
    role: "employee",
    email: "Mark.Witbooi@zimmerbiomet.com",
    points: 1856,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Mark-Witbooi-Warehouse-Logistics-and-Driver.jpg",
    birthdate: "1983-09-20T22:00:00.000Z",
  },
  {
    lastName: "Sivell",
    displayName: "Mark Sivell",
    firstName: "Mark",
    designation: "Sales Executive",
    user_id: 140,
    teammate_id: 579,
    region: "kwazulu-natal",
    department: "sales-kwazulu-natal",
    role: "employee",
    email: "Mark.Sivell@zimmerbiomet.com",
    points: 26,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Mark-Sivell-KZN-Sales.jpg",
    birthdate: "1979-09-13T22:00:00.000Z",
  },
  {
    lastName: "Makiva",
    displayName: "Sibusiso Makiva",
    firstName: "Sibusiso",
    designation: "Customer Services Executive",
    user_id: 143,
    teammate_id: 332,
    region: "gauteng",
    department: "customer-service",
    role: "employee",
    email: "Sibusiso.Makiva@zimmerbiomet.com",
    points: 1559,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/12/Sibusiso.jpg",
    birthdate: "1978-12-24T23:00:00.000Z",
  },
  {
    lastName: "Oliver",
    displayName: "Sherwin Khan Oliver",
    firstName: "Sherwin Khan",
    designation: "Loan Set Clerk",
    user_id: 144,
    teammate_id: 554,
    region: "kwazulu-natal",
    department: "operations-kwazulu-natal",
    role: "employee",
    email: "Sherwin.KhanOliver@zimmerbiomet.com",
    points: 81,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Sherwin-oliver-KZN-Warehouse.jpg",
    birthdate: "1994-08-14T22:00:00.000Z",
  },
  {
    lastName: "Beyers",
    displayName: "Shanene Beyers",
    firstName: "Shanene",
    designation: "Sales Executive",
    user_id: 147,
    teammate_id: 601,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Shanene.Beyers@zimmerbiomet.com",
    points: 411,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2017/06/Shanene.jpg",
    birthdate: "1981-11-29T23:00:00.000Z",
  },
  {
    lastName: "Maluleka",
    displayName: "Sandra Maluleka",
    firstName: "Sandra",
    designation: "Office Cleaner",
    user_id: 149,
    teammate_id: 64,
    region: "gauteng",
    department: "reception-and-tea-ladies",
    role: "employee",
    email: "Sandra.Maluleka@zimmerbiomet.com",
    points: 1357,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Sandra.jpg",
    birthdate: "1972-02-16T23:00:00.000Z",
  },
  {
    lastName: "Mwale",
    displayName: "Sam Mwale",
    firstName: "Sam",
    designation: "Implants Supervisor",
    user_id: 150,
    teammate_id: 238,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Sam.Mwale@zimmerbiomet.com",
    points: 43,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Sam.jpg",
    birthdate: "1961-12-09T23:00:00.000Z",
  },
  {
    lastName: "Mcqueen",
    displayName: "Owen Mcqueen",
    firstName: "Owen",
    designation: "Driver",
    user_id: 151,
    teammate_id: 180,
    region: "cape-town",
    department: "operations-cape-town",
    role: "employee",
    email: "Owen.Mcqueen@zimmerbiomet.com",
    points: 354,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Owen-McQueen-Warehouse-Assistant.jpg",
    birthdate: "1964-12-10T23:00:00.000Z",
  },
  {
    lastName: "Lelaka",
    displayName: "Phipps Lelaka",
    firstName: "Phipps",
    designation: "Rotating Kit Coordinator",
    user_id: 152,
    teammate_id: 230,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Phipps.Lelaka@zimmerbiomet.com",
    points: 800,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Phipps.jpg",
    birthdate: "1986-04-22T22:00:00.000Z",
  },
  {
    lastName: "Sigonyela",
    displayName: "Neliswa Sigonyela",
    firstName: "Neliswa",
    designation: "Rotating Kit Coordinator",
    user_id: 154,
    teammate_id: 620,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Neliswa.Sigonyela@zimmerbiomet.com",
    points: 447,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2017/06/Neliswa.jpg",
    birthdate: "1981-11-26T23:00:00.000Z",
  },
  {
    lastName: "Ndlovu",
    displayName: "Musi Ndlovu",
    firstName: "Musi",
    designation: "Rotating Kit Coordinator",
    user_id: 156,
    teammate_id: 825,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Musi.Ndlovu@zimmerbiomet.com",
    points: 1231,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Musi.jpg",
    birthdate: "1992-05-13T22:00:00.000Z",
  },
  {
    lastName: "Mzamani",
    displayName: "Musa Mzamani",
    firstName: "Musa",
    designation: "Driver",
    user_id: 157,
    teammate_id: 807,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Musa.Mzamani@zimmerbiomet.com",
    points: 1073,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Chauke-Musa.jpg",
    birthdate: "1983-10-04T23:00:00.000Z",
  },
  {
    lastName: "Chabalala",
    displayName: "Morris Tinyiko Chabalala",
    firstName: "Morris Tinyiko",
    designation: "Rotating Kit Coordinator",
    user_id: 158,
    teammate_id: 226,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Tinyiko.Chabala@zimmerbiomet.com",
    points: 2325,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Morris.jpg",
    birthdate: "1971-05-23T23:00:00.000Z",
  },
  {
    lastName: "Ravat",
    displayName: "Mohamed Ravat",
    firstName: "Mohamed",
    designation: "Loan Set Planner",
    user_id: 159,
    teammate_id: 224,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Mohamed.Ravat@zimmerbiomet.com",
    points: 558,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Mohamed.jpg",
    birthdate: "1953-05-31T23:00:00.000Z",
  },
  {
    lastName: "Khawula",
    displayName: "Mlungisi Khawula",
    firstName: "Mlungisi",
    designation: "",
    user_id: 160,
    teammate_id: 556,
    region: "kwazulu-natal",
    department: "operations-kwazulu-natal",
    role: "employee",
    email: "Mlungisi.Khawula@zimmerbiomet.com",
    points: 1835,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Mlungisi-Mthokozisi-Khwela-KZN-Warehouse.jpg",
    birthdate: "1986-09-29T23:00:00.000Z",
  },
  {
    lastName: "Ndlovu",
    displayName: "Thulani Ndlovu",
    firstName: "Thulani",
    designation: "Theater Technician",
    user_id: 161,
    teammate_id: 573,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Thulani.Ndlovu@zimmerbiomet.com",
    points: 150,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Thulani-Ndlovu-Gauteng-Sales.jpg",
    birthdate: "1989-10-28T23:00:00.000Z",
  },
  {
    lastName: "Sereo",
    displayName: "Priscilla Sereo",
    firstName: "Priscilla",
    designation: "Customer Services Executive",
    user_id: 162,
    teammate_id: 369,
    region: "gauteng",
    department: "customer-service",
    role: "employee",
    email: "Priscilla.Sereo@zimmerbiomet.com",
    points: 422,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2016/04/Priscilla.jpg",
    birthdate: "1992-02-01T23:00:00.000Z",
  },
  {
    lastName: "Refiloe",
    displayName: "Rargwase Refiloe",
    firstName: "Rargwase",
    designation: "Rotating Kit Coordinator",
    user_id: 163,
    teammate_id: 234,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Rargwase.Refiloe@zimmerbiomet.com",
    points: 776,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Refiloe-Rargwase-Loan-Set-Cleaner.jpg",
  },
  {
    lastName: "Van Rooyen",
    displayName: "Renier Van Rooyen",
    firstName: "Renier",
    designation: "Warehouse Manager",
    user_id: 164,
    teammate_id: 593,
    region: "port-elizabeth",
    department: "operations-port-elizabeth",
    role: "employee",
    email: "Renier.VanRooyen@zimmerbiomet.com",
    points: 489,
    profileImageUrl:
      "http://portalzm.bravecloud.co.za/wp-content/uploads/2017/06/Renier-van-Rooyen-Bloemfontein-Warehouse.jpg",
    birthdate: "1986-06-19T22:00:00.000Z",
  },
  {
    lastName: "Swiegers",
    displayName: "Roch\u00e9 Swiegers",
    firstName: "Roch\u00e9",
    designation: "National Sales Lead- FEST",
    user_id: 166,
    teammate_id: 402,
    region: "gauteng",
    department: "head-office",
    role: "employee",
    email: "Roche.Swiegers@zimmerbiomet.com",
    points: 736,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2017/02/Roche.jpg",
    birthdate: "1970-03-03T23:00:00.000Z",
  },
  {
    lastName: "Rademeyer",
    displayName: "Rudi Rademeyer",
    firstName: "Rudi",
    designation: "Sales Executive",
    user_id: 168,
    teammate_id: 336,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Rudi.Rademeyer@zimmerbiomet.com",
    points: 100,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2015/12/Rudi.jpg",
    birthdate: "1987-05-27T22:00:00.000Z",
  },
  {
    lastName: "Wilkinson",
    displayName: "Michaela Wilkinson",
    firstName: "Michaela",
    designation: "Customer Services Executive",
    user_id: 175,
    teammate_id: 994,
    region: "gauteng",
    department: "customer-service",
    role: "employee",
    email: "Michaela.Wilkinson@zimmerbiomet.com",
    points: 1730,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Michaela.jpg",
    birthdate: "1994-05-23T22:00:00.000Z",
  },
  {
    lastName: "Weeber",
    displayName: "Werner Weeber",
    firstName: "Werner",
    designation: "National Sales Manager -  Recon",
    user_id: 176,
    teammate_id: 1002,
    region: "gauteng",
    department: "head-office",
    role: "employee",
    email: "Werner.Weeber@zimmerbiomet.com",
    points: 295,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Werner.jpg",
    birthdate: "1966-10-15T23:00:00.000Z",
  },
  {
    lastName: "Archary",
    displayName: "Kevin Archary",
    firstName: "Kevin",
    designation: "Dispatch Supervisor",
    user_id: 177,
    teammate_id: 1003,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Kevin.Archary@zimmerbiomet.com",
    points: 863,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Kevin.jpg",
    birthdate: "1969-07-18T23:00:00.000Z",
  },
  {
    lastName: "Victor",
    displayName: "Maronel Victor",
    firstName: "Maronel",
    designation: "Logistics Planning Team",
    user_id: 178,
    teammate_id: 822,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Maronel.Victor@zimmerbiomet.com",
    points: 643,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Maronel.jpg",
    birthdate: "1976-06-22T23:00:00.000Z",
  },
  {
    lastName: "Naidoo",
    displayName: "Melissa Naidoo",
    firstName: "Melissa",
    designation: "Planning Manager",
    user_id: 180,
    teammate_id: 823,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Melissa.Naidoo@zimmerbiomet.com",
    points: 405,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Melissa.jpg",
    birthdate: "1980-12-12T23:00:00.000Z",
  },
  {
    lastName: "Allchurch",
    displayName: "Craig Allchurch",
    firstName: "Craig",
    designation: "Sales Executive",
    user_id: 182,
    teammate_id: 1007,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "Craig.Allchurch@zimmerbiomet.com",
    points: 50,
    birthdate: "1979-01-17T23:00:00.000Z",
  },
  {
    lastName: "Forrester-Jones",
    displayName: "Warren Forrester-Jones",
    firstName: "Warren",
    designation: "Sales Executive",
    user_id: 184,
    teammate_id: 999,
    region: "cape-town",
    department: "sales-spine",
    role: "employee",
    email: "Warren.ForresterJones@zimmerbiomet.com",
    points: 100,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Warren.jpg",
    birthdate: "1980-09-28T23:00:00.000Z",
  },
  {
    lastName: "Laurence",
    displayName: "Alastair Laurence",
    firstName: "Alastair",
    designation: "Sales Executive",
    user_id: 185,
    teammate_id: 803,
    region: "cape-town",
    department: "sales-cape-town",
    role: "employee",
    email: "alastair.laurence@zimmerbiomet.com",
    points: 138,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/zimmer-pfp.jpg",
    birthdate: "1969-08-11T23:00:00.000Z",
  },
  {
    lastName: "Van Eden",
    displayName: "Melville Van Eden",
    firstName: "Melville",
    designation: "Rotating Kit Coordinator",
    user_id: 191,
    teammate_id: 824,
    region: "cape-town",
    department: "operations-cape-town",
    role: "employee",
    email: "melville.vaneden@zimmerbiomet.com",
    points: 676,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/4-Melville.jpg",
    birthdate: "1974-07-11T23:00:00.000Z",
  },
  {
    lastName: "Orange",
    displayName: "Steven Orange",
    firstName: "Steven",
    designation: "Vice President Africa",
    user_id: 192,
    teammate_id: 938,
    region: "gauteng",
    department: "head-office",
    role: "employee",
    email: "steven.orange@zimmerbiomet.com",
    points: 700,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Steven.jpg",
    birthdate: "1972-01-21T23:00:00.000Z",
  },
  {
    lastName: "Moloi",
    displayName: "Thabo Moloi",
    firstName: "Thabo",
    designation: "Rotating Kit Coordinator",
    user_id: 195,
    teammate_id: 842,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "thabo.moloi@zimmerbiomet.com",
    points: 340,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Thabo.jpg",
    birthdate: "1978-12-04T23:00:00.000Z",
  },
  {
    lastName: "Ramabulana",
    displayName: "Tendani Ramabulana",
    firstName: "Tendani",
    designation: "Driver",
    user_id: 196,
    teammate_id: 841,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "tendani.ramabulana@zimmerbiomet.com",
    points: 238,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Tendani.jpg",
    birthdate: "1988-08-09T22:00:00.000Z",
  },
  {
    lastName: "Khumalo",
    displayName: "Siphiwe Khumalo",
    firstName: "Siphiwe",
    designation: "Driver",
    user_id: 197,
    teammate_id: 840,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "siphiwe.khumalo@zimmerbiomet.com",
    points: 123,
    profileImageUrl:
      "http://localhost:8888/portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Simpiwe-Khumalo.jpg",
    birthdate: "1987-06-15T22:00:00.000Z",
  },
  {
    lastName: "Mathebula",
    displayName: "Samuel Mathebula",
    firstName: "Samuel",
    designation: "Driver",
    user_id: 198,
    teammate_id: 838,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "samuel.mathebula@zimmerbiomet.com",
    points: 288,
    profileImageUrl:
      "http://localhost:8888/portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Samuel-Mathebula.jpg",
    birthdate: "1972-04-09T23:00:00.000Z",
  },
  {
    lastName: "Ramlakhan",
    displayName: "Shakira Ramlakhan",
    firstName: "Shakira",
    designation: "Compliance Manager",
    user_id: 199,
    teammate_id: 839,
    region: "gauteng",
    department: "head-office",
    role: "employee",
    email: "shakira.ramlakhan@zimmerbiomet.com",
    points: 130,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Shakira.jpg",
    birthdate: "1982-01-04T23:00:00.000Z",
  },
  {
    lastName: "Ismail",
    displayName: "Roeshdeen Ismail",
    firstName: "Roeshdeen",
    designation: "Rotating Kit Coordinator",
    user_id: 200,
    teammate_id: 837,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "roeshdeen.ismail@zimmerbiomet.com",
    points: 1621,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Roeshdeen.jpg",
    birthdate: "1993-06-15T22:00:00.000Z",
  },
  {
    lastName: "Pillay",
    displayName: "Ray Pillay",
    firstName: "Ray",
    designation: "Logistics Planning Team",
    user_id: 201,
    teammate_id: 835,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "raylucre.pillay@zimmerbiomet.com",
    points: 1649,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Ray.jpg",
    birthdate: "1988-11-25T23:00:00.000Z",
  },
  {
    lastName: "Ntuli",
    displayName: "Paulina Ntuli",
    firstName: "Paulina",
    designation: "Loan Set Planner",
    user_id: 203,
    teammate_id: 833,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "paulina.ntuli@zimmerbiomet.com",
    points: 124,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Paulina.jpg",
    birthdate: "1980-01-16T23:00:00.000Z",
  },
  {
    lastName: "Molangoana",
    displayName: "Patrick Molangoana",
    firstName: "Patrick",
    designation: "Driver",
    user_id: 204,
    teammate_id: 832,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "patrick.molangoana@zimmerbiomet.com",
    points: 1506,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Patrick.jpg",
    birthdate: "1963-02-03T23:00:00.000Z",
  },
  {
    lastName: "Nkosi",
    displayName: "Nkululeko Nkosi",
    firstName: "Nkululeko",
    designation: "Rotating Kit Coordinator",
    user_id: 205,
    teammate_id: 831,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "nkululeko.nkosi@zimmerbiomet.com",
    points: 315,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Nkululeko.jpg",
    birthdate: "1984-11-23T23:00:00.000Z",
  },
  {
    lastName: "Mbhedje",
    displayName: "Nkanyiso Mbhedje",
    firstName: "Nkanyiso",
    designation: "",
    user_id: 206,
    teammate_id: 830,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "nkanyiso.mbhedje@zimmerbiomet.com",
    points: 83,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Wanda.jpg",
    birthdate: "1990-03-10T23:00:00.000Z",
  },
  {
    lastName: "Da Silva",
    displayName: "Nicholas Da Silva",
    firstName: "Nicholas",
    designation: "Sales Executive",
    user_id: 207,
    teammate_id: 829,
    region: "cape-town",
    department: "sales-cape-town",
    role: "employee",
    email: "nicholas.DaSilva@zimmerbiomet.com",
    points: 289,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Nic-Da-Silva.jpg",
    birthdate: "1983-07-13T22:00:00.000Z",
  },
  {
    lastName: "Lourens",
    displayName: "Neil Lourens",
    firstName: "Neil",
    designation: "Theatre Technician",
    user_id: 208,
    teammate_id: 828,
    region: "free-state",
    department: "sales-free-state",
    role: "employee",
    email: "neil.lourens@zimmerbiomet.com",
    points: 50,
    birthdate: "1981-05-29T22:00:00.000Z",
  },
  {
    lastName: "Louw",
    displayName: "Barend Louw",
    firstName: "Barend",
    designation: "Driver",
    user_id: 209,
    teammate_id: 804,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "barend.louw@zimmerbiomet.com",
    points: 247,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Barend.jpg",
    birthdate: "1981-06-30T22:00:00.000Z",
  },
  {
    lastName: "Oosthuyzen",
    displayName: "Larry Oosthuyzen",
    firstName: "Larry",
    designation: "Sales Executive",
    user_id: 212,
    teammate_id: 821,
    region: "kwazulu-natal",
    department: "sales-kwazulu-natal",
    role: "employee",
    email: "larry.oosthuyzen@zimmerbiomet.com",
    points: 338,
    birthdate: "1987-09-30T23:00:00.000Z",
  },
  {
    lastName: "Phiri",
    displayName: "Billy Phiri",
    firstName: "Billy",
    designation: "Rotating Kit Coordinator",
    user_id: 213,
    teammate_id: 805,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "billy.phiri@zimmerbiomet.com",
    points: 867,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Billy.jpg",
    birthdate: "1963-03-20T23:00:00.000Z",
  },
  {
    lastName: "Haskins",
    displayName: "John Haskins",
    firstName: "John",
    designation: "Sales Executive",
    user_id: 215,
    teammate_id: 818,
    region: "kwazulu-natal",
    department: "sales-kwazulu-natal",
    role: "employee",
    email: "john.haskins@zimmerbiomet.com",
    points: 50,
    birthdate: "1985-10-12T23:00:00.000Z",
  },
  {
    lastName: "De Abreu",
    displayName: "Firmino De Abreu",
    firstName: "Firmino",
    designation: "Warehouse Manager",
    user_id: 217,
    teammate_id: 813,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Firmino.DeAbreu@zimmerbiomet.com",
    points: 1382,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Firmino.jpg",
    birthdate: "1975-09-14T23:00:00.000Z",
  },
  {
    lastName: "Morifi",
    displayName: "Ernest Morifi",
    firstName: "Ernest",
    designation: "Driver",
    user_id: 218,
    teammate_id: 812,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "ernest.morifi@zimmerbiomet.com",
    points: 200,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Ernest.jpg",
    birthdate: "1977-03-06T23:00:00.000Z",
  },
  {
    lastName: "Mompati",
    displayName: "Catherine Mompati",
    firstName: "Catherine",
    designation: "Rotating Kit Coordinator",
    user_id: 219,
    teammate_id: 806,
    region: "gauteng",
    department: "finance",
    role: "employee",
    email: "cathrinempolokeng.mompati@zimmerbiomet.com",
    points: 76,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/3-Catherine.jpg",
    birthdate: "1979-03-19T23:00:00.000Z",
  },
  {
    lastName: "Jeftha",
    displayName: "Diego Jeftha",
    firstName: "Diego",
    designation: "Rotating Kit Coordinator",
    user_id: 221,
    teammate_id: 817,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "diego.jeftha@zimmerbiomet.com",
    points: 485,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Diego.jpg",
    birthdate: "1993-06-08T22:00:00.000Z",
  },
  {
    lastName: "Govender",
    displayName: "Devashen Govender",
    firstName: "Devashen",
    designation: "Driver",
    user_id: 222,
    teammate_id: 810,
    region: "kwazulu-natal",
    department: "operations-kwazulu-natal",
    role: "employee",
    email: "devashen.govender@zimmerbiomet.com",
    points: 2027,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Devashen-1.jpg",
    birthdate: "1989-07-08T22:00:00.000Z",
  },
  {
    lastName: "Monyeki",
    displayName: "Derrick Monyeki",
    firstName: "Derrick",
    designation: "Rotating Kit Coordinator",
    user_id: 223,
    teammate_id: 809,
    region: "free-state",
    department: "operations-free-state",
    role: "employee",
    email: "derrick.monyeki@zimmerbiomet.com",
    points: 119,
    profileImageUrl:
      "http://localhost:8888/portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Derrick-Monyeki.jpg",
    birthdate: "1982-07-12T22:00:00.000Z",
  },
  {
    lastName: "Zithwana",
    displayName: "Christopher Zithwana",
    firstName: "Christopher",
    designation: "Theater Technician",
    user_id: 225,
    teammate_id: 808,
    region: "gauteng",
    department: "sales-gauteng",
    role: "employee",
    email: "christopherpetros.zithwana@zimmerbiomet.com",
    points: 933,
    profileImageUrl:
      "http://localhost:8888/portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Christopher-Zhitwana.jpg",
    birthdate: "1986-05-05T22:00:00.000Z",
  },
  {
    lastName: "Ndhlovu",
    displayName: "John Ndhlovu",
    firstName: "John",
    designation: "Rotating Kit Coordinator",
    user_id: 226,
    teammate_id: 819,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "john.ndhlovu@zimmerbiomet.com",
    points: 9,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/John.jpg",
    birthdate: "1980-12-17T23:00:00.000Z",
  },
  {
    lastName: "Mosikidi",
    displayName: "Refiloe Mosikidi",
    firstName: "Refiloe",
    designation: "Loan Set Planner",
    user_id: 227,
    teammate_id: 836,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "refiloe.mosikidi@zimmerbiomet.com",
    points: 1,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Refiloe.jpg",
    birthdate: "1988-04-18T22:00:00.000Z",
  },
  {
    lastName: "Mohasa",
    displayName: "Agnes Mohasa",
    firstName: "Agnes",
    designation: "Inbound Team",
    user_id: 228,
    teammate_id: 568,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Agnes.Mohasa@zimmerbiomet.com",
    points: 950,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2017/06/Agnes.jpg",
    birthdate: "1989-10-16T23:00:00.000Z",
  },
  {
    lastName: "Pillay",
    displayName: "Alston Pillay",
    firstName: "Alston",
    designation: "Driver",
    user_id: 229,
    teammate_id: 1009,
    region: "kwazulu-natal",
    department: "operations-kwazulu-natal",
    role: "employee",
    email: "Alston.Pillay@zimmerbiomet.com",
    points: 2033,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/alston.jpg",
    birthdate: "1992-07-06T22:00:00.000Z",
  },
  {
    lastName: "Nenzhelele",
    displayName: "Calvin Nenzhelele",
    firstName: "Calvin",
    designation: "Washer",
    user_id: 230,
    teammate_id: 1012,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "Calvin.Nenzhelele@zimmerbiomet.com",
    points: 110,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Calvin.jpg",
    birthdate: "1977-08-27T22:00:00.000Z",
  },
  {
    lastName: "Olivier",
    displayName: "Frank Olivier",
    firstName: "Frank",
    designation: "Driver",
    user_id: 231,
    teammate_id: 814,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "frank.olivier@zimmerbiomet.com",
    points: 244,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Frank.jpg",
    birthdate: "1973-05-24T23:00:00.000Z",
  },
  {
    lastName: "Matoba",
    displayName: "Innocent Matoba",
    firstName: "Innocent",
    designation: "Loan Set Planner",
    user_id: 232,
    teammate_id: 1013,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "innocent.matoba@zimmerbiomet.com",
    points: 510,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Innocent.jpg",
    birthdate: "1992-08-22T22:00:00.000Z",
  },
  {
    lastName: "Mkhasibe",
    displayName: "Jabulane Mkhasibe",
    firstName: "Jabulane",
    designation: "Sales Executive",
    user_id: 233,
    teammate_id: 1000,
    region: "sub-saharan-africa",
    department: "sales-sub-saharan-africa",
    role: "employee",
    email: "jabulane.mkhasibe@zimmerbiomet.com",
    points: 437,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Jabulane.jpg",
    birthdate: "1986-03-11T23:00:00.000Z",
  },
  {
    lastName: "Khumalo",
    displayName: "Lenard Khumalo",
    firstName: "Lenard",
    designation: "Instrument Supervisor",
    user_id: 234,
    teammate_id: 1001,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "lenard.khumalo@zimmerbiomet.com",
    points: 470,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Lenard.jpg",
    birthdate: "1974-04-26T23:00:00.000Z",
  },
  {
    lastName: "Killian",
    displayName: "Johan Killian",
    firstName: "Johan",
    designation: "Sales Executive",
    user_id: 236,
    teammate_id: 996,
    region: "gauteng",
    department: "sales-spine",
    role: "employee",
    email: "johan.killian@zimmerbiomet.com",
    points: 100,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Johan.jpg",
    birthdate: "1972-09-11T23:00:00.000Z",
  },
  {
    lastName: "Masuku",
    displayName: "Ndondana Masuku",
    firstName: "Ndondana",
    designation: "Rotating kit Coordinator",
    user_id: 238,
    teammate_id: 1010,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "ndondana.masuku@zimmerbiomet.com",
    points: 220,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Ndondana.jpg",
    birthdate: "1975-09-22T23:00:00.000Z",
  },
  {
    lastName: "Colic",
    displayName: "Stephan Colic",
    firstName: "Stephan",
    designation: "Sales Executive",
    user_id: 240,
    teammate_id: 166,
    region: null,
    department: "sales-trauma-agents",
    role: "employee",
    email: "colic_stephan@hotmail.com",
    points: 25,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Stefan-Colic-Sales-Executive.jpg",
  },
  {
    lastName: "Radojevic",
    displayName: "Zoran Radojevic",
    firstName: "Zoran",
    designation: "Trauma Manager",
    user_id: 242,
    teammate_id: 168,
    region: null,
    department: "sales-trauma-agents",
    role: "employee",
    email: "zoran.radojevic@zimmerbiomet.com",
    points: 150,
    profileImageUrl:
      "http://portal.zimmer-biomet.co.za/wp-content/uploads/2015/08/Zoran-Radojevic-Trauma-Manager.jpg",
  },
  {
    lastName: "Lalchund",
    displayName: "Jayshree Lalchund",
    firstName: "Jayshree",
    designation: "QA & RA Manager",
    user_id: 244,
    teammate_id: 1062,
    region: null,
    department: "head-office",
    role: "employee",
    email: "Jayshree.lalchund@zimmerbiomet.com",
    points: 1610,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/04/Jayshree-1.png",
    birthdate: "1978-09-27T22:00:00.000Z",
  },
  {
    lastName: "Sebati",
    displayName: "Keabetswe Sebati",
    firstName: "Keabetswe",
    designation: "Logistics Planning Team",
    user_id: 245,
    teammate_id: 820,
    region: "gauteng",
    department: "operations-gauteng",
    role: "employee",
    email: "keabetswe.sebati@zimmerbiomet.com",
    points: 1949,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Keabetswe.jpg",
    birthdate: "1992-04-10T22:00:00.000Z",
  },
  {
    lastName: "Johnston",
    displayName: "Nathan Johnston",
    firstName: "Nathan",
    designation: "Sales Executive",
    user_id: 246,
    teammate_id: 827,
    region: "cape-town",
    department: "sales-cape-town",
    role: "employee",
    email: "nathandale.johnston@zimmerbiomet.com",
    points: 62,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/03/Nathan-Johnston.jpg",
    birthdate: "1989-04-20T22:00:00.000Z",
  },
  {
    lastName: "Heyns",
    displayName: "Philip Heyns",
    firstName: "Philip",
    designation: "Strategic Account Manager",
    user_id: 255,
    teammate_id: 2154,
    region: "gauteng",
    department: "head-office",
    role: "employee",
    email: "philip.heyns1@zimmerbiomet.com",
    points: 172,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2020/02/IMG_1543.jpg",
  },
  {
    lastName: "Kotze",
    displayName: "Natasha Kotze",
    firstName: "Natasha",
    designation: "HR Manager",
    user_id: 257,
    teammate_id: 1677,
    region: null,
    department: "head-office",
    role: "employee",
    email: "Natasha.Kotze@zimmerbiomet.com",
    points: 561,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/09/PHOTO-2019-08-29-17-09-05.jpg",
  },
  {
    lastName: "Delport",
    displayName: "Pieter Delport",
    firstName: "Pieter",
    designation: "Warehouse manager",
    user_id: 258,
    teammate_id: 1700,
    region: "free-state",
    department: "operations-free-state",
    role: "employee",
    email: "pieter.delport@zimmerbiomet.com",
    points: 299,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/09/1.jpg",
  },
  {
    lastName: "Gavin",
    displayName: "Maylene Gavin",
    firstName: "Maylene",
    designation: "Finance Clerk",
    user_id: 259,
    teammate_id: 1704,
    region: "gauteng",
    department: "finance",
    role: "employee",
    email: "Gavinmaylene@gmail.com",
    points: 91,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/09/2.jpg",
  },
  {
    lastName: "Brebner",
    displayName: "Graeme Brebner",
    firstName: "Graeme",
    designation: "Sales",
    user_id: 261,
    teammate_id: 1819,
    region: "kwazulu-natal",
    department: "sales-kwazulu-natal",
    role: "employee",
    email: "Graeme.Brebner@zimmerbiomet.com",
    points: 115,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2019/10/132.png",
  },
  {
    lastName: "Oelofse",
    displayName: "Ancke Oelofse",
    firstName: "Ancke",
    designation: "Scrub Technician",
    user_id: 264,
    teammate_id: 2112,
    region: "free-state",
    department: "sales-free-state",
    role: "employee",
    email: "Ancke.Oelofse@zimmerbiomet.com",
    points: 25,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2020/02/Ancke-Oelofse.jpeg",
  },
  {
    lastName: "Phatlane",
    displayName: "Elizabeth Phatlane",
    firstName: "Elizabeth",
    designation: "Admin Assistant",
    user_id: 265,
    teammate_id: 2114,
    region: "gauteng",
    department: "head-office",
    role: "employee",
    email: "Elizabeth.Phatlane@zimmerbiomet.com",
    points: 1747,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2020/02/Elizabeth-Phatlane.png",
  },
  {
    lastName: "Serfontein",
    displayName: "Willem Serfontein",
    firstName: "Willem",
    designation: "Sales Representative",
    user_id: 266,
    teammate_id: 2118,
    region: "free-state",
    department: "sales-free-state",
    role: "employee",
    email: "Willem.Serfontein@zimmerbiomet.com",
    points: 25,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2020/02/2020-02-12-11.04.47.jpg",
  },
  {
    lastName: "Mantsi",
    displayName: "Synthia Mantsi",
    firstName: "Synthia",
    designation: "Regulatory Affairs Associate",
    user_id: 268,
    teammate_id: 2161,
    region: "gauteng",
    department: "head-office",
    role: "employee",
    email: "Synthia.Mantsi@bsci.com",
    points: 26,
    profileImageUrl:
      "https://portal.zimmer-biomet.co.za/wp-content/uploads/2020/02/Photo.jpg",
  },
];

export const usersNoBirthday = [
  {
    displayName: "Jonas @zimmerbiomet.com",
    firstName: "Jonas",
    lastName: "@zimmerbiomet.com",
  },
  {
    displayName: "Rargwase Refiloe",
    firstName: "Rargwase",
    lastName: "Refiloe",
  },
  {
    displayName: "Stephan Colic",
    firstName: "Stephan",
    lastName: "Colic",
  },
  {
    displayName: "Zoran Radojevic",
    firstName: "Zoran",
    lastName: "Radojevic",
  },
  {
    displayName: "Philip Heyns",
    firstName: "Philip",
    lastName: "Heyns",
  },
  {
    displayName: "Natasha Kotze",
    firstName: "Natasha",
    lastName: "Kotze",
  },
  {
    displayName: "Pieter Delport",
    firstName: "Pieter",
    lastName: "Delport",
  },
  {
    displayName: "Maylene Gavin",
    firstName: "Maylene",
    lastName: "Gavin",
  },
  {
    displayName: "Graeme Brebner",
    firstName: "Graeme",
    lastName: "Brebner",
  },
  {
    displayName: "Ancke Oelofse",
    firstName: "Ancke",
    lastName: "Oelofse",
  },
  {
    displayName: "Elizabeth Phatlane",
    firstName: "Elizabeth",
    lastName: "Phatlane",
  },
  {
    displayName: "Willem Serfontein",
    firstName: "Willem",
    lastName: "Serfontein",
  },
  {
    displayName: "Synthia Mantsi",
    firstName: "Synthia",
    lastName: "Mantsi",
  },
];
