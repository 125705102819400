import React, { useState } from "react";
import UserForm from "./UserForm";
import { useFirebase } from "../../../app/context/Firebase";
import { useToasts } from "react-toast-notifications";

export default function EditUser({ user, goBack }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const firebase = useFirebase();
  const { addToast } = useToasts();

  const handleSubmit = async (e, formValues, reset) => {
    setLoading(true);
    e.preventDefault();
    formValues.displayName = `${formValues.firstName} ${formValues.lastName}`;
    delete formValues.formValid;

    return firebase
      .user(user.id)
      .update({
        ...formValues,
      })

      .then(() => {
        setLoading(false);
        addToast("Edited Successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
        });
        goBack();
      })
      .catch((error) => {
        setLoading(false);

        setError(error);
        console.log(error);
      });
  };

  return user ? (
    <UserForm
      loading={loading}
      error={error}
      handleSubmit={handleSubmit}
      submitText="Update User"
      initialValues={user}
      cancel={goBack}
    />
  ) : (
    <div>Loading</div>
  );
}
