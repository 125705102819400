import React, { useMemo } from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { useSliders } from "../../app/context/sliders";
import SliderItem from "../AdminArea/DashboardSlider/SliderItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Ring } from "react-awesome-spinners";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";
// import { useUsers } from "../../app/context/users";
// import { isBirthday } from "../../app/utils/";

//TODO: update var to const/let

export default function Slider() {
  const { sliders } = useSliders();
  // var { users } = useUsers();

  // var getBirthdaySlider = (sliders = []) =>
  //   sliders.find(({ id }) => id === "zatuDn9cuP07CNKkUtzJ");

  // var addNames = (names) => (slider) => ({
  //   ...slider,
  //   subtitle: {
  //     ...slider.subtitle,
  //     content: names.join(", ") + " !",
  //   },
  // });

  var filterSliders = (sliders) =>
    sliders
      .sort((a, b) => a.position - b.position)
      .filter((s) => s?.platform?.value === "HQ" || s.platform === undefined)
      .filter((s) => s.isPublished);

  const sliderList = useMemo(() => {
    // var bdays = [];

    // if (users.length) {
    //   bdays = users
    //     .filter((user) => isBirthday(user.birthdate))
    //     .map((user) => user.displayName);
    // }
    if (sliders) {
      var filtered = filterSliders(sliders);
      // TODO: release birthday message

      // var bdaySlider = getBirthdaySlider(sliders);
      // uncomment bellow to get birthday-features
      // if (bdays.length) return [addNames(bdays)(bdaySlider), ...filtered];
      return filtered;
    } else {
      return [];
    }
  }, [sliders /* users */]);

  var [init, setInit] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setInit(true), 500);
  }, []);

  if (!init)
    return (
      <div style={{ margin: "0 auto", width: "fit-content" }}>
        <Ring color={colours[primary].shade["500"]} />
      </div>
    );

  return sliderList.length > 0 ? (
    <div style={{ maxWidth: "1200px", margin: "auto" }}>
      <Carousel
        arrows
        infinite
        autoPlay={sliderList.length > 1 ? 6000 : 100000000000}
        animationSpeed={1000}
        slidesPerPage={1}
        centered
        arrowLeft={<FontAwesomeIcon icon={["fal", "angle-left"]} />}
        arrowRight={<FontAwesomeIcon icon={["fal", "angle-right"]} />}
        addArrowClickHandler
      >
        {sliderList.map((s, idx) => (
          <SliderItem slider={s} key={idx} />
        ))}
      </Carousel>
    </div>
  ) : null;
}
