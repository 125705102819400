export const formatNewSlider = (values, user) => {
  return {
    ...values,
    createdBy: { id: user.id, name: user.displayName, email: user.email },
    dateCreated: new Date(),
  };
};

export const formatSlider = (values, user) => {
  return {
    ...values,
    updatedBy: { id: user.id, name: user.displayName, email: user.email },
    dateUpdated: new Date(),
  };
};
