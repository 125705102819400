import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import ApprovalUsersTable from "./ApprovalUsersTable";
import ApprovalComplimentsList from "./ApprovalComplimentsList";
import DashStats from "./DashStats";
import UnreadMessagesList from "./UnreadMessagesList";
import { usePoints } from "../../../app/context/points";
import TodaysPointsTable from "./TodaysPointsTable";

export default function AdminDashboard() {
  const { getTodaysPointsLog } = usePoints();
  const [pointsLog, setPointsLog] = useState();

  useEffect(() => getTodaysPointsLog(setPointsLog), [getTodaysPointsLog]);
  return (
    <div>
      <PageHeaderContainer>Admin Dashboard</PageHeaderContainer>
      <DashStats pointsLog={pointsLog} />
      <Grid columns={process.env.REACT_APP_VIEW_POINTS === "true" ? 2 : 1}>
        <ApprovalUsersTable />
        {process.env.REACT_APP_TEAMMATES === "true" && (
          <ApprovalComplimentsList />
        )}
        {process.env.REACT_APP_MESSAGES === "true" && <UnreadMessagesList />}
        {process.env.REACT_APP_VIEW_POINTS === "true" && (
          <TodaysPointsTable pointsLog={pointsLog} />
        )}
      </Grid>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  column-gap: 1em;
  row-gap: 1em;

  @media (max-width: 1025px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
