import React from "react";
import { Card, Heading, AspectRatio } from "@theme-ui/components";
import { ImageBlock } from "../../app/DSL/components/elements/styled";
import styled from "@emotion/styled";
import { primary } from "../../app/DSL/theme";
import { NavLink } from "react-router-dom";

export default function TeammateCard({ user }) {
  return (
    <NavLink to={`/teammates/${user.id}`}>
      <Card style={{ textAlign: "center", height: "100%" }} variant="hoverable">
        <AspectRatio ratio={1 / 1}>
          <ImageBlock
            size="full"
            style={{ marginBottom: "1em" }}
            url={
              user && user.profileImageUrl
                ? user.profileImageUrl
                : "/user-circle-solid.svg"
            }
          >
            <div className="img"></div>
          </ImageBlock>
        </AspectRatio>

        <Heading as="h3">{user.displayName}</Heading>
        <SubHeading>{user.designation}</SubHeading>
      </Card>
    </NavLink>
  );
}

const SubHeading = styled(Heading)`
  color: ${(props) => props.theme.colors[primary].shade["500"]};
  font-weight: 400;
  font-size: 1em;
`;
