import React, { createContext, useContext, useState, useEffect } from "react";
import { transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext, useUserContext } from "../auth";
import { formatNewSlider, formatSlider } from "./formatting";

const SlidersContext = createContext({});

export const SlidersProvider = ({ children }) => {
  const firebase = useFirebase();
  const [sliders, setSliders] = useState();
  const auth = useAuthContext();
  const user = useUserContext();

  useEffect(() => {
    const unsubscribe = auth.user
      ? firebase
          .sliders()
          .onSnapshot((snap) => setSliders(transformCollectionSnap(snap)))
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, auth]);

  const createSlider = (values) => {
    return firebase.sliders().add(formatNewSlider(values, user));
  };

  const updateSlider = (values) => {
    return firebase.slider(values.id).update(formatSlider(values, user));
  };

  return (
    <SlidersContext.Provider value={{ sliders, createSlider, updateSlider }}>
      {children}
    </SlidersContext.Provider>
  );
};

export const SlidersConsumer = SlidersContext.Consumer;

export const useSliders = () => useContext(SlidersContext);
