import React, { useState, useCallback, useEffect } from "react";
import Table from "../../../app/DSL/components/elements/Table";
import { useUsers } from "../../../app/context/users";
import { useMemo } from "react";
import {
  UsersTableContainer,
  RoundButton,
} from "../../../app/DSL/components/elements/styled";
import { useFirebase } from "../../../app/context/Firebase";
import RoleLabel from "../../../app/DSL/components/elements/RoleLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import ConfirmationModal from "../../../app/DSL/components/elements/ConfirmationModal";
import ReactModal from "react-modal";
import { useToasts } from "react-toast-notifications";
import { departments } from "../../../app/context/data/departments";

import styled from "@emotion/styled";
import ImageUploadModal from "../../Profile/ImageUploadModal";
import { Label, Radio } from "@theme-ui/components";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import { useHistory } from "react-router-dom";
import colours from "../../../app/DSL/styles/colours";
import PointsModal from "./PointsModal";
import { formatPointString } from "../../../app/context/points/formatting";
import { distributors } from "../../../app/context/data/distributors";
import DownloadReport from "../../../app/DSL/components/elements/DownloadReport";
import { format } from "date-fns";
ReactModal.setAppElement("#root");

export default function UsersTable({ editUser }) {
  const [pointsModalOpen, setPointsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState();
  const [modalActionType, setModalActionType] = useState();
  const [confirmItem, setConfirmItem] = useState({});

  const [filter, setFilter] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { addToast } = useToasts();

  const firebase = useFirebase();
  const { users, stats } = useUsers();

  const history = useHistory();

  useEffect(() => {
    if (history.location.search) {
      const f = history.location.search.replace("?", "");
      setFilter(f);
    }
  }, [history]);

  const archiveUser = useCallback(
    () => (id) => {
      firebase.archiveUser(id);
      setModalOpen(false);
      addToast("User archived", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
    },
    [addToast, firebase]
  );

  const approveUser = useCallback(
    () => (e, formValues, id) => {
      setLoading(true);
      e.preventDefault();
      formValues.displayName = `${formValues.firstName} ${formValues.lastName}`;
      delete formValues.formValid;

      return firebase
        .user(id)
        .update({
          ...formValues,
          isApproved: true,
        })

        .then(() => {
          setLoading(false);
          setModalOpen(false);
          addToast("Edited Successfully", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 1500,
          });
        })
        .catch((error) => {
          setLoading(false);

          setError(error);
          console.log(error);
        });
    },
    [addToast, firebase]
  );

  const confirmApproval = useCallback(
    () => (id) => {
      setModalAction(approveUser);
      setModalActionType("details");
    },
    [approveUser]
  );

  const csvHeadings = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Last Login", key: "lastLogin" },
    { label: "Date of Birth", key: "birthdate" },
    { label: "Sortable DoB (mm/dd)", key: "sortableBirthdate" },
    { label: "Department", key: "department" },
    { label: "Designation", key: "designation" },
    { label: "Points", key: "points" },
    { label: "Regions", key: "regions" },
  ];

  const userList = useMemo(
    () =>
      users
        ? users
            .sort((a, b) => (a.displayName < b.displayName ? -1 : 1))
            .filter((u) => !u.isArchived)
            .filter((u) => (filter === "notApproved" ? !u.isApproved : true))
            .filter((u) => (filter === "firstLogin" ? u.firstLogin : true))
            .filter((u) =>
              filter === "online" ? stats?.todaysLogins?.includes(u.id) : true
            )
            .filter((u) =>
              filter === "notOnline"
                ? u.isApproved &&
                  !u.firstLogin &&
                  !stats?.todaysLogins?.includes(u.id)
                : true
            )
        : [],
    [users, filter, stats]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        id: "pending",
        accessor: (u) => u.firstLogin,
        Cell: (cell) => {
          return (
            <span>
              {!cell.row.original.isApproved ? (
                <FontAwesomeIcon
                  data-tip="User awaiting approval"
                  data-for="notActive"
                  icon={["fal", "exclamation-circle"]}
                  color="grey"
                />
              ) : cell.row.original.firstLogin ? (
                <FontAwesomeIcon
                  icon={["fal", "hourglass-half"]}
                  color="grey"
                  data-tip="User not yet active"
                  data-for="notActive"
                />
              ) : (
                <FontAwesomeIcon
                  size="xs"
                  icon={["fas", "circle"]}
                  color={
                    stats?.todaysLogins?.includes(cell.row.original.id)
                      ? colours.lightGreen.shade[500]
                      : colours.tusk.shade[400]
                  }
                  data-tip={
                    stats?.todaysLogins?.includes(cell.row.original.id)
                      ? "Online today"
                      : "Not online today"
                  }
                  data-for="notActive"
                />
              )}
              <ReactTooltip id="notActive" effect="solid" />
            </span>
          );
        },
        disableFilters: true,
      },
      {
        Header: "Name",
        id: "name",
        accessor: (u) => `${u.firstName} ${u.lastName}`,
        filter: "fuzzyText",
      },
      {
        Header: "Last Login",
        id: "lastLogin",
        accessor: (u) =>
          u.lastLogin ? format(u.lastLogin.toDate(), "d MMM YYY HH:mm") : "-",
        filter: "fuzzyText",
      },
      ...(process.env.REACT_APP_VIEW_POINTS === "true"
        ? [
            {
              Header: "Points",
              id: "points",
              accessor: (u) => u.points,
              Cell: (cell) => (
                <Points>
                  <p>
                    <FontAwesomeIcon icon={["far", "star"]} size="lg" />{" "}
                    {cell.row.original.points &&
                      formatPointString(cell.row.original.points)}{" "}
                  </p>
                </Points>
              ),
              filter: "fuzzyText",
            },
          ]
        : []),

      process.env.REACT_APP_USER_TYPE === "internal"
        ? {
            Header: "Department",
            id: "department",
            accessor: (u) => {
              const dep = departments.find((d) => d.value === u.department);
              return dep ? dep.label : "-";
            },
            filter: "fuzzyText",
          }
        : {
            Header: "Distributor",
            id: "distributor",
            accessor: (u) => {
              const dep = distributors.find((d) => d.value === u.distributor);
              return dep ? dep.label : "-";
            },
            filter: "fuzzyText",
          },
      {
        Header: "Designation",
        id: "designation",
        accessor: (u) => u.designation,
        filter: "fuzzyText",
      },

      {
        Header: "Role",
        id: "role",
        accessor: (u) => u.role,
        Cell: (cell) => {
          return cell.row.original.role ? (
            <RoleLabel role={cell.row.original.role} />
          ) : (
            "-"
          );
        },
        disableFilters: true,
      },

      {
        Header: "Actions",
        accessor: (u) => u.id,
        Cell: (cell) => {
          return (
            <span>
              {cell.row.original.isApproved ? (
                [
                  <RoundButton
                    key="archive"
                    data-tip="Archive user"
                    data-for="actionName"
                    variant="secondary"
                    onClick={() => {
                      setModalOpen(true);
                      setModalActionType("archive");
                      setModalAction(archiveUser);
                      setConfirmItem(cell.row.original);
                    }}
                  >
                    <FontAwesomeIcon icon={["fas", "trash"]} />
                  </RoundButton>,

                  <RoundButton
                    key="edit"
                    data-tip="View / Edit user"
                    data-for="actionName"
                    variant="secondary"
                    onClick={() => editUser(cell.row.original)}
                  >
                    <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
                  </RoundButton>,
                  <RoundButton
                    key="image"
                    data-tip={`${
                      cell.row.original.profileImageUrl ? "Update" : "Upload"
                    } Image`}
                    data-for="actionName"
                    variant="secondary"
                    onClick={() => {
                      setImgModalOpen(true);
                      setConfirmItem(cell.row.original);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={[
                        `${cell.row.original.profileImageUrl ? "fas" : "far"}`,
                        "portrait",
                      ]}
                    />
                  </RoundButton>,
                  process.env.REACT_APP_VIEW_POINTS === "true" && (
                    <RoundButton
                      key="points"
                      data-tip="Update points"
                      data-for="actionName"
                      variant="secondary"
                      onClick={() => {
                        setPointsModalOpen(true);
                        setConfirmItem(cell.row.original);
                      }}
                    >
                      <FontAwesomeIcon icon={["far", "star"]} />
                    </RoundButton>
                  ),
                ]
              ) : (
                <RoundButton
                  key="approve"
                  data-tip="Approve user"
                  data-for="actionName"
                  variant="secondary"
                  onClick={() => {
                    setModalOpen(true);
                    setModalActionType("approve");
                    setModalAction(confirmApproval);
                    setConfirmItem(cell.row.original);
                  }}
                >
                  <FontAwesomeIcon icon={["fas", "check"]} />
                </RoundButton>
              )}

              <ReactTooltip id="actionName" effect="solid" />
            </span>
          );
        },
        className: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [editUser, archiveUser, confirmApproval, stats]
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: "",
        id: "pending",
        accessor: (u) => u.firstLogin,
        Cell: (cell) => {
          return (
            <span>
              {!cell.row.original.isApproved ? (
                <FontAwesomeIcon
                  data-tip="User awaiting approval"
                  data-for="notActive"
                  icon={["fal", "exclamation-circle"]}
                  color="grey"
                />
              ) : cell.row.original.firstLogin ? (
                <FontAwesomeIcon
                  icon={["fal", "hourglass-half"]}
                  color="grey"
                  data-tip="User not yet active"
                  data-for="notActive"
                />
              ) : (
                <FontAwesomeIcon
                  size="xs"
                  icon={["fas", "circle"]}
                  color={
                    stats?.todaysLogins?.includes(cell.row.original.id)
                      ? colours.lightGreen.shade[500]
                      : colours.tusk.shade[400]
                  }
                  data-tip={
                    stats?.todaysLogins?.includes(cell.row.original.id)
                      ? "Online today"
                      : "Not online today"
                  }
                  data-for="notActive"
                />
              )}
              <ReactTooltip id="notActive" effect="solid" />
            </span>
          );
        },
        disableFilters: true,
      },
      {
        Header: "Name",
        id: "name",
        accessor: (u) => `${u.firstName} ${u.lastName}`,
        filter: "fuzzyText",
      },
      ...(process.env.REACT_APP_VIEW_POINTS === "true"
        ? [
            {
              Header: "Points",
              id: "points",
              accessor: (u) => u.points,
              Cell: (cell) => (
                <Points>
                  <p>
                    {cell.row.original.points &&
                      formatPointString(cell.row.original.points)}{" "}
                  </p>
                </Points>
              ),
              filter: "fuzzyText",
            },
          ]
        : []),
      {
        Header: "Actions",
        accessor: (u) => u.id,
        Cell: (cell) => {
          return (
            <span>
              {cell.row.original.isApproved ? (
                [
                  <RoundButton
                    key="archive"
                    data-tip="Archive user"
                    data-for="actionName"
                    variant="secondary"
                    onClick={() => {
                      setModalOpen(true);
                      setModalActionType("archive");
                      setModalAction(archiveUser);
                      setConfirmItem(cell.row.original);
                    }}
                  >
                    <FontAwesomeIcon icon={["fas", "trash"]} />
                  </RoundButton>,

                  <RoundButton
                    key="edit"
                    data-tip="Edit user"
                    data-for="actionName"
                    variant="secondary"
                    onClick={() => editUser(cell.row.original)}
                  >
                    <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
                  </RoundButton>,
                  <RoundButton
                    key="image"
                    data-tip={`${
                      cell.row.original.profileImageUrl ? "Update" : "Upload"
                    } Image`}
                    data-for="actionName"
                    variant="secondary"
                    onClick={() => {
                      setImgModalOpen(true);
                      setConfirmItem(cell.row.original);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={[
                        `${cell.row.original.profileImageUrl ? "fas" : "far"}`,
                        "portrait",
                      ]}
                    />
                  </RoundButton>,
                  process.env.REACT_APP_VIEW_POINTS === "true" && (
                    <RoundButton
                      key="points"
                      data-tip="Update points"
                      data-for="actionName"
                      variant="secondary"
                      onClick={() => {
                        setPointsModalOpen(true);
                        setConfirmItem(cell.row.original);
                      }}
                    >
                      <FontAwesomeIcon icon={["far", "star"]} />
                    </RoundButton>
                  ),
                ]
              ) : (
                <RoundButton
                  key="approve"
                  data-tip="Approve user"
                  data-for="actionName"
                  variant="secondary"
                  onClick={() => {
                    setModalOpen(true);
                    setModalActionType("approve");
                    setModalAction(confirmApproval);
                    setConfirmItem(cell.row.original);
                  }}
                >
                  <FontAwesomeIcon icon={["fas", "check"]} />
                </RoundButton>
              )}

              <ReactTooltip id="actionName" effect="solid" />
            </span>
          );
        },
        className: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [confirmApproval, archiveUser, editUser, stats]
  );

  return (
    <UserView>
      {/* TODO: Move filters to component */}
      <Choices>
        <InputWrap style={{ marginRight: "10px" }}>
          <Label variant="radioLabel" style={{ textTransform: "none" }}>
            <Radio
              name="filter"
              value=""
              checked={filter === ""}
              onChange={(e) => {
                setFilter("");
              }}
            />
            All
          </Label>
        </InputWrap>
        <InputWrap style={{ marginRight: "10px" }}>
          <Label variant="radioLabel" style={{ textTransform: "none" }}>
            <Radio
              name="filter"
              value="notApproved"
              checked={filter === "notApproved"}
              onChange={(e) => {
                setFilter("notApproved");
              }}
            />
            Awaiting approval
          </Label>
        </InputWrap>
        <InputWrap>
          <Label variant="radioLabel" style={{ textTransform: "none" }}>
            <Radio
              name="filter"
              value="firstLogin"
              checked={filter === "firstLogin"}
              onChange={(e) => {
                setFilter("firstLogin");
              }}
            />
            Never active
          </Label>
        </InputWrap>
        <InputWrap>
          <Label variant="radioLabel" style={{ textTransform: "none" }}>
            <Radio
              name="filter"
              value="online"
              checked={filter === "online"}
              onChange={(e) => {
                setFilter("online");
              }}
            />
            Online today
          </Label>
        </InputWrap>
        <InputWrap>
          <Label variant="radioLabel" style={{ textTransform: "none" }}>
            <Radio
              name="filter"
              value="notOnline"
              checked={filter === "notOnline"}
              onChange={(e) => {
                setFilter("notOnline");
              }}
            />
            Not online today
          </Label>
        </InputWrap>
      </Choices>
      <div className="not-mobile">
        <UsersTableContainer
          numActions={process.env.REACT_APP_VIEW_POINTS === "true" ? 4 : 3}
        >
          <Table
            emptyMsg="No users"
            columns={columns}
            data={userList}
            defaultRows={5}
            emptyIcon="users"
            loading={users === undefined}
          />
        </UsersTableContainer>
      </div>
      <div className="mobile">
        <UsersTableContainer
          numActions={process.env.REACT_APP_VIEW_POINTS === "true" ? 2 : 3}
        >
          <Table
            className="mobile"
            emptyMsg="No users"
            columns={mobileColumns}
            data={userList}
            defaultRows={5}
            emptyIcon="users"
            loading={users === undefined}
          />
        </UsersTableContainer>
      </div>

      <ConfirmationModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        name={confirmItem.displayName}
        id={confirmItem.id}
        action={modalAction}
        actionType={modalActionType}
        item={confirmItem}
        type="user"
        loading={loading}
        error={error}
      />
      <ImageUploadModal
        user={confirmItem}
        isOpen={imgModalOpen}
        setOpen={setImgModalOpen}
      />
      <PointsModal
        user={confirmItem}
        isOpen={pointsModalOpen}
        setOpen={setPointsModalOpen}
      />
      <DownloadReport
        data={userList.map((u) =>
          u.birthdate
            ? {
                ...u,
                birthdate: format(u.birthdate.toDate(), "dd/MM/YYY"),
                sortableBirthdate: format(u.birthdate.toDate(), "MM/dd"),
                lastLogin: u.lastLogin
                  ? format(u.lastLogin.toDate(), "dd/MM/YYY HH:mm")
                  : "-",
              }
            : u
        )}
        headers={csvHeadings}
        // loading={!reportsLoaded}
        className="download-btn"
        fileName="users"
      />
    </UserView>
  );
}

const UserView = styled.div`
  .mobile {
    display: none !important;
  }

  @media (max-width: 1024px) {
    .mobile {
      display: block !important;
    }
    .not-mobile {
      display: none !important;
    }
  }
  text-align: center;
`;

const Choices = styled.div`
  display: flex;
  align-content: center;
  @media (max-width: 576px) {
    display: block;
  }
`;

const Points = styled.div`
  svg {
    color: ${(props) => props.theme.colors.orange.shade["500"]};
    margin-right: 5px;
  }
  p {
    font-size: 1em;
  }
`;
