import * as firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import config from "./config";

// Initialize Firebase
firebase.initializeApp(config);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const firestore = firebase.firestore;

const Firebase = {
  /////////////////////// AUTH ///////////////////////////////
  auth,
  storage,

  loginWithEmail: (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  },
  signupWithEmail: (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  },
  signOut: () => {
    return auth.signOut();
  },
  checkUserAuth: (user) => {
    return auth.onAuthStateChanged(user);
  },

  sendSignInMail: (email, actionCodeSettings) => {
    return auth.sendSignInLinkToEmail(email, actionCodeSettings);
  },

  loginInWithLink: (email, link) => {
    return auth.signInWithEmailLink(email, link);
  },
  updatePassword: (password) => {
    return auth.currentUser.updatePassword(password);
  },

  sendForgotPasswordEmail: (email) => {
    return auth.sendPasswordResetEmail(email);
  },

  /////////////////////// FIRESTORE ///////////////////////////////
  db,
  firestore,
  //----------------- USERS -----------------

  createNewUser: (userData) => {
    return db.collection("users").doc().set(userData);
  },

  createUserWithId: (userData) => {
    return db.collection("users").doc(userData.id).set(userData);
  },

  user: (userId) => {
    return db.collection("users").doc(userId);
  },

  users: (userId) => {
    return db.collection("users");
  },

  findUserByEmail: (email) => {
    return db.collection("users").where("email", "==", email);
  },
  archiveUser: (userId) => {
    return db.collection("users").doc(userId).update({ isArchived: true });
  },

  approveUser: (userId) => {
    return db.collection("users").doc(userId).update({ isApproved: true });
  },

  //----------------- HOSPITALS -----------------

  hospitals: () => {
    return db.collection("hospitals");
  },

  hospital: (hospitalId) => {
    return db.collection("hospitals").doc(hospitalId);
  },
  archiveHospital: (hospitalId) => {
    return db
      .collection("hospitals")
      .doc(hospitalId)
      .update({ isArchived: true });
  },

  //----------------- DOCTORS -----------------

  doctors: () => {
    return db.collection("doctors");
  },

  doctor: (docId) => {
    return db.collection("doctors").doc(docId);
  },
  archiveDoctor: (docId) => {
    return db.collection("doctors").doc(docId).update({ isArchived: true });
  },

  //----------------- COMPLIMENTS -----------------

  compliments: () => {
    return db.collection("compliments");
  },

  compliment: (complimentId) => {
    return db.collection("compliments").doc(complimentId);
  },

  //----------------- SUGGESTIONS -----------------

  messages: () => {
    return db.collection("messages");
  },

  message: (messageId) => {
    return db.collection("messages").doc(messageId);
  },

  //----------------- Newsflash -----------------

  newsflashes: (id) =>
    id ? db.doc("newsflashes/" + id) : db.collection("newsflashes"),
  //----------------- SLIDERS -----------------

  sliders: () => {
    return db.collection("dashboardSliders");
  },

  slider: (id) => {
    return db.collection("dashboardSliders").doc(id);
  },

  //----------------- SETTINGS -----------------

  setting: (name) => {
    return db.collection("settings").doc(name);
  },

  //------------------POINTS LOG --------------

  pointsLog: () => {
    return db.collection("pointsLog");
  },

  //----------------- SCREENS -----------------

  screen: (name) => {
    return db.collection("screens").doc(name);
  },

  // warehouse: (name, area) => {
  //   return db.collection("screens");
  // },

  warehouse: () => {
    return db.collection("screens").doc("warehouse");
  },

  // deleteMonth: (month, area) => {
  //   console.log(month);
  //   console.log(area);
  //   return db
  //     .collection("screens")
  //     .doc("warehouse")
  //     .update({
  //       area: firebase.firestore.FieldValue.arrayRemove({ month }),
  //     });
  // },
};

export default Firebase;
