import React, { useState } from "react";
import ReactModal from "react-modal";
import { Button, Card, Heading } from "@theme-ui/components";
import { Flex } from "theme-ui";
import ImageUpload from "../../app/DSL/components/elements/ImageUpload";
import { useToasts } from "react-toast-notifications";
import { useFirebase } from "../../app/context/Firebase";

ReactModal.setAppElement("#root");

export default function ImageUploadModal({ isOpen, setOpen, user }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const firebase = useFirebase();
  const { addToast } = useToasts();
  const cropper = React.createRef();

  const uploadFile = () => {
    const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    setLoading(true);

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully

        const response = JSON.parse(xhr.responseText);
        // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg

        const url = response.secure_url;

        firebase
          .user(user.id)
          .update({
            profileImageUrl: url,
          })
          .then(() => {
            addToast("Image Added Successfully", {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 1500,
            });
            setLoading(false);
            setOpen(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
        //addImage(url);
      }
    };

    fd.append("upload_preset", process.env.REACT_APP_PRESET);
    fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
    fd.append(
      "file",
      cropper?.current?.cropper
        ?.getCroppedCanvas({ width: 400, height: 400 })
        .toDataURL()
    );
    xhr.send(fd);
  };

  return (
    <ReactModal isOpen={isOpen} overlayClassName="overlay" className="content">
      <Card>
        <Heading>Update Profile Picture</Heading>

        <p style={{ margin: "2em 0" }}></p>
        <ImageUpload
          image={user?.profileImageUrl}
          setFile={setFile}
          file={file}
          cropper={cropper}
          centered
        />

        <Flex sx={{ justifyContent: "space-between" }}>
          <Button variant="outline" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          {loading ? (
            <Button>Loading</Button>
          ) : (
            <Button
              onClick={() => {
                uploadFile();
              }}
            >
              Update
            </Button>
          )}
        </Flex>
      </Card>
    </ReactModal>
  );
}
