import React, { useState } from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Image, Button } from "@theme-ui/components";
import styled from "@emotion/styled";
import { platforms } from "../../../app/context/data/platforms";
import PermissionsModal from "./PermissionsModal";

export default function Platforms() {
  const [modalItem, setModalItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <PageHeaderContainer>Manage Platforms</PageHeaderContainer>
      <Card>
        <Grid>
          {platforms
            .filter((p) => p.active)
            .map((p, idx) => (
              <PlatformCard colour={p.colour} key={idx}>
                <Image src={`/assets/${p.name}.svg`} />
                <div>
                  <h2>{p.title}</h2>
                  <div>
                    <Button
                      onClick={() => {
                        setModalOpen(true);
                        setModalItem(p);
                      }}
                      variant={!p.active ? "disabled" : "outlineWhite"}
                    >
                      Manage permissions
                    </Button>
                  </div>
                  <div>
                    <a href={p.link}>
                      <Button variant={!p.active ? "disabled" : "outlineWhite"}>
                        Visit Platform
                      </Button>
                    </a>
                  </div>
                </div>
              </PlatformCard>
            ))}
        </Grid>
        {platforms.filter((p) => !p.active).length > 0 && <h3>Coming soon</h3>}
        <Grid>
          {platforms
            .filter((p) => !p.active)
            .map((p, idx) => (
              <PlatformCard colour={p.colour} key={idx} disabled>
                <Image src={`/assets/${p.name}.svg`} />
                <div>
                  <h2>{p.title}</h2>
                </div>
              </PlatformCard>
            ))}
        </Grid>
      </Card>

      <PermissionsModal
        open={modalOpen}
        close={() => {
          setModalOpen(false);
          setModalItem(null);
        }}
        platform={modalItem}
      />
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const PlatformCard = styled(Card)`
  filter: ${(props) => (props.disabled ? "grayscale(90%)" : "none")};
  border-radius: 10px;
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 2em;
  background: ${(props) =>
    props.theme.colors[props.colour ? props.colour : "tusk"].shade[
      props.code ? props.code : "500"
    ]};
  img {
    filter: drop-shadow(2px 2px 5px rgba(255, 255, 255, 0.2));
  }
  color: white;
  button {
    margin-bottom: 1em;
  }

  @media (max-width: 578px) {
    grid-template-columns: 1fr;
  }
`;
