import React, { useMemo, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Card } from "@theme-ui/components";
import { useUsers } from "../../../app/context/users";
import { useCompliments } from "../../../app/context/compliments";
import { isToday } from "date-fns";
import { usePoints } from "../../../app/context/points";
import { useMessages } from "../../../app/context/messages";
import { NavLink } from "react-router-dom";

export default function DashStats({ pointsLog }) {
  const { users, stats } = useUsers();
  const { getTodaysCompliments } = useCompliments();

  const [compliments, setCompliments] = useState();

  useEffect(() => getTodaysCompliments(setCompliments), [getTodaysCompliments]);
  const { messages } = useMessages();
  const { monthsOrders } = usePoints();
  const todaysPoints = useMemo(
    () =>
      pointsLog ? pointsLog.reduce((total, p) => (total += p.numPoints), 0) : 0,
    [pointsLog]
  );
  const monthsPointsSpent = useMemo(
    () =>
      monthsOrders
        ? monthsOrders.reduce((total, p) => (total += p.totalPrice), 0)
        : 0,
    [monthsOrders]
  );

  //TODO: move filtering to memos
  return (
    <Stats
      columns={
        process.env.REACT_APP_MESSAGES === "true" &&
        process.env.REACT_APP_VIEW_POINTS === "true"
          ? 4
          : 3
      }
    >
      <NavLink to="/admin-area/users?notApproved">
        <Card variant="small" style={{ height: "100%" }}>
          <h1>
            {users
              ? users.filter((u) => !u.isArchived).filter((u) => !u.isApproved)
                  .length
              : 0}
          </h1>
          <h3>Users awaiting approval</h3>
        </Card>
      </NavLink>
      <NavLink to="/admin-area/users?firstLogin">
        <Card variant="small" style={{ height: "100%" }}>
          <h1>
            {users
              ? users.filter((u) => !u.isArchived).filter((u) => u.firstLogin)
                  .length
              : 0}
          </h1>
          <h3>Inactive users</h3>
        </Card>
      </NavLink>
      <NavLink to="/admin-area/users?online">
        <Card variant="small" style={{ height: "100%" }}>
          <h1>{stats.todaysLogins ? stats.todaysLogins.length : 0}</h1>
          <h3>Unique users today</h3>
        </Card>
      </NavLink>
      {process.env.REACT_APP_VIEW_POINTS === "true" && (
        <NavLink to="/admin-area/points">
          <Card variant="small" style={{ height: "100%" }}>
            <h1>{todaysPoints}</h1>
            <h3>Total points earned today</h3>
          </Card>
        </NavLink>
      )}
      {process.env.REACT_APP_SHOP === "true" && (
        <a
          href={`${process.env.REACT_APP_SHOP_LINK}/admin-area/orders`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card variant="small" style={{ height: "100%" }}>
            <h1>{monthsPointsSpent}</h1>
            <h3>Total points spent this month</h3>
          </Card>
        </a>
      )}
      {process.env.REACT_APP_TEAMMATES === "true" && (
        <NavLink to="/admin-area/compliments?postedToday">
          <Card variant="small" style={{ height: "100%" }}>
            <h1>
              {compliments
                ? compliments.filter((c) => {
                    return isToday(c.datePosted.toDate());
                  }).length
                : 0}
            </h1>
            <h3>Compliments posted today</h3>
          </Card>
        </NavLink>
      )}
      {process.env.REACT_APP_TEAMMATES === "true" && (
        <NavLink to="/admin-area/compliments?approvedToday">
          <Card variant="small" style={{ height: "100%" }}>
            <h1>
              {compliments
                ? compliments.filter((c) => {
                    return isToday(c.dateApproved?.toDate());
                  }).length
                : 0}
            </h1>
            <h3>Compliments approved today</h3>
          </Card>
        </NavLink>
      )}
      {process.env.REACT_APP_MESSAGES === "true" && (
        <NavLink to="/admin-area/messages?today">
          <Card variant="small" style={{ height: "100%" }}>
            <h1>
              {messages
                ? messages.filter((c) => {
                    return isToday(c.datePosted?.toDate());
                  }).length
                : 0}
            </h1>
            <h3>Messages posted today</h3>
          </Card>
        </NavLink>
      )}
    </Stats>
  );
}

const Stats = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  column-gap: 1em;
  margin-bottom: 1em;
  text-align: center;
  row-gap: 1em;
  @media (max-width: 1025px) {
    grid-template-columns: 1fr 1fr;
  }

  a {
    transition: transform 0.3s ease;
    :hover {
      transform: translateY(-5px);
    }
  }
`;
