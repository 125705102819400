import React, { useState } from "react";
import { Button, Label, Textarea, Flex } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import CardModal from "../../../app/DSL/components/elements/CardModal";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import InputNum from "rc-input-number";
import "rc-input-number/assets/index.css";
import { usePoints } from "../../../app/context/points";
import { useToasts } from "react-toast-notifications";

export default function PointsModal({ user, isOpen, setOpen }) {
  const [points, setPoints] = useState(0);
  const [description, setDescription] = useState();
  const { updatePoints } = usePoints();
  const { addToast } = useToasts();

  const close = () => {
    setOpen(false);
    setPoints(0);
    setDescription(null);
  };
  return (
    <CardModal isOpen={isOpen} close={close} width="60%">
      <BlockHeaderContainer title={`Update ${user.displayName}'s points`} />
      <h3>Manually update a user's point</h3>
      <InputWrap>
        <Label>Description</Label>
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></Textarea>
      </InputWrap>
      <InputWrap>
        <Label>Number of points</Label>
        <InputNum
          step={1}
          value={points}
          onChange={(num) => {
            setPoints(num);
          }}
        />
      </InputWrap>
      <Flex sx={{ justifyContent: "space-between", marginTop: "1em" }}>
        <Button variant="outline" onClick={close}>
          Cancel
        </Button>
        <Button
          variant={!(points !== 0 && description) ? "disabled" : "primary"}
          onClick={() => {
            updatePoints({ points, description, user }).then(() => {
              addToast("Points Updated", {
                appearance: "success",
                autoDismiss: true,
                autoDismissTimeout: 1500,
              });
              close();
            });
          }}
        >
          Update points
        </Button>
      </Flex>
    </CardModal>
  );
}
