import React from "react";
import styled from "@emotion/styled";
import { useUsers } from "../../app/context/users";
import { useMemo } from "react";
import TeammateCard from "./TeammateCard";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { Ring } from "react-awesome-spinners";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";

export default function TeammatesList({ department, search }) {
  const { users } = useUsers();
  const teammates = useMemo(
    () =>
      users
        ? users
            /* .filter((u) => u.id !== user?.id) */
            .filter((u) => !u.email.includes("bigbrave"))
            .filter((u) => !u.isArchived)
            .filter((u) =>
              department && department.value
                ? u.department === department.value
                : true
            )
            .filter((u) =>
              search
                ? u.displayName.toLowerCase().includes(search.toLowerCase())
                : true
            )
            .sort((a, b) =>
              a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
            )
        : [],
    [users, department, search]
  );

  return (
    <div>
      <BlockHeaderContainer
        title={`Showing ${teammates.length} teammate${
          teammates.length === 1 ? "" : "s"
        }`}
      />
      {users ? (
        <Grid>
          {teammates.map((t, idx) => (
            <TeammateCard key={idx} user={t} />
          ))}
        </Grid>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Ring color={colours[primary].shade["500"]} />
        </div>
      )}
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1em;
  @media (max-width: 1025px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 1em;
    padding: 0.5em;
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
