import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Card, Heading, Divider } from "@theme-ui/components";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { ImageBlock } from "../../app/DSL/components/elements/styled";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { useMemo } from "react";
import { useUsers } from "../../app/context/users";
import { StyledLabel } from "../../app/DSL/components/elements/styled";
import { departments } from "../../app/context/data/departments";
import ComplimentList from "./Compliments/ComplimentList";
import AddCompliment from "./Compliments/AddCompliment";
import { useCompliments } from "../../app/context/compliments";
import regionData from "../../app/context/data/regions";
import { useUserContext } from "../../app/context/auth";
import { distributors } from "../../app/context/data/distributors";

export default function TeammateDetails({ match }) {
  const regions = regionData[process.env.REACT_APP_REGIONS];
  const { users } = useUsers();
  const loggedIn = useUserContext();
  const user = useMemo(
    () => (users ? users.find((u) => u?.id === match?.params?.id) : {}),
    [match, users]
  );
  const { getCompliments } = useCompliments();
  const [compliments, setCompliments] = useState();
  useEffect(
    () =>
      user?.id ? getCompliments(user, setCompliments) : console.log("loading"),
    [getCompliments, user]
  );

  const filteredCompliments = useMemo(
    () =>
      compliments
        ? compliments.filter((c) => c.isApproved && !c.isArchived)
        : [],
    [compliments]
  );

  return (
    <div>
      <PageHeaderContainer>View Teammate</PageHeaderContainer>

      <Grid>
        <ImageCard>
          <ImageBlock
            size="250px"
            style={{ marginBottom: "1em" }}
            url={
              user && user.profileImageUrl
                ? user.profileImageUrl
                : "/user-circle-solid.svg"
            }
          >
            <div className="img"></div>
          </ImageBlock>
          <Heading as="h2">{user?.displayName}</Heading>
          <Divider />
          <Heading as="h3">Designation</Heading>
          <p>{user?.designation ? user?.designation : "-"}</p>
          <Divider />
          {process.env.REACT_APP_USER_TYPE === "internal" ? (
            <>
              <Heading as="h3">Department</Heading>
              <p>
                {user?.department
                  ? departments.find((d) => d.value === user?.department)?.label
                  : "-"}
              </p>
              <Divider />
            </>
          ) : (
            <>
              <Heading as="h3">Distributor</Heading>
              <p>
                {user?.distributor
                  ? distributors.find((d) => d.value === user?.distributor)
                      ?.label
                  : "-"}
              </p>
              <Divider />
            </>
          )}

          <Heading as="h3">Region</Heading>
          <p>
            {user?.region
              ? regions.find((reg) => reg.value === user?.region)?.label
              : regions.find((r) => user?.regions[0] === r.value)?.label}
          </p>
          <Divider />
          <StyledLabel
            colour="darkBlue"
            style={{ marginTop: "1em", padding: "0.5em" }}
          >
            {filteredCompliments?.length ? filteredCompliments.length : 0}{" "}
            {filteredCompliments?.length === 1 ? "Compliment" : "Compliments"}
          </StyledLabel>
        </ImageCard>
        <Card>
          <BlockHeaderContainer
            title="Compliments"
            actionType="link"
            link="/teammates"
            buttonText="Back to teammates"
          />
          <ComplimentList
            compliments={filteredCompliments}
            loading={compliments === undefined}
          />
          {user?.id !== loggedIn?.id && <AddCompliment user={user} />}
        </Card>
      </Grid>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1em;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;

const ImageCard = styled(Card)`
  display: grid;
`;
