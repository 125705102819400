import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { useUserContext } from "../../app/context/auth";
import {
  formatPointString,
  pointColor,
} from "../../app/context/points/formatting";
import { Card } from "@theme-ui/components";
import { Ring } from "react-awesome-spinners";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";

export default function PointBreakdown({ pointsLog, admin }) {
  const user = useUserContext();
  const reasons = [
    "compliment__received",
    "compliment__given",
    "compliment__liked",
    "login",
  ];
  const descriptions = [
    "Points from receiving compliments",
    "Points from giving compliments",
    "Points from liking compliments",
    "Points from logging in",
  ];
  const reasonCount = (reason) =>
    pointsLog
      ?.filter((p) => p.reason === reason)
      .reduce((total, p) => (total += p.numPoints), 0);

  const totalPoints = useMemo(
    () =>
      admin
        ? pointsLog
          ? pointsLog.reduce((total, p) => (total += p.numPoints), 0)
          : 0
        : user?.points,
    [pointsLog, admin, user]
  );
  return pointsLog ? (
    <div>
      <Points>
        <h1>
          <FontAwesomeIcon icon={["far", "star"]} />{" "}
          {totalPoints && formatPointString(totalPoints, "spaces")}{" "}
        </h1>
        <h2>POINTS</h2>
      </Points>
      <Grid admin={admin}>
        {reasons.map((r, idx) => (
          <PointCard reason={r} key={idx}>
            <h2>{descriptions[idx]}</h2>
            <h1>
              <FontAwesomeIcon icon={["far", "star"]} />{" "}
              {reasonCount(r) && formatPointString(reasonCount(r), "spaces")}{" "}
            </h1>
          </PointCard>
        ))}
      </Grid>
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <Ring color={colours[primary].shade["500"]} />
    </div>
  );
}

const Points = styled.div`
  text-align: center;
  h1 {
    svg {
      color: ${(props) => props.theme.colors.orange.shade["500"]};
      margin-right: 5px;
    }
    font-size: 4em;
    margin-bottom: 0;
  }
  h2 {
    font-size: 2.5em;
    margin-top: 0;
  }
`;

const PointCard = styled(Card)`
  padding: 1em;
  background: ${(props) =>
    props.theme.colors.orange.shade[pointColor(props.reason)]};
  color: white;
  margin-bottom: 1em;
  text-align: center;

  h2 {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.2em;
  }
  h1 {
    margin: 0;
    margin-top: 0.5em;
    font-size: 2em;
  }
`;

const Grid = styled.div`
  column-gap: 1em;
  display: grid;
  grid-template-columns: ${(props) =>
    props.admin ? "1fr 1fr 1fr 1fr" : "1fr"};
  @media (max-width: 1366px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;
