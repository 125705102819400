import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Button, Spinner, AspectRatio } from "@theme-ui/components";
import { useUserContext } from "../../app/context/auth";
import UserForm from "../AdminArea/Users/UserForm";
import { useFirebase } from "../../app/context/Firebase";
import { useToasts } from "react-toast-notifications";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import ImageUploadModal from "./ImageUploadModal";
import { ImageBlock } from "../../app/DSL/components/elements/styled";
import { useCompliments } from "../../app/context/compliments";
import { useMemo } from "react";
import ComplimentList from "../Teammates/Compliments/ComplimentList";
import { StyledLabel } from "../../app/DSL/components/elements/styled";
import { primary } from "../../app/DSL/theme";

const Profile = () => {
  const user = useUserContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const firebase = useFirebase();
  const { addToast } = useToasts();
  const [modalOpen, setModalOpen] = useState(false);
  const { getCompliments } = useCompliments();
  const [compliments, setCompliments] = useState();
  useEffect(
    () =>
      user ? getCompliments(user, setCompliments) : console.log("loading"),
    [getCompliments, user]
  );

  const complimentList = useMemo(
    () => (compliments ? compliments.filter((c) => c.isApproved) : []),
    [compliments]
  );

  const handleSubmit = async (e, formValues, reset) => {
    setLoading(true);
    e.preventDefault();
    formValues.displayName = `${formValues.firstName} ${formValues.lastName}`;
    delete formValues.formValid;

    return firebase
      .user(user.id)
      .update({
        ...formValues,
      })

      .then(() => {
        addToast("Edited Successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        setError(error);
        console.log(error);
      });
  };
  const removeImage = () => {
    return firebase
      .user(user.id)
      .update({
        profileImageUrl: firebase.firestore.FieldValue.delete(),
      })

      .then(() => {
        addToast("Removed Successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
        });
      })
      .catch((error) => {
        setLoading(false);

        setError(error);
        console.log(error);
      });
  };
  return (
    <div>
      <PageHeaderContainer>Profile</PageHeaderContainer>
      <Grid>
        <ImageCard>
          <ImageContainer>
            <AspectRatio
              ratio={1 / 1}
              style={{ margin: "1em", display: "grid", alignItems: "center" }}
            >
              <ImageBlock
                size="full"
                max="300px"
                url={
                  user && user.profileImageUrl
                    ? user.profileImageUrl
                    : "/user-circle-solid.svg"
                }
              >
                <div className="img"></div>
                <div className="hover not-mobile">
                  {user && user.profileImageUrl ? (
                    [
                      <Button
                        onClick={() => setModalOpen(true)}
                        key="chnage"
                        style={{ alignSelf: "end" }}
                      >
                        Change Image
                      </Button>,
                      <Button
                        onClick={() => removeImage()}
                        key="remove"
                        style={{ alignSelf: "start" }}
                      >
                        Remove Image
                      </Button>,
                    ]
                  ) : (
                    <Button onClick={() => setModalOpen(true)}>
                      Add Image
                    </Button>
                  )}
                </div>
              </ImageBlock>
            </AspectRatio>

            <div className="mobile">
              <Buttons>
                {user && user.profileImageUrl ? (
                  [
                    <Button
                      onClick={() => setModalOpen(true)}
                      key="change"
                      style={{ alignSelf: "end" }}
                    >
                      Change Image
                    </Button>,
                    <Button
                      onClick={() => removeImage()}
                      key="remove"
                      style={{ alignSelf: "start" }}
                    >
                      Remove Image
                    </Button>,
                  ]
                ) : (
                  <Button onClick={() => setModalOpen(true)}>Add Image</Button>
                )}
              </Buttons>
            </div>
          </ImageContainer>
          <BlockHeaderContainer
            title={`Welcome, ${user && user.displayName}`}
          />

          {user ? (
            <UserForm
              profile={true}
              loading={loading}
              error={error}
              handleSubmit={handleSubmit}
              submitText="Update Profile"
              initialValues={user}
            />
          ) : (
            <Spinner />
          )}
        </ImageCard>
        <Card>
          <BlockHeaderContainer
            title="Your Compliments"
            rightComponent={
              <StyledLabel colour={primary}>
                {complimentList?.length ? complimentList?.length : 0} Compliment
                {complimentList?.length === 1 ? "" : "s"}
              </StyledLabel>
            }
          />
          <ComplimentList compliments={complimentList} />
        </Card>
      </Grid>
      <ImageUploadModal isOpen={modalOpen} setOpen={setModalOpen} user={user} />
    </div>
  );
};

export default Profile;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1em;
  @media (max-width: 1025px) {
    .mobile {
      display: block !important;
    }
    grid-template-columns: 2fr 3fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;

const ImageContainer = styled.div`
  display: grid;
  column-gap: 1em;
  row-gap: 1em;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 250px 1fr;
  }
  @media (max-width: 576px) {
    width: 250px;
    margin: auto;
    grid-template-columns: 1fr;
  }
`;

const ImageCard = styled(Card)`
  display: grid;
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1em;
  row-gap: 1em;
  margin-bottom: 1em;
`;
