import React, { createContext, useContext, useState, useEffect } from "react";
import Firebase from "../Firebase/functions";

const NewsflashesContext = createContext({});

const BLANK = {
  title: "Title",
  html: "Body",
  author: { userId: "", name: "" },
};

//TODO: Refactor to match other contexts

export const NewsflashesProvider = ({ children }) => {
  var [state, setState] = useState();
  // ============
  // ⬇⬇ Read ⬇⬇
  var handleSnap = ({ docs, empty }) =>
    setState(empty ? [] : docs.map((doc) => ({ ...doc.data(), id: doc.id })));

  useEffect(() => Firebase.newsflashes().onSnapshot(handleSnap), []);

  // ============
  // ⬆⬆ Write ⬆⬆
  var update = (id) => (data) =>
    Firebase.newsflashes(id).set(
      { ...data, updatedAt: new Date() },
      { merge: true }
    );

  var get = (id) => state?.find(({ id: uid }) => uid === id);

  var create = (data) =>
    Firebase.newsflashes().add({ ...BLANK, ...data, createdAt: new Date() });

  // ============
  // ⬆⬆ Delete ⬆⬆
  var remove = (id) => id && Firebase.newsflashes(id).delete();

  return (
    <NewsflashesContext.Provider value={{ state, create, get, update, remove }}>
      {children}
    </NewsflashesContext.Provider>
  );
};

export const NewsflashesConsumer = NewsflashesContext.Consumer;

export const useNewsflashes = (id) => {
  var data = useContext(NewsflashesContext);
  var focus = data?.get(id);
  return { ...data, focus };
};
