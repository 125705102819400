import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-date-picker";

export default function DateInput({ startDate, inputProps, onChange, value }) {
  return (
    <DatePicker
      activeStartDate={startDate}
      format={"dd/MM/y"}
      onChange={onChange}
      value={value}
      {...inputProps}
      calendarIcon={
        <FontAwesomeIcon icon={["far", "calendar-alt"]} size="lg" />
      }
      closeIcon={<FontAwesomeIcon icon={["fal", "times"]} />}
    />
  );
}
