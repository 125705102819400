import React from "react";
import AccordionItem from "../../../app/DSL/components/elements/AccordionItem";

const items = [
  {
    title: "Will my Basic salary stay the same if I stay in the Company?",
    content: "Basic salary will not be reduced.",
  },
  {
    title: "Will my benefit stay the same if I stay in the Company?",
    content:
      "All benefits will be aligned to the position you are successful in, in the “New Structure”",
  },
  {
    title:
      "If my current Bonus % target is higher than the position I am successful in, in the new structure will this be reduced?",
    content:
      "Yes. Your Bonus % target will be aligned with the Z level of the position you are successful in, in the new structure.",
  },
  {
    title: "Will I have to sign a new Contract of Employment?",
    content: "Yes",
  },
  {
    title: "If I volunteer to leave the company, will I receive more money?",
    content:
      "This will be a topic of consultation; requests must be put to the company in writing.",
  },
  {
    title: "Will my leave balance pay out to me at date of termination?",
    content:
      "Yes, annual leave available at date of termination will be paid out to employees in line with the annual leave policy. This will be paid out in the month following your termination month.",
  },
  {
    title: "Is sick leave paid out?",
    content: "Sick leave is not paid out at date of termination.",
  },
  {
    title: "Will I have to work my notice month?",
    content:
      "The anticipated exit date for employees is August 2020, however working notice or not will be part of the consultation process and agreed with each employee. Payment in lieu of notice, might be considered and is a topic of consultation.",
  },
  {
    title: "What is the company doing to support affected employees?",
    content: (
      <ul>
        <li>
          Possible re-deployment to any suitable vacant alternative positions
          which are identified in other departments;
        </li>
        <li>
          Employees will be granted reasonable time off either during the notice
          period or before to enable them to attend interviews for alternative
          employment;
        </li>
        <li>Completion of relevant UIF documentation; and</li>
        <li>The Company will prepare a Certificate of Service.</li>
      </ul>
    ),
  },
  {
    title: "Will employees be given calculations on retrenchment calculations?",
    content: "Yes",
  },
  {
    title: "What portion is tax exempt?",
    content:
      "SARS offers a R 500 000 tax exemption if the employee has not been retrenched before.",
  },
  {
    title: "Tax directive.",
    content:
      "The company will apply for this at the request of the employee or alternatively the employee may apply directly to SARS. We usually apply on behalf of the employee.",
  },
  {
    title:
      "If I Volunteer to leave the organisation will the R 500 000 tax exemption apply to my Provident Fund Cash Withdrawal?",
    content:
      "Yes, all voluntary applications will be considered to be a qualifying retrenchment and will be specified as such on the Withdrawal form.",
  },
  {
    title:
      "What happens if we do not volunteer for a package, but wait for our services to be terminated?",
    content:
      "If an employee did not apply to be considered for the voluntary offers by the 8 th of July 2020, and if they are not successful in securing a position, and if the company is not able to offer an alternative position, their employment contracts will be terminated due to the operational requirements of the employer.",
  },
  {
    title: "Medical aid savings account, is this going to be paid out?",
    content:
      "The Medical Savings account belongs to the member. If an employee is retrenched and chooses not to continue with a medical aid or to use medical aid other than Discovery Health, their Medical Savings will be paid out to them when they withdraw from Discovery Health. If the member continues the membership in their personal capacity, then they will still have the medical savings to use as part of their cover. This is governed by the rules of Discovery Health and members must confirm with them before they make changes.",
  },
  {
    title:
      "Is there an option to remain on the company medical for a certain amount of time?",
    content:
      "Discovery Health is not a company medical scheme and the company acts as a collection agent for the contribution. This means that retrenched employees may elect to remain with Discovery Health or to pursue other options in their personal capacity.",
  },
  {
    title:
      "Can medical aid benefits be transferred to individuals at a preferential rate?",
    content:
      "There are no preferential rates, contributions are determined by the member’s choice of plan, and DH’s rates are fixed for the year. If members chose to stay on the medical aid in their personal capacity, they will be able to down grade their plan. Please note DH has rules in this regard depending on the how the benefit has been used which may have financial implications.",
  },
  {
    title:
      "Who do we use for a reference at the company when we seek alternative employment?",
    content:
      "We recommend that you get a written reference from your manager before you exit. HR can only provide a certificate of service.",
  },
  {
    title: "On what basis is severance calculated.",
    content:
      "Severance is calculated on the Zimmer Biomet Guidelines, as stipulated in your Section 189 Notification letter.",
  },
];

export default function FAQ() {
  return items.map((item, key) => {
    return <AccordionItem {...item} key={key} />;
  });
}

// const Wrapper = styled.div`
//     .accordion-list {
//         list-style: none;
//         margin: 0;
//         padding: 0;
//         background-color: #fff;
//         max-width: 30rem;
//         border-radius: 0.4rem;
//         overflow: hidden;
//         box-shadow: 0 0 0.8rem 0.1rem rgba(darken($cAccent, 20%), 0.06),
//             0 20px 30px -10px rgba(darken($cAccent, 20%), 0.2);

//         &__item {
//             & + & {
//                 border-top: 1px solid $cLightGray;
//             }
//         }
//     }
// `;
