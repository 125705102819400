/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";

import { Card, Heading, Button, Image } from "@theme-ui/components";
import styled from "@emotion/styled";
import { useFirebase } from "../../app/context/Firebase";
import UserForm from "../AdminArea/Users/UserForm";
import { NavLink } from "react-router-dom";
import { primary } from "../../app/DSL/theme";

export default function Register() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState("create");
  const firebase = useFirebase();

  const handleSubmit = async (e, formValues, reset, createAnother) => {
    setLoading(true);
    e.preventDefault();
    formValues.displayName = `${formValues.firstName} ${formValues.lastName}`;
    formValues.email = formValues.email.toLowerCase();
    delete formValues.formValid;

    return firebase
      .createNewUser({
        ...formValues,
        firstLogin: true,
        isApproved: false,
      })

      .then(() => {
        setLoading(false);
        setView("done");
      })
      .catch((error) => {
        setLoading(false);

        setError(error);
        console.log(error);
      });
  };

  return (
    <RegisterBox>
      <RegisterCard sx={{ textAlign: "center" }}>
        <div>
          <Image src="/logo.svg" variant="login" />
          <Heading as="h1" variant="display">
            {process.env.REACT_APP_SITE_TITLE.toUpperCase()}
          </Heading>
        </div>
        <Heading as="h4" color="blue.font.500">
          {
            {
              create: "REGISTER",
              done: `WELCOME TO ${process.env.REACT_APP_SITE_TITLE.toUpperCase()}`,
            }[view]
          }
        </Heading>
        {
          {
            create: (
              <div>
                <UserForm
                  loading={loading}
                  error={error}
                  handleSubmit={handleSubmit}
                  submitText="Register"
                  initialValues={{ role: "employee" }}
                  register={true}
                />
              </div>
            ),
            done: (
              <div>
                <p>
                  You have been registered on {process.env.REACT_APP_SITE_TITLE}
                  . Please check your email to activate your account.
                </p>
                <NavLink to="/">
                  <Button>Login</Button>
                </NavLink>
              </div>
            ),
          }[view]
        }
        {view === "create" && (
          <div>
            Already have an account? <NavLink to="/">Login</NavLink>
          </div>
        )}
      </RegisterCard>
    </RegisterBox>
  );
}

const RegisterBox = styled.div`
  background-image: url(${process.env.REACT_APP_BG_URL});
  background-size: ${process.env.REACT_APP_BG_SIZE};
  background-repeat: ${process.env.REACT_APP_BG_REPEAT};
  background-color: ${(props) => props.theme.colors.tusk.shade["300"]};
  min-height: calc(100vh - 5em);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-content: center;
  padding: 4em 0 1em;
`;

const RegisterCard = styled(Card)`
  width: 50%;
  padding: 2em 4em;
  display: grid;
  grid-gap: 24;
  grid-template-rows: 150px 100px 1fr 50px;
  align-content: center;
  align-items: center;
  & div:first-of-type {
    align-self: end;
  }
  a {
    color: ${(props) => props.theme.colors[primary].shade["300"]};
  }
  @media (max-width: 768px) {
    width: 90%;
    margin-top: 3em;
    padding: 2em;
    grid-template-rows: 100px 100px 1fr 50px;

    img {
      width: 40%;
      margin-bottom: 0;
    }
  }
`;
