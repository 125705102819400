import React from "react";
import { CSVLink } from "react-csv";
// import SimpleLoader from '../../../app/layout/SimpleLoader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@theme-ui/components";

export default function DownloadReport({ data, headers, fileName }) {
  const today = new Date();

  return (
    <CSVLink
      data={data}
      headers={headers}
      className="download-btn"
      filename={`${fileName}-${today.getTime()}.csv`}
    >
      <Button>
        <FontAwesomeIcon icon={["fas", "download"]} />{" "}
        <span>Download Report</span>
      </Button>
    </CSVLink>
  );
}
