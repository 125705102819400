/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";

import {
  Card,
  Heading,
  Flex,
  Button,
  Image,
  Label,
  Input,
  Message,
} from "@theme-ui/components";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import styled from "@emotion/styled";
import { useForm } from "../../app/forms/useForm";
import { useFirebase } from "../../app/context/Firebase";
import { useAuthContext } from "../../app/context/auth";
import { useEffect } from "react";

import zxcvbn from "zxcvbn";

import ReactTooltip from "react-tooltip";

export default function ChangePassword({ setView }) {
  const formFields = [
    {
      name: "new",
      title: "New Password",
      type: "password",
      validate: (x) => x.length >= 6,
      error: "Password must be longer than 6 characters",
      placeholder: "••••••",
    },
    {
      name: "confirm",
      title: "Confirm Password",
      type: "password",
      validate: (x) => x.length >= 6,
      error: "Passwords must match",
      placeholder: "••••••",
    },
  ];
  const { formValid, inputProps, values, formattedValues } =
    useForm(formFields);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordFeedback, setPasswordFeedback] = useState({});

  const firebase = useFirebase();

  const auth = useAuthContext();

  useEffect(() => {
    const password = values.new.value;
    const score = zxcvbn(password);
    return () => {
      setPasswordFeedback(score.feedback);
      setPasswordScore(score.score);
    };
  }, [values]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValues = formattedValues();
    setLoading(true);
    await firebase
      .updatePassword(formValues.new)
      .then(async (data) => {
        auth.updateFirstLogin();
      })
      .then((data) => {
        setView("image");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setError(error);
      });
  };

  return (
    <LoginBox>
      <LoginCard sx={{ textAlign: "center" }}>
        <LoginForm onSubmit={handleSubmit}>
          <div>
            <Image src="/logo.svg" variant="login" />
            <Heading as="h1" variant="display">
              {process.env.REACT_APP_SITE_TITLE.toUpperCase()}
            </Heading>
          </div>
          <Heading as="h4" color="blue.font.500">
            Create a new password
          </Heading>
          <div>
            <InputWrap
              //TODO:make a function to reuse
              state={
                (error || values.new.touched) &&
                !values.new.valid &&
                values.new.blur &&
                "error"
              }
            >
              <Label htmlFor="new">New Password</Label>
              <Input {...inputProps("new")} placeholder="••••••" />
              {(error || values.new.touched) &&
              !values.new.valid &&
              values.new.blur ? (
                <Label variant="infoLabel.warning">{values.new.error}</Label>
              ) : (
                <Label variant="infoLabel.hidden">...</Label>
              )}
            </InputWrap>
            <Label variant="infoLabel">Password strength</Label>
            <PasswordStrengthBar
              score={passwordScore}
              data-tip
              data-for="feedback"
            >
              <div></div>
            </PasswordStrengthBar>
            <LightReactTooltip place="right" effect="solid" id="feedback">
              {passwordFeedback.warning || passwordFeedback.suggestions ? (
                <span>
                  <h4>Password Feedback</h4>
                  <PasswordStrengthBar
                    score={passwordScore}
                    data-tip
                    data-for="feedback"
                  >
                    <div></div>
                  </PasswordStrengthBar>
                  {passwordFeedback.warning}
                  {passwordFeedback.suggestions &&
                    passwordFeedback.suggestions.map((s, idx) => (
                      <p key={idx}>{s}</p>
                    ))}
                </span>
              ) : (
                <span>
                  <h4>Password Feedback</h4>
                  <PasswordStrengthBar
                    score={passwordScore}
                    data-tip
                    data-for="feedback"
                  >
                    <div></div>
                  </PasswordStrengthBar>
                  <p>Enter a password to get feedback</p>
                </span>
              )}
            </LightReactTooltip>

            <InputWrap
              state={
                (error || values.confirm.touched) &&
                values.new.value !== values.confirm.value &&
                values.new.blur &&
                "error"
              }
            >
              <Label htmlFor="confirm">Confirm Password</Label>
              <Input {...inputProps("confirm")} placeholder="••••••" />
              {(error || values.confirm.touched) &&
              values.new.value !== values.confirm.value &&
              values.new.blur ? (
                <Label variant="infoLabel.warning">Password must match</Label>
              ) : (
                <Label variant="infoLabel.hidden">...</Label>
              )}
            </InputWrap>
            {error && <Message variant="warning">{error.message}</Message>}
          </div>
          <Flex sx={{ justifyContent: "space-between" }}>
            <Button
              sx={{ justifySelf: "flex-end" }}
              onClick={() => firebase.signOut()}
              variant="outline"
            >
              Logout
            </Button>
            {loading ? (
              <Button disabled={loading}>Loading</Button>
            ) : (
              <Button
                type="submit"
                variant={
                  formValid && values.new.value === values.confirm.value
                    ? "primary"
                    : "disabled"
                }
              >
                Save New Password
              </Button>
            )}
          </Flex>
        </LoginForm>
      </LoginCard>
    </LoginBox>
  );
}

const LoginBox = styled.div`
  background-color: ${(props) => props.theme.colors.tusk.shade["500"]};
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-content: center;
`;

const LoginCard = styled(Card)`
  width: 50%;
  padding: 2em;
  @media (max-width: 768px) {
    width: 90%;
    margin-top: 3em;
    padding: 2em;

    img {
      width: 40%;
      margin-bottom: 0;
    }
  }
`;

const LoginForm = styled.form`
  display: grid;
  grid-gap: 24;
  grid-template-rows: 150px 100px 1fr 70px;
  align-content: center;
  align-items: center;
  & div:first-of-type {
    align-self: end;
  }
  @media (max-width: 768px) {
    grid-template-rows: 100px 100px 1fr 50px;
  }
`;

const PasswordStrengthBar = styled.div`
  margin: 10px auto 2em auto;
  padding: 0;
  width: 100%;
  height: 10px;
  overflow: hidden;
  background: ${(props) => props.theme.colors.tusk.shade["300"]};
  border-radius: 6px;

  div {
    position: relative;
    float: left;
    min-width: 1%;
    height: 100%;
    background: ${(props) => {
      switch (props.score) {
        case 0:
          return props.theme.colors.warning.shade["500"];
        case 1:
          return props.theme.colors.warning.shade["500"];
        case 2:
          return props.theme.colors.orange.shade["500"];
        case 3:
          return props.theme.colors.yellow.shade["500"];
        case 4:
          return props.theme.colors.lightGreen.shade["500"];
        default:
          break;
      }
    }};
    width: ${(props) => (props.score / 4) * 100}%;
  }
`;

const LightReactTooltip = styled(ReactTooltip)`
  color: ${(props) => props.theme.colors.midnight.shade["500"]} !important;
  background-color: ${(props) =>
    props.theme.colors.tusk.shade["100"]} !important;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid ${(props) => props.theme.colors.tusk.shade["500"]};
  &.show {
    opacity: 1 !important;
  }
  &.place-right {
    &:after {
      border-right-color: ${(props) =>
        props.theme.colors.tusk.shade["500"]} !important;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    }
  }
`;
