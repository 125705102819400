/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import Form from "../../../app/forms/Form";
import styled from "@emotion/styled";
import { departments } from "../../../app/context/data/departments";
import { useAuthContext } from "../../../app/context/auth";
import regionData from "../../../app/context/data/regions";
import { distributors } from "../../../app/context/data/distributors";

const userGroup =
  process.env.REACT_APP_USER_TYPE === "internal" ? "department" : "distributor";

export default function UserForm({
  initialValues,
  handleSubmit,
  loading,
  error,
  submitText,
  create,
  profile,
  register,
  cancel,
}) {
  const regions = regionData[process.env.REACT_APP_REGIONS];
  const { claims } = useAuthContext();

  const roles = ["admin", "manager", "employee"];

  const formFields = [
    {
      name: "firstName",
      title: "First Name",
      type: "text",
      validate: (x) => x.length >= 3,
      error: "Name must be longer than 3 characters",
      placeholder: "John",
    },
    {
      name: "lastName",
      title: "Last Name",
      type: "text",
      validate: (x) => x.length >= 3,
      error: "Name must be longer than 3 characters",
      placeholder: "Doe",
    },
    {
      name: "email",
      title: "Email",
      type: "email",
      validate: (x) => {
        //Checks if zimmer email on registration
        const zbEmail =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@zimmerbiomet.com/;
        const bbEmail =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@bigbrave.co.za/;
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return claims?.isAdmin || process.env.REACT_APP_USER_TYPE === "external"
          ? re.test(x)
          : zbEmail.test(x) || bbEmail.test(x);
      },
      error: `Must be a valid ${
        process.env.REACT_APP_USER_TYPE === "internal" ? "Zimmer Biomet" : ""
      }  email address`,
      placeholder: "example@zimmerbiomet.com",
      readOnly: initialValues.email !== undefined,
    },
    {
      name: "birthdate",
      title: "Date of Birth",
      type: "date",
      validate: (x) => x < new Date(),
      error: "Select your date of birth",
      placeholder: "MM/DD/YYYY",
      maxDate: new Date(),
      minDate: new Date("01/01/1900"),
    },
    {
      name: "role",
      title: "Role",
      type: "radio",
      options: roles,
      validate: (x) => x.length > 1,
      error: "Please select a role",
      hidden: profile || register,
    },
    {
      name: "designation",
      title: "Designation",
      type: "text",
      validate: (x) => x.length > 3,
      error: "Designation must be longer than 3 characters",
      placeholder: "Sales",
    },
    process.env.REACT_APP_USER_TYPE === "internal"
      ? {
          name: "department",
          title: "Department",
          type: "select",
          options: departments,
          validate: (x) => x && x.length > 0,
          error: "Please select a department",
          placeholder: "--- Select a department --",
          selectedOption: initialValues.department
            ? departments.find((d) => initialValues.department === d.value)
            : {},
        }
      : {
          name: "distributor",
          title: "Distributor",
          type: "select",
          options: distributors,
          validate: (x) => x && x.length > 0,
          error: "Please select a distributor",
          placeholder: "--- Select a distributor --",
          selectedOption: initialValues.distributor
            ? distributors.find((d) => initialValues.distributor === d.value)
            : {},
          optional: true,
          hidden: profile || register,
        },

    {
      name: "region",
      title: "Region",
      type: "select",
      options: regions,
      validate: (x) => x && x.length > 0,
      error: "Please select a region",
      placeholder: "--- Select region --",
      selectedOption:
        initialValues && initialValues.region
          ? initialValues.region
            ? regions.find((r) => initialValues.region === r.value)
            : {}
          : initialValues.regions
          ? regions.find((r) => initialValues.regions[0] === r.value)
          : {},
    },
  ];

  return (
    <StyledForm context={profile ? "profile" : register ? "register" : ""}>
      <Form
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        loading={loading}
        error={error}
        submitText={submitText}
        formFields={formFields}
        create={create}
        type="user"
        cancel={cancel}
        register={register}
      />
    </StyledForm>
  );
}
const StyledForm = styled.div`
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
    grid-template-rows: auto;
    grid-template-areas: ${(props) => {
      switch (props.context) {
        case "profile":
          return `"firstName firstName" "lastName lastName" "email email" "birthdate birthdate" "role role" "designation designation" "${userGroup} ${userGroup}" "region region"  "checkbox checkbox" "error error" "button button"`;

        default:
          return userGroup === "distributor"
            ? `"firstName lastName" "email birthdate" "role role" "designation designation" "${userGroup} ${userGroup}" "region region" "checkbox checkbox" "error error"  "message message" "button button"`
            : `"firstName lastName" "email birthdate" "role role" "designation ${userGroup}" "region region" "checkbox checkbox" "error error"  "message message" "button button"`;
      }
    }};

    @media (max-width: 768px) {
      grid-template-areas: "firstName firstName" "lastName lastName" "email email" "birthdate birthdate" "role role" "designation designation" "${userGroup} ${userGroup}" "region region" "checkbox checkbox" "error error" "message message" "button button";
    }
  }
`;
