import React from "react";
import { PageWrapper, ContentWrapper } from "./DSL/components/layout/styled";
import SideNav from "./DSL/components/navigation/SideNav";
import Router from "./Router";
import { useAuthContext } from "./context/auth";
import styled from "@emotion/styled";
import { primary } from "./DSL/theme";
import SideBarWrapper from "./DSL/components/layout/SideBarWrapper";
import { Ring } from "react-awesome-spinners";
import PageHeaderContainer from "./DSL/components/layout/PageHeaderContainer";
import colours from "./DSL/styles/colours";
import Footer from "./DSL/components/layout/Footer";
import CheckVersion from "./DSL/components/elements/CheckVersion";

export default function Main() {
  const auth = useAuthContext();
  return auth && auth.claims ? (
    <PageWrapper>
      <SideNav />
      <ContentWrapper>
        <SideBarWrapper>
          <Router />
        </SideBarWrapper>
        <Footer />
        <CheckVersion />
      </ContentWrapper>
    </PageWrapper>
  ) : (
    <PageWrapper>
      <SkeletonSide className="sidenav">
        <BoxButton>
          <img src="/ZBLogo.svg" alt="zb" />
        </BoxButton>
      </SkeletonSide>
      <ContentWrapper>
        <div>
          <PageHeaderContainer image />
          <div style={{ textAlign: "center" }}>
            <Ring color={colours[primary].shade["500"]} />
          </div>
        </div>
        <Footer />
      </ContentWrapper>
    </PageWrapper>
  );
}

const SkeletonSide = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors[primary].shade["500"]};
`;

const BoxButton = styled.div`
  width: 100%;
  height: 75px;
  background-color: ${(props) => props.theme.colors[primary].shade["800"]};
  text-align: center;
  display: grid;
  cursor: pointer;

  img {
    align-self: center;
    margin: 0;
    margin-top: 2px;
    justify-self: center;
  }
`;
