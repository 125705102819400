export default {
  regions: [
    {
      label: "Cape Town",
      value: "cape-town",
    },
    {
      label: "Free State",
      value: "free-state",
    },
    {
      label: "Gauteng",
      value: "gauteng",
    },
    {
      label: "KwaZulu-Natal",
      value: "kwazulu-natal",
    },
    {
      label: "Sub Saharan Africa",
      value: "sub-saharan-africa",
    },
    {
      label: "Namibia",
      value: "namibia",
    },
    {
      label: "Port Elizabeth",
      value: "port-elizabeth",
    },
    {
      label: "National",
      value: "national",
    },
  ],

  regions_ssa: [
    {
      label: "SSA",
      value: "ssa",
    },
    {
      label: "Botswana",
      value: "Botswana",
    },
    {
      label: "Ethiopia",
      value: "Ethiopia",
    },
    {
      label: "Kenya",
      value: "Kenya",
    },
    {
      label: "Malawi",
      value: "Malawi",
    },
    {
      label: "Mauritius",
      value: "Mauritius",
    },
    {
      label: "Nigeria",
      value: "Nigeria",
    },
    {
      label: "Rwanda",
      value: "Rwanda",
    },
    {
      label: "Tanzania",
      value: "Tanzania",
    },
    {
      label: "Uganda",
      value: "Uganda",
    },
    {
      label: "Zambia",
      value: "Zambia",
    },
    {
      label: "Zimbabwe",
      value: "Zimbabwe",
    },
  ],
};
