import React, { useState } from "react";
import ChangePassword from "./ChangePassword";
import FirstImageUpload from "./FirstImageUpload";

export default function FirstLogin() {
  const [view, setView] = useState("password");
  return {
    password: <ChangePassword setView={setView} />,
    image: <FirstImageUpload />,
  }[view];
}
