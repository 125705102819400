export const standardNavItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: ["fal", "th"],
  },
  ...(process.env.REACT_APP_TEAMMATES === "true"
    ? [
        {
          title: "Teammates",
          link: "/teammates",
          icon: ["fal", "user-friends"],
        },
      ]
    : []),
  ...(process.env.REACT_APP_NEWSFLASH === "true"
    ? [
        {
          title: "Newsflash",
          link: "/newsflash",
          icon: ["fal", "newspaper"],
        },
      ]
    : []),
  ...(process.env.REACT_APP_VIEW_POINTS === "true"
    ? [
        {
          title: "Points Log",
          link: "/points-log",
          icon: ["fal", "star"],
        },
      ]
    : []),
  /* {
    title: "Manage Data",
    link: "/manage-data",
    icon: ["fal", "hospital-user"],
  }, */
];

export const notApprovedNavItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: ["fal", "th"],
  },
  ...(process.env.REACT_APP_NEWSFLASH === "true"
    ? [
        {
          title: "Newsflash",
          link: "/newsflash",
          icon: ["fal", "newspaper"],
        },
      ]
    : []),
];

export const adminNavItems = [
  {
    title: "Dashboard",
    link: "/admin-area/dashboard",
    icon: ["fal", "th"],
  },
  {
    title: "Users",
    link: "/admin-area/users",
    icon: ["fal", "users"],
  },
  ...(process.env.REACT_APP_TEAMMATES === "true"
    ? [
        {
          title: "Compliments",
          link: "/admin-area/compliments",
          icon: ["fal", "thumbs-up"],
        },
      ]
    : []),
  ...(process.env.REACT_APP_MESSAGES === "true"
    ? [
        {
          title: "Messages",
          link: "/admin-area/messages",
          icon: ["fal", "comment-dots"],
        },
      ]
    : []),
  ...(process.env.REACT_APP_NEWSFLASH === "true"
    ? [
        {
          title: "Newsflash",
          link: "/admin-area/newsflash",
          icon: ["fal", "newspaper"],
        },
      ]
    : []),
  ...(process.env.REACT_APP_SLIDERS === "true"
    ? [
        {
          title: "Sliders",
          link: "/admin-area/sliders",
          icon: ["fal", "bring-forward"],
        },
      ]
    : []),
  // ...(process.env.REACT_APP_SCREENS === "true"
  //   ? [
  //       {
  //         title: "Screens",
  //         link: "/admin-area/screens",
  //         icon: ["fal", "computer-classic"],
  //       },
  //     ]
  //   : []),
  ...(process.env.REACT_APP_SHOP === "true" ||
  process.env.REACT_APP_BROCHURE === "true" ||
  process.env.REACT_APP_WAREHOUSE === "true" ||
  process.env.REACT_APP_SALES === "true"
    ? [
        {
          title: "Platforms",
          link: "/admin-area/platforms",
          icon: ["fal", "shapes"],
        },
      ]
    : []),
  ...(process.env.REACT_APP_VIEW_POINTS === "true"
    ? [
        {
          title: "Points",
          link: "/admin-area/points",
          icon: ["fal", "star"],
        },
      ]
    : []),
];
