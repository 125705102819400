import React from "react";
import { Image } from "@theme-ui/components";
import styled from "@emotion/styled";
import NewsflashCard from "./Card";
import { isBefore } from "date-fns";

export default function NewsflashList({ items = [] }) {
  return items?.length > 0 ? (
    <Container>
      {items
        .filter((i) => !i.isArchived)
        .sort((a, b) =>
          isBefore(a.createdAt.toDate(), b.createdAt.toDate()) ? 1 : -1
        )
        .map((a) => (
          <NewsflashCard {...a} key={a.id} />
        ))}
    </Container>
  ) : (
    <div style={{ textAlign: "center" }}>
      <Image
        src="/assets/compliments.svg"
        style={{ width: "45%", margin: "auto" }}
      />
      <h4>There are no items here</h4>
    </div>
  );
}

const Container = styled.div`
  & > div {
    margin-bottom: 1em;
  }
  max-height: 60vh;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;

  @media (max-width: 1366px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 1em;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
