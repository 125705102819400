import "./styles/main.css";
import button from "./styles/button";
import colours from "./styles/colours";
import forms from "./styles/form";

export const primary = "darkBlue";

export default {
    colors: colours,
    ...forms,
    styles: {
        hr: {
            color: "tusk.shade.500",
        },
    },
    backgroundColor: "white",
    fonts: {
        body: '"Source Sans Pro", sans-serif',
        heading: "inherit",
        monospace: "Menlo, monospace",
    },
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700,
    },
    cards: {
        primary: {
            padding: "2em",
            borderRadius: 5,
            boxShadow: "1px 1px 5px rgba(0, 0, 0, .1)",
            bg: "white",
        },
        small: {
            padding: "1em",
            borderRadius: 5,
            boxShadow: "1px 1px 5px rgba(0, 0, 0, .1)",
            bg: "white",
        },
        hoverable: {
            padding: "2em",
            borderRadius: 5,
            boxShadow: "1px 1px 5px rgba(0, 0, 0, .1)",
            bg: "white",
            border: "1px solid white",
            "&:hover": {
                border: "1px solid tusk.shade.900",
                boxShadow: "1px 1px 7px rgba(0, 0, 0, .2)",
                transform: "translate(0, -2px)",
                transition: "transform ease 100ms",
            },
        },
    },
    buttons: {
        primary: {
            ...button,
            backgroundColor: `${primary}.shade.500`,
            color: `${primary}.font.500`,
            fontWeight: "bold",
            "&:hover": {
                backgroundColor: `${primary}.shade.900`,
            },
        },
        secondary: {
            ...button,
            backgroundColor: "tusk.shade.500",
            color: `${primary}.shade.500`,
        },
        outline: {
            ...button,
            backgroundColor: "transparent",
            border: "2px",
            borderStyle: "solid",
            borderColor: `${primary}.shade.500`,
            color: `${primary}.shade.500`,
            fontWeight: "bold",
            "&:hover": {
                borderColor: `${primary}.shade.900`,
                backgroundColor: `${primary}.shade.900`,
                color: `${primary}.font.900`,
            },
        },
        outlineWhite: {
            ...button,
            backgroundColor: "transparent",
            border: "2px",
            borderStyle: "solid",
            borderColor: "white",
            color: "white",
            fontWeight: "bold",
            "&:hover": {
                borderColor: "white",
                backgroundColor: "rgba(0,0,0,0.2)",
                color: "white",
            },
        },
        disabled: {
            ...button,
            backgroundColor: "tusk.shade.900",
            color: "white",
            pointerEvents: "none",
            fontWeight: "bold",
        },
    },
    images: {
        login: {
            width: 150,
            height: 150,
            marginBottom: "2em",
        },
    },
    messages: {
        warning: {
            backgroundColor: "warning.shade.100",
            color: "warning.shade.500",
        },
    },
};
