import { useState, useMemo } from "react";

//TODO: add update select options function (for conditional filtering)

//TODO: extract to npm module?

export function useForm(formFields, initialValues) {
  const init = (arr) =>
    arr.reduce(
      (acc, cur) => {
        let formValid = acc.formValid;
        if (formValid && !cur.optional) {
          formValid =
            initialValues && initialValues[cur.name]
              ? cur.validate(initialValues[cur.name])
              : cur.value && cur.validate
              ? cur.validate(cur.value)
              : false;
        }

        return {
          ...acc,
          formValid,
          [cur.name]: {
            ...cur,
            value:
              initialValues && initialValues[cur.name]
                ? cur.type === "date"
                  ? initialValues[cur.name].toDate()
                  : initialValues[cur.name]
                : cur.value
                ? cur.value
                : "",
            valid:
              initialValues && initialValues[cur.name]
                ? cur.type === "date"
                  ? cur.validate(initialValues[cur.name].toDate())
                  : cur.validate(initialValues[cur.name])
                : cur.value && cur.validate
                ? cur.validate(cur.value)
                : false,
            touched:
              cur.touched !== undefined
                ? cur.touched
                : cur.value
                ? true
                : false,
            blur: false,
          },
        };
      },
      { formValid: true }
    );

  const [values, setValues] = useState(init(formFields));
  const formValid = useMemo(
    () =>
      Object.keys(values)
        .filter((field) => !values[field].optional)
        .filter((field) => field !== "formValid")
        .every((field) => {
          return values[field].valid === true;
        }),
    [values]
  );

  const inputProps = (name) => ({
    ...values[name],
    name,
    onChange:
      values[name].type === "select"
        ? handleSelectChange
        : values[name].type === "multiselect"
        ? handleMultiSelectChange
        : values[name].type === "date"
        ? (date) => {
            if (date) date.setHours(12);
            handleChange({ target: { value: date, name } });
          }
        : handleChange,
    onBlur: () => handleBlur(name),
  });

  const setAllTouched = () => {
    Object.keys(values).forEach((key) => {
      if (key !== "formValid") {
        const valid = validateField(key, values[key]);
        updateValue(key, { touched: true, blur: true }, valid);
      }
    });
  };

  const updateValue = (name, value, valid) => {
    setValues((v) => ({
      ...v,
      [name]: { ...values[name], ...value },
      formValid: valid,
    }));
  };

  const validateField = (name, value) => {
    return values[name].validate ? values[name].validate(value) : true;
  };

  const checkValidFormForFeild = (name, valid) => {
    return formFields
      .filter(({ optional }) => !optional)
      .every((field) =>
        field.name === name ? valid : values[field.name].valid
      );
  };

  const handleChange = (e) => {
    if (e.persist) {
      e.persist();
    }
    const valid = validateField(e.target.name, e.target.value);
    const formValid = checkValidFormForFeild(e.target.name, valid);

    const updatedValue = { value: e.target.value, touched: true, valid };

    updateValue(e.target.name, updatedValue, formValid);
  };

  const handleSelectChange = (option, action) => {
    const valid = validateField(action.name, option.value);
    const formValid = checkValidFormForFeild(action.name, valid);
    const updatedValue = {
      value: option.value,
      touched: true,
      valid,
      selectedOption: option,
    };
    updateValue(action.name, updatedValue, formValid);
  };

  const handleMultiSelectChange = (options, action) => {
    const valid = validateField(action.name, options);
    const formValid = checkValidFormForFeild(action.name, valid);
    const updatedValue = {
      value: options ? options.map((o) => o.value) : null,
      touched: true,
      valid,
      selectedOptions: options,
    };
    updateValue(action.name, updatedValue, formValid);
  };

  const handleBlur = (name) => {
    if (name) {
      setValues((v) => ({ ...v, [name]: { ...v[name], blur: true } }));
    }
  };

  const reset = () => setValues(init(formFields));

  const showError = (name) => {
    if (values[name].touched && !values[name].valid) {
      return true;
    }
    return false;
  };

  const showErrorIfInvalid = (name) => {
    if (values[name].touched && !values[name].valid && values[name].blur) {
      return values[name].error;
    }
    return false;
  };

  const fieldValid = (name) => values[name].valid;
  const fieldTouched = (name) => values[name].touched;

  const valueOf = (name) => values[name].value;

  const touchedInvalid = (name) => fieldTouched(name) && !fieldValid(name);
  const touchedValid = (name) => fieldTouched(name) && fieldValid(name);

  //TODO: useMemo?
  const formattedValues = () => {
    const formattedValues = {};
    Object.keys(values)
      .filter((key) => values[key].value)
      .forEach((key) => {
        formattedValues[key] = values[key].value;
      });

    return formattedValues;
  };

  return {
    values,
    valueOf,
    handleChange,
    showError,
    inputProps,
    fieldValid,
    fieldTouched,
    touchedValid,
    touchedInvalid,
    formValid,
    showErrorIfInvalid,
    reset,
    formattedValues,
    setAllTouched,
  };
}
