import React from "react";
import {
  Card,
  Heading,
  Divider,
  Button,
  Image,
  Flex,
} from "@theme-ui/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDistanceToNow } from "date-fns";
import { useMessages } from "../../../app/context/messages";
import { StyledLabel } from "../../../app/DSL/components/elements/styled";
import styled from "@emotion/styled";
import ReactTooltip from "react-tooltip";

export default function MessageCard({ message }) {
  const { readMessage } = useMessages();
  return (
    <Card style={{ padding: "1em", position: "relative" }}>
      <Heading as="h3">{message?.sentBy?.name}</Heading>

      <p style={{ marginTop: "0.5em" }}>
        {formatDistanceToNow(message.datePosted.toDate(), {
          addSuffix: true,
        })}
      </p>
      <Divider />
      <StyledLabel
        inline
        style={{ textTransform: "capitalize" }}
        type={message.type.value}
      >
        {message.type.label}
      </StyledLabel>
      <Divider />

      <p>{message.content}</p>

      <div style={{ textAlign: "right" }}>
        <Divider />
        <Flex style={{ justifyContent: "space-between" }}>
          <a
            href={`mailto:${message?.sentBy?.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>Respond</Button>
          </a>
          {!message.isRead && (
            <Button
              onClick={() => readMessage(message)}
              variant="outline"
            >
              <FontAwesomeIcon icon={["fal", "check"]} /> Mark as read
            </Button>
          )}
        </Flex>
      </div>
      <PlatformImage
        data-tip={message?.platform?.label ? message?.platform?.label : "HQ"}
        data-for="platform"
        src={
          message.platform
            ? `/assets/${message.platform.value}.svg`
            : "/assets/HQ.svg"
        }
      />
      <ReactTooltip id="platform" effect="solid" />
    </Card>
  );
}

const PlatformImage = styled(Image)`
  position: absolute;
  top: 1em;
  right: 1em;
  width: 35px;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
`;
