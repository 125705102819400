import React from "react";
import styled from "@emotion/styled";
import { InputWrap } from "./StyledInput";
import { Label } from "@theme-ui/components";
import { ChromePicker } from "react-color";

export default function ColourPicker({
  colour,
  setColour,
  isOpen,
  open,
  close,
  label,
}) {
  return (
    <InputWrap>
      <Label>{label}</Label>
      <Swatch colour={colour} onClick={open}>
        <div></div>
      </Swatch>
      {isOpen ? (
        <Popover>
          <Cover onClick={close} />
          <ChromePicker
            color={colour}
            onChange={(color) => {
              setColour(color.hex);
            }}
          />
        </Popover>
      ) : null}
    </InputWrap>
  );
}

const Swatch = styled.div`
  padding: 0.5em;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 0.5em;
  margin-right: 1em;
  height: 1.5em;
  width: 36px;
  div {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background: ${(props) => props.colour};
  }
`;

const Popover = styled.div`
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
