import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { useNewsflashes } from "../../app/context/newsflash";
import NewsflashCard from "../Newsflash/NewsflashCard";
import { isBefore } from "date-fns";

export default function DashNewsflash() {
  var { state } = useNewsflashes();
  const newsflashList = useMemo(
    () =>
      state
        ? state
            .sort((a, b) =>
              isBefore(a.createdAt.toDate(), b.createdAt.toDate()) ? 1 : -1
            )
            .filter((s) => s.isPublished)
        : [],
    [state]
  );
  return (
    <Grid>
      {newsflashList.slice(0, 3).map((n) => (
        <NewsflashCard newsflash={n} key={n.id} />
      ))}
    </Grid>
  );
}
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
  margin-bottom: 3em;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
