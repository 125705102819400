import React from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import { Card } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";

import NewsflashList from "./NewsflashList";
import { useNewsflashes } from "../../../app/context/newsflash";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../app/context/auth";
import { Ring } from "react-awesome-spinners";
import colours from "../../../app/DSL/styles/colours";
import { primary } from "../../../app/DSL/theme";

export default function Newsflash() {
  var history = useHistory();
  var { state, create: createNewsflash } = useNewsflashes();
  var {
    userId,
    claims: { name },
  } = useAuthContext();

  var create = () =>
    createNewsflash({
      author: { userId, name },
      isPublished: false,
    }).then(({ id }) => history.push("/admin-area/newsflash/" + id));

  return (
    <div>
      <PageHeaderContainer>Manage Newsflashes</PageHeaderContainer>

      <Card>
        <BlockHeaderContainer
          title={`Showing ${
            state?.length ? state.filter((s) => !s.isArchived).length : 0
          } newsflash${state?.length === 1 ? "" : "es"}`}
          buttonText="New Newsflash"
          actionType="button"
          action={create}
        />
        {state ? (
          <NewsflashList items={state} />
        ) : (
          <div style={{ textAlign: "center" }}>
            <Ring color={colours[primary].shade["500"]} />
          </div>
        )}
      </Card>
    </div>
  );
}
