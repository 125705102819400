import styled from "@emotion/styled";

export const PageWrapper = styled.div`
  display: grid;
  grid-template-columns: 75px 1fr;
  @media (max-width: 768px) {
    .sidenav {
      display: none;
    }
    grid-template-columns: 1fr;
  }
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  padding: 0 5em;
  background-image: url(${process.env.REACT_APP_BG_URL});
  background-size: ${process.env.REACT_APP_BG_SIZE};
  background-repeat: ${process.env.REACT_APP_BG_REPEAT};
  background-color: ${(props) => props.theme.colors.tusk.shade["400"]};
  display: grid;
  grid-template-rows: 1fr auto;
  @media (max-width: 768px) {
    padding: 0 2em;
    overflow-x: hidden;
  }
  @media (max-width: 576px) {
    padding: 0 1em;
    overflow-x: hidden;
  }
`;
