export const formatMessage = ({ message, user }) => {
  return {
    ...message,
    datePosted: new Date(),
    sentBy: {
      name: user.displayName,
      email: user.email,
      id: user.id,
    },
  };
};
