export const departments = [
  { label: "Business Administration", value: "business-administration" },
  { label: "Customer Service", value: "customer-service" },
  { label: "Finance", value: "finance" },
  { label: "Head Office", value: "head-office" },
  { label: "HR", value: "hr" },
  { label: "Marketing", value: "marketing" },
  { label: "Marketing and Events", value: "marketing-and-events" },
  { label: "Operations", value: "operations" },
  { label: "Operations Cape Town", value: "operations-cape-town" },
  { label: "Operations Free State", value: "operations-free-state" },
  { label: "Operations Gauteng", value: "operations-gauteng" },
  { label: "Operations Kwazulu-Natal", value: "operations-kwazulu-natal" },
  { label: "Operations Port Elizabeth", value: "operations-port-elizabeth" },
  { label: "QA & RA", value: "qa-ra" },
  { label: "Reception and Tea Ladies", value: "reception-and-tea-ladies" },
  { label: "Sales Cape Town", value: "sales-cape-town" },
  { label: "Sales Free State", value: "sales-free-state" },
  { label: "Sales Gauteng", value: "sales-gauteng" },
  { label: "Sales KwaZulu-Natal", value: "sales-kwazulu-natal" },
  { label: "Sales Namibia Agent", value: "sales-namibia-agent" },
  { label: "Sales Sub-Sharan Africa", value: "sales-sub-sharan-africa" },
  { label: "Sales Spine", value: "sales-spine" },
];
