import React from "react";
import { DefaultToast } from "react-toast-notifications";
import styled from "@emotion/styled";

const StyledToast = styled(DefaultToast)`
	background-color: ${props => {
		switch (props.appearance) {
			case "success":
				return props.theme.colors.lightGreen.shade["500"];
			case "error":
				return props.theme.colors.orange.shade["500"];
			case "info":
				return props.theme.blue.warning.shade["500"];
			case "warning":
				return props.theme.colors.warning.shade["500"];
			default:
				return props.theme.tusk.warning.shade["500"];
		}
	}} !important;

	.react-toast-notifications__toast__icon-wrapper {
		background-color: rgba(0, 0, 0, 0.1);
	}

	.react-toast-notifications__toast__content {
		color: white;
	}

	.react-toast-notifications__toast__dismiss-button {
		color: white;
	}
`;

export const Toast = ({ children, ...props }) => (
	<StyledToast {...props}>{children}</StyledToast>
);
