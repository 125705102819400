import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Image } from "@theme-ui/components";
import { platforms } from "../../app/context/data/platforms";
import { useFirebase } from "../../app/context/Firebase";
import { transformDocSnap } from "../../app/context/helpers";
import { useUserContext } from "../../app/context/auth";
import ReactTooltip from "react-tooltip";

export default function PlatformLinks() {
  const firebase = useFirebase();
  const user = useUserContext();

  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    platforms.forEach((p) =>
      firebase
        .setting(`${p.name}Permissions`)
        .get()
        .then((snap) =>
          setPermissions((prev) => ({
            ...prev,
            [p.name]: transformDocSnap(snap),
          }))
        )
    );
  }, [firebase]);

  const platformList = useMemo(
    () =>
      platforms
        .filter((p) => p.showOnDashboard)
        .map((p) => {
          const platformPermissions = permissions[p.name];
          if (platformPermissions && user) {
            if (
              platformPermissions.allowAll ||
              platformPermissions?.department
                ?.map((d) => d.value)
                ?.includes(user.department) ||
              platformPermissions?.users
                ?.map((u) => u.value)
                ?.includes(`${user.id}__${user.displayName}`)
            ) {
              return p;
            } else {
              return { ...p, disabled: true };
            }
          } else {
            return false;
          }
        }),
    [user, permissions]
  );

  return (
    <Grid>
      {platformList.map((p, idx) => (
        <PlatformItem
          disabled={p.disabled || !p.active}
          data-tip={
            p.disabled
              ? "You do not have access to this platform"
              : `Visit ${p.name}`
          }
          data-for="access"
          key={idx}
        >
          {p.disabled || !p.active ? (
            <div>
              <Image src={`/assets/${p.name}.svg`} />
              <h3>{p.title}</h3>
            </div>
          ) : (
            <a href={p.link}>
              <Image src={`/assets/${p.name}.svg`} />
              <h3>{p.title}</h3>
            </a>
          )}
        </PlatformItem>
      ))}
      <ReactTooltip id="access" effect="solid" />
    </Grid>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
  justify-content: center;
  align-items: center;
  * {
    text-align: center;
  }
  img {
    width: 60%;
  }
  @media (max-width: 1366px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 1em;
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 1em;
  }
`;

const PlatformItem = styled.div`
  * {
    color: ${(props) =>
      props.disabled ? props.theme.colors.tusk.shade[900] : "black"};
  }
  filter: ${(props) => (props.disabled ? "grayscale(70%)" : "none")};
  img {
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  }
`;
