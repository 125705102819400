import React, { useState } from "react";
import styled from "@emotion/styled";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Label, Input } from "@theme-ui/components";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import TeammatesList from "./TeammatesList";
import { InputWrap, IconLeft } from "../../app/DSL/components/form/StyledInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { departments } from "../../app/context/data/departments";
import { customStyles } from "../../app/forms/Form";

export default function Teammates() {
  const [department, setDepartment] = useState();
  const [search, setSearch] = useState("");
  return (
    <div>
      <PageHeaderContainer>Teammates</PageHeaderContainer>
      <Grid>
        <Card>
          <BlockHeaderContainer title="Find a teammate" />
          <InputWrap icon="left" style={{ margin: "1em 0 2em" }}>
            <Label>Search</Label>
            <IconLeft>
              <FontAwesomeIcon icon={["fas", "search"]} />
            </IconLeft>
            <Input
              placeholder="Search teammates"
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputWrap>
          <InputWrap style={{ margin: "1em 0" }}>
            <Label>Department</Label>

            <Select
              styles={customStyles}
              isClearable
              isSearchable
              options={departments}
              value={department}
              placeholder="Select a department"
              onChange={(option) => setDepartment(option)}
            />
          </InputWrap>
        </Card>
        <Card>
          <TeammatesList department={department} search={search} />
        </Card>
      </Grid>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 1em;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
