import React from "react";
import { ThemeProvider } from "theme-ui";
import theme from "./DSL/theme";

import { FirebaseProvider } from "./context/Firebase";
import { AuthProvider } from "./context/auth";
import Router from "./Router";

import "./DSL/components/icons/library";
import { BrowserRouter } from "react-router-dom";
import { DataProvider } from "./context/data";
import { ToastProvider } from "react-toast-notifications";
import { Toast } from "./DSL/components/elements/Toast";
import Main from "./Main";
import ErrorBoundary from "./ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <FirebaseProvider>
        <AuthProvider>
          <DataProvider>
            <ThemeProvider theme={theme}>
              <ToastProvider components={{ Toast: Toast }}>
                <BrowserRouter>
                  {["/", "/register", "/first-login"].includes(
                    window.location.pathname
                  ) ? (
                    <Router />
                  ) : (
                    <Main />
                  )}
                </BrowserRouter>
              </ToastProvider>
            </ThemeProvider>
          </DataProvider>
        </AuthProvider>
      </FirebaseProvider>
    </ErrorBoundary>
  );
}

export default App;
