import React from "react";
import ReactModal from "react-modal";
import { Button, Card, Heading } from "@theme-ui/components";
import { Flex } from "theme-ui";
import UserForm from "../../../../features/AdminArea/Users/UserForm";

ReactModal.setAppElement("#root");

export default function ConfirmationModal({
  isOpen,
  setOpen,
  actionType,
  name,
  action,
  id,
  item,
  loading,
  error,
  area,
}) {
  return (
    <ReactModal isOpen={isOpen} overlayClassName="overlay" className="content">
      <Card>
        <Heading>Confirm {actionType}</Heading>
        {(actionType === "archive" || actionType === "approve") && (
          <p style={{ margin: "2em 0" }}>
            {name
              ? `Are you sure you would like to ${actionType} ${name}?`
              : `Are you sure you would like to ${actionType} that?`}
          </p>
        )}

        {actionType === "details" && (
          <div>
            <p>Please confirm the user's details before continuing.</p>
            <UserForm
              loading={loading}
              error={error}
              handleSubmit={(e, formValues) => action(e, formValues, id)}
              submitText="Confirm Details"
              initialValues={item}
              cancel={() => setOpen(false)}
            />
          </div>
        )}
        {actionType === "delete" && (
          <div>
            <p>Are you sure you would like to delete this month?</p>
          </div>
        )}
        {actionType !== "details" && (
          <Flex sx={{ justifyContent: "space-between" }}>
            <Button variant="outline" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                actionType === "delete" ? action(item, area) : action(id);
              }}
            >
              Confirm
            </Button>
          </Flex>
        )}
      </Card>
    </ReactModal>
  );
}
