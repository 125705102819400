import React, { createContext, useContext, useState } from "react";

const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showAdminBar, setShowAdminBar] = useState(false);
  return (
    <MenuContext.Provider
      value={{ setShowMenu, showMenu, showAdminBar, setShowAdminBar }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const MenuConsumer = MenuContext.Consumer;

export const useMenu = () => useContext(MenuContext);
