/**
 * setLoading(true);
 * action().then(() => setLoading(false));
 */
//TODO: use this more
var withLoading = (setLoading) => (action) => {
  setLoading(true);
  action().then(() => setLoading(false));
};

var isBirthday = (timestamp) => {
  if (!timestamp) return false;
  var now = new Date();
  var birthday = timestamp.toDate();

  return (
    now.getMonth() === birthday.getMonth() &&
    now.getDate() === birthday.getDate()
  );
};

export { withLoading, isBirthday };
