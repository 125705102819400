import React, { useState } from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import {
  Card,
  Label,
  Input,
  Button,
  Spinner,
  Flex,
  Divider,
  Checkbox,
} from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { useNewsflashes } from "../../../app/context/newsflash";
import { useParams, useHistory, NavLink } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import useDelayedState from "../../../app/utils/hooks/useDelayedState";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import { withLoading } from "../../../app/utils";
//import ImageUpload from "./ImageUpload";
import { useToasts } from "react-toast-notifications";
// import { useAuthContext } from "../../../app/context/auth";
import ImageUpload from "../../../app/DSL/components/elements/ImageUpload";
import ImageToLinkModal from "./ImageToLinkModal";

export default function EditNewsflash() {
  let { id } = useParams();
  var history = useHistory();
  var { update, focus, remove } = useNewsflashes(id);
  var [state, setState] = useDelayedState(focus);
  const [modalOpen, setModalOpen] = useState(false);
  const { addToast } = useToasts();
  // var user = useAuthContext();
  // loading
  const [loadingImg, setLoadingImg] = useState(false);
  var [loadingPub, setLoadingPub] = useState(false);
  var [loadingDraft, setLoadingDraft] = useState(false);
  var [loadingRemove, setLoadingRemove] = useState(false);
  const cropper = React.createRef();

  var saveDraft = () =>
    withLoading(setLoadingDraft)(() =>
      update(id)({ ...state, isPublished: false }).then(() => {
        addToast("Newsflash Draft Saved", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
        });
        history.push("/admin-area/newsflash/");
      })
    );

  var savePublish = () =>
    withLoading(setLoadingPub)(() =>
      update(id)({ ...state, isPublished: true }).then(() => {
        addToast("Newsflash Published", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500,
        });
        history.push("/admin-area/newsflash/");
      })
    );

  var removePost = () => {
    withLoading(setLoadingRemove)(() => remove(id));
    setLoadingRemove(true);
    update(id)({ ...state, isArchived: true, isPublished: false }).then(() => {
      addToast("Newsflash archived", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
      history.push("/admin-area/newsflash/");
    });
  };

  const uploadFile = async () => {
    const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    setLoadingImg(true);

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully

        const response = JSON.parse(xhr.responseText);
        // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg

        const url = response.secure_url;
        setState((prev) => ({ ...prev, featuredImage: url }));
        setLoadingImg(false);
        return url;
        //addImage(url);
      }
    };

    fd.append("upload_preset", process.env.REACT_APP_PRESET);
    fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
    fd.append(
      "file",
      cropper?.current?.cropper
        ?.getCroppedCanvas({ width: 1000, height: 600 })
        .toDataURL()
    );
    xhr.send(fd);
  };

  return (
    <div>
      <PageHeaderContainer>Newsflash</PageHeaderContainer>
      <Card>
        <BlockHeaderContainer
          title="Edit Newsflash"
          actionType="link"
          link="/admin-area/newsflash"
          buttonText="Back to newsflashes"
        />
        {!state ? (
          "loading..."
        ) : (
          <>
            <InputWrap>
              <Label>Featured Image</Label>
              <ImageUpload
                fullwidth
                image={state?.featuredImage}
                aspectRatio={10 / 6}
                cropper={cropper}
                dimensions={{ width: "500px", height: "300px" }}
                mobileDimensions={{ width: "250px", height: "150px" }}
                upload={async () => await uploadFile()}
                uploading={loadingImg}
              />
            </InputWrap>
            <InputWrap>
              <Label variant="radioLabel">
                <Checkbox
                  checked={state?.showFeaturedImage}
                  onChange={(e) => {
                    setState((p) => ({
                      ...p,
                      showFeaturedImage: !p.showFeaturedImage,
                    }));
                  }}
                />
                Show Featured image in article?
              </Label>
            </InputWrap>
            <InputWrap>
              <Label>Title</Label>
              <Input
                value={state?.title}
                placeholder={"Title"}
                onChange={({ target: { value: title } }) =>
                  setState((p) => ({ ...p, title }))
                }
              />
            </InputWrap>
            <InputWrap>
              <Button onClick={() => setModalOpen(true)}>Get Image Link</Button>
            </InputWrap>
            <InputWrap>
              <Label>Content</Label>
              {/*TODO: Allow having collumns of text? */}
              <Editor
                initialValue={state?.html}
                onEditorChange={(html) => setState((p) => ({ ...p, html }))}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount image",
                  ],
                  toolbar: `undo redo | formatselect | bold italic backcolor | 
                    alignleft aligncenter alignright alignjustify | 
                    bullist numlist outdent indent | removeformat | image code | help`,
                }}
              />
            </InputWrap>
          </>
        )}
        <Divider />
        <Flex style={{ justifyContent: "space-between" }}>
          <div>
            <NavLink to="/admin-area/newsflash">
              <Button variant="outline">Cancel</Button>
            </NavLink>
            <LoadingButton
              style={{ marginLeft: "1em" }}
              onClick={removePost}
              label="Archive"
              loading={loadingRemove}
            />
          </div>
          <div>
            <LoadingButton
              onClick={saveDraft}
              label="Save as Draft"
              disabled={!state?.html && !state?.title && !state?.featuredImage}
              loading={loadingDraft}
            />
            <LoadingButton
              style={{ marginLeft: "1em" }}
              onClick={savePublish}
              label="Publish"
              disabled={!state?.html && !state?.title && !state?.featuredImage}
              loading={loadingPub}
            />
          </div>
        </Flex>
      </Card>
      <ImageToLinkModal isOpen={modalOpen} close={() => setModalOpen(false)} />
    </div>
  );
}

var LoadingButton = (props) => (
  <Button {...props}>
    {props.label}
    {props.loading && (
      <Spinner color="white" size="15" style={{ marginLeft: 10 }} />
    )}
  </Button>
);

// const Buttons = styled.div`
//   display: flex !important;
//   flex-direction: column;
//   button {
//     &:disabled {
//       cursor: not-allowed;
//     }
//     width: 100%;
//     &:not(:last-child) {
//       margin-bottom: 10px;
//     }
//   }
// `;
