import React, { useState, useEffect, useMemo } from "react";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Divider, Button } from "@theme-ui/components";
import styled from "@emotion/styled";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import PointBreakdown from "./PointBreakdown";
import PointsTable from "./PointsTable";
import { usePoints } from "../../app/context/points";
import { useUserContext } from "../../app/context/auth";
import { primary } from "../../app/DSL/theme";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import { customStyles } from "../../app/forms/Form";
import { useUsers } from "../../app/context/users";
import Select from "react-select";
import DownloadReport from "../../app/DSL/components/elements/DownloadReport";
import { format } from "date-fns";
import { getObjFromLink } from "../../app/context/helpers";

export default function PointsLog({ admin }) {
  const {
    getUserPointsLog,
    getMonthsPointsLog,
    getWholePointsLog,
  } = usePoints();
  const [all, setAll] = useState(false);
  const [pointsLog, setPointsLog] = useState();
  const user = useUserContext();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const { users } = useUsers();
  useEffect(
    () =>
      admin
        ? all
          ? getWholePointsLog(setPointsLog)
          : getMonthsPointsLog(setPointsLog)
        : getUserPointsLog(user, setPointsLog),
    [getMonthsPointsLog, getUserPointsLog, user, admin, all, getWholePointsLog]
  );

  const usersOptions = useMemo(
    () =>
      users
        ? users.map((u) => ({
            label: u.displayName,
            value: `${u.id}__${u.displayName}`,
          }))
        : [],
    [users]
  );

  const points = useMemo(
    () =>
      pointsLog
        ? pointsLog
            .filter((p) =>
              selectedUser ? p.userLink === selectedUser.value : true
            )
            .filter((p) =>
              selectedReason ? p.reason === selectedReason.value : true
            )
        : null,
    [pointsLog, selectedUser, selectedReason]
  );

  const csvHeadings = [
    { label: "Date Received", key: "dateReceived" },
    { label: "User", key: "userName" },
    { label: "Points", key: "numPoints" },
    { label: "Reason", key: "reason" },
    { label: "Description", key: "description" },
  ];

  const reasonsOptions = [
    { value: "compliment__given", label: "Compliment given" },
    { value: "compliment__received", label: "Compliment received" },
    {
      value: "compliment__given__archived",
      label: "Compliment given archived",
    },
    {
      value: "compliment__received__archived",
      label: "Compliment received archived",
    },
    { value: "compliment__liked", label: "Compliment liked" },
    { value: "compliment__unliked", label: "Compliment unliked" },
    { value: "login", label: "Login" },
    { value: "joined", label: "Joined" },
    { value: "order__placed", label: "Order placed" },
    { value: "order__refunded", label: "Order refunded" },
  ];

  return (
    <div>
      <PageHeaderContainer>
        {admin ? "All Points" : "Points Log"}
      </PageHeaderContainer>
      {admin && !all && (
        <MessageCard style={{ marginBottom: "1em" }}>
          <h3>Only showing points earned in the last month</h3>
          <Button
            variant="outlineWhite"
            onClick={() => {
              setAll(true);
              setPointsLog(null);
            }}
          >
            Load all Points
          </Button>
        </MessageCard>
      )}
      <Grid admin={admin}>
        <Card>
          <BlockHeaderContainer title="Total Points" />
          <PointBreakdown pointsLog={points} admin={admin} />
          <Divider />
          <h4 className="mobile">
            View on desktop to see a more detailed breakdown
          </h4>
        </Card>
        <Card className="not-mobile">
          <BlockHeaderContainer title="Points Log" />
          {admin && (
            <Filter>
              <InputWrap style={{ margin: "0" }}>
                <Select
                  styles={customStyles}
                  isClearable
                  isSearchable
                  options={usersOptions}
                  value={selectedUser}
                  placeholder="Filter by user"
                  onChange={(option) => setSelectedUser(option)}
                />
              </InputWrap>
              <InputWrap style={{ margin: "0" }}>
                <Select
                  styles={customStyles}
                  isClearable
                  isSearchable
                  options={reasonsOptions}
                  value={selectedReason}
                  placeholder="Filter by reason"
                  onChange={(option) => setSelectedReason(option)}
                />
              </InputWrap>
              <DownloadReport
                data={
                  points
                    ? points.map((p) => ({
                        ...p,
                        dateReceived: format(
                          p.dateReceived.toDate(),
                          "dd/MM/YYY"
                        ),
                        userName: getObjFromLink(p.userLink).name,
                      }))
                    : []
                }
                headers={csvHeadings}
                // loading={!reportsLoaded}
                className="download-btn"
                fileName="points-export"
              />
            </Filter>
          )}
          <PointsTable pointsLog={points} admin={admin} />
        </Card>
      </Grid>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.admin ? "1fr" : "1fr 3fr")};
  column-gap: 1em;

  row-gap: 1em;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr;
  }
`;

const MessageCard = styled(Card)`
  padding: 1em 2em;
  margin-bottom: 1em;
  background: ${(props) => props.theme.colors[primary].shade[500]};
  color: ${(props) => props.theme.colors[primary].font[500]};
`;

const Filter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  align-items: center;
  column-gap: 1em;
`;
