import React from "react";
import release from "../../../config/release.json";
import styled from "@emotion/styled";
import { Image } from "@theme-ui/components";

export default function Footer() {
  const now = new Date();
  return (
    <Grid>
      <small>Version {release.versionClient}</small>
      <div>
        <p>
          © {now.getFullYear()}. {process.env.REACT_APP_FOOTER_COPY}
        </p>
        <p>
          <a
            href={process.env.REACT_APP_TERMS}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>{" "}
          apply
        </p>
      </div>

      <Image
        src={`/assets/${process.env.REACT_APP_FOOTER_LOGO}`}
        width="200px"
      />
    </Grid>
  );
}

const Grid = styled.div`
  z-index: 0;
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  column-gap: 1em;
  align-self: center;
  align-items: center;
  justify-items: center;
  filter: grayscale(1);
  opacity: 0.6;
  padding: 2em 0;
  * {
    color: ${(props) => props.theme.colors.midnight.shade[100]};
  }
  small {
    display: block;
    width: 100%;
  }
  p {
    margin: 0;
    text-align: center;
  }

  @media (max-width: 767.98px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
    small {
      text-align: center;
    }
  }
  a {
    text-decoration: underline;
  }
`;
