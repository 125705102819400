import React, { useState, useEffect } from "react";
import { useFirebase } from "../../../context/Firebase";
import { transformDocSnap } from "../../../context/helpers";
import release from "../../../config/release.json";
import styled from "@emotion/styled";
import { Button } from "@theme-ui/components";
import { primary } from "../../theme";

export default function CheckVersion() {
  const [show, setShow] = useState(false);
  const [dbVersion, setDbVersion] = useState(false);
  const firebase = useFirebase();
  useEffect(() => {
    const unsubscribe = firebase.setting("release").onSnapshot((snap) => {
      const dbrelease = transformDocSnap(snap);
      setDbVersion(dbrelease.versionClient);
      setShow(release.versionClient !== dbrelease.versionClient);
    });
    return () => unsubscribe();
  });
  return show ? (
    <CheckVersionInfo>
      <h2>New Version Available</h2>
      <p>
        A new version ({dbVersion}) of {process.env.REACT_APP_SITE_TITLE} is
        available. You need to reload this page to see the latest changes.
      </p>
      <Button
        variant="outlineWhite"
        onClick={() => {
          window.location.reload(true);
        }}
      >
        Get latest version
      </Button>
    </CheckVersionInfo>
  ) : null;
}

const CheckVersionInfo = styled.div`
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 999;
  width: 320px;
  background: ${(props) => props.theme.colors[primary].shade[500]};
  padding: 30px;
  box-shadow: 0 3px 8px 0 rgba(145, 145, 145, 0.17);
  border-radius: 4px;
  h2 {
    color: #fff;
    margin-top: 0;
  }
  p {
    color: #fff;
  }
  .icon {
    padding-left: 10px;
  }
  .ui.button {
    text-transform: uppercase;
    background-color: #fbbd08 !important;
    border-color: #fbbd08 !important;
    color: #191935 !important;
    &:hover {
      color: #191935 !important;
      background-color: #fbbd08 !important;
    }
  }

  @media (max-width: 576px) {
    width: 250px;
    top: 30px;
    right: 30px;
  }
`;
