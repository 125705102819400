import React from "react";
import PageHeaderContainer from "./PageHeaderContainer";
import BlockHeaderContainer from "./BlockHeaderContainer";
import { Card } from "@theme-ui/components";

export default function StandardPageWrapper({
	mainHeader,
	blockProps,
	children
}) {
	return (
		<div>
			<PageHeaderContainer>{mainHeader}</PageHeaderContainer>
			<Card>
				<BlockHeaderContainer {...blockProps} />

				{children}
			</Card>
		</div>
	);
}
