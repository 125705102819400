export const platforms = [
  ...(process.env.REACT_APP_BROCHURE === "true"
    ? [
        {
          title: "Brochure",
          name: "brochure",
          link: process.env.REACT_APP_BROCHURE_LINK,
          colour: "blue",
          active: true,
          showOnDashboard: true,
        },
      ]
    : []),
  ...(process.env.REACT_APP_SHOP === "true"
    ? [
        {
          title: "Shop",
          name: "shop",
          link: process.env.REACT_APP_SHOP_LINK,
          colour: "teal",
          active: true,
          showOnDashboard: true,
        },
      ]
    : []),
  ...(process.env.REACT_APP_WAREHOUSE === "true"
    ? [
        {
          title: "Warehouse",
          name: "warehouse",
          link: "#",
          colour: "lightGreen",
          active: false,
          showOnDashboard: true,
        },
      ]
    : []),
  ...(process.env.REACT_APP_SALES === "true"
    ? [
        {
          title: "Sales",
          name: "sales",
          link: process.env.REACT_APP_SALES_LINK,
          colour: "lightTeal",
          active: true,
          showOnDashboard: true,
        },
      ]
    : []),
  ...(process.env.REACT_APP_SCREENS === "true"
    ? [
        {
          title: "Screens",
          name: "screens",
          link: process.env.REACT_APP_SCREENS_LINK,
          colour: "purple",
          active: true,
          showOnDashboard: false,
        },
      ]
    : []),
];
