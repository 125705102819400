import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../features/auth/Login";
import Register from "../features/auth/Register";
import Dashboard from "../features/Dashboard";
import { useAuthContext } from "./context/auth";
import Error404 from "./Error404";
import Settings from "../features/Settings";
import Profile from "../features/Profile";
//import ManageData from "../features/ManageData";
import Teammates from "../features/Teammates";
import TeammateDetails from "../features/Teammates/TeammateDetails";
import AdminDashboard from "../features/AdminArea/Dashboard";
import Users from "../features/AdminArea/Users";
import ForgotPassword from "../features/auth/ForgotPassword";
import { useEffect } from "react";
import Compliments from "../features/AdminArea/Compliments";
import FirstLogin from "../features/auth/FirstLogin";
import PointsLog from "../features/PointsLog";
import Messages from "../features/AdminArea/Messages";
import NewsflashAdmin from "../features/AdminArea/Newsflash";
import EditNewsflash from "../features/AdminArea/Newsflash/EditNewsflash";
import Newsflash from "../features/Newsflash";
import NewsflashArchive from "../features/Newsflash/NewsflashArchive";
import { useMenu } from "./context/menu";
import DashboardSlider from "../features/AdminArea/DashboardSlider";
// import Screens from "../features/AdminArea/Screens";
import Platforms from "../features/AdminArea/Platforms";
import NotApproved from "../features/NotApproved";
import YearInReviewForm from "../features/Forms/YearInReviewForm";
import WarehouseHRSurveyForm from "../features/Forms/WarehouseHRSurveyForm";

const Wrapper = ({ location, children }) => {
  const { setShowAdminBar } = useMenu();

  useEffect(() => {
    setShowAdminBar(location?.pathname?.includes("admin-area"));
  }, [location, setShowAdminBar]);
  return children;
};

export default function Router() {
  const auth = useAuthContext();
  //TODO: extract routes to separate files
  const authRoutes = [
    { path: "/dashboard", component: (props) => <Dashboard {...props} /> },
    /*  { path: "/manage-data", component: (props) => <ManageData {...props} /> }, */
    { path: "/settings", component: (props) => <Settings {...props} /> },
    { path: "/profile", component: (props) => <Profile {...props} /> },
    ...(process.env.REACT_APP_NEWSFLASH === "true"
      ? [
          {
            path: "/newsflash",
            component: (props) => <Newsflash {...props} />,
          },
          {
            path: "/newsflash/archive",
            component: (props) => <NewsflashArchive {...props} />,
          },
          {
            path: "/newsflash/:id",
            component: (props) => <Newsflash {...props} />,
          },
        ]
      : []),
    ...(process.env.REACT_APP_VIEW_POINTS === "true"
      ? [
          {
            path: "/points-log",
            component: (props) => <PointsLog {...props} />,
          },
        ]
      : []),
    ...(process.env.REACT_APP_TEAMMATES === "true"
      ? [
          {
            path: "/teammates",
            component: (props) => <Teammates {...props} />,
          },
          {
            path: "/teammates/:id",
            component: (props) => <TeammateDetails {...props} />,
          },
        ]
      : []),

    {
      path: "/year-in-review",
      component: (props) => <YearInReviewForm {...props} />,
    },

    {
      path: "/forms/hr-survey-07-2020",
      component: (props) => <WarehouseHRSurveyForm {...props} />,
    },
  ];

  const notApprovedRoutes = [
    {
      path: "/dashboard",
      component: (props) => <NotApproved {...props} />,
    },
    ...(process.env.REACT_APP_NEWSFLASH === "true"
      ? [
          {
            path: "/newsflash",
            component: (props) => <Newsflash {...props} />,
          },
          {
            path: "/newsflash/archive",
            component: (props) => <NewsflashArchive {...props} />,
          },
          {
            path: "/newsflash/:id",
            component: (props) => <Newsflash {...props} />,
          },
        ]
      : []),
    { path: "/profile", component: (props) => <Profile {...props} /> },
  ];

  const adminRoutes = [
    {
      path: "/admin-area/dashboard",
      component: (props) => <AdminDashboard {...{ props }} />,
    },
    {
      path: "/admin-area/users",
      component: (props) => <Users {...{ props }} />,
    },
    ...(process.env.REACT_APP_VIEW_POINTS === "true"
      ? [
          {
            path: "/admin-area/points",
            component: (props) => <PointsLog {...{ props }} admin={true} />,
          },
        ]
      : []),
    ...(process.env.REACT_APP_TEAMMATES === "true"
      ? [
          {
            path: "/admin-area/compliments",
            component: (props) => <Compliments {...{ props }} />,
          },
        ]
      : []),
    ...(process.env.REACT_APP_MESSAGES === "true"
      ? [
          {
            path: "/admin-area/messages",
            component: (props) => <Messages {...{ props }} />,
          },
        ]
      : []),
    ...(process.env.REACT_APP_NEWSFLASH === "true"
      ? [
          {
            path: "/admin-area/newsflash",
            component: (props) => <NewsflashAdmin {...props} />,
          },
          {
            path: "/admin-area/newsflash/:id",
            component: (props) => <EditNewsflash {...props} />,
          },
        ]
      : []),
    ...(process.env.REACT_APP_SLIDERS === "true"
      ? [
          {
            path: "/admin-area/sliders",
            component: (props) => <DashboardSlider {...{ props }} />,
          },
        ]
      : []),
    // ...(process.env.REACT_APP_SCREENS === "true"
    //   ? [
    //       {
    //         path: "/admin-area/screens",
    //         component: (props) => <Screens {...{ props }} />,
    //       },
    //     ]
    //   : []),
    ...(process.env.REACT_APP_SHOP === "true" ||
    process.env.REACT_APP_BROCHURE === "true" ||
    process.env.REACT_APP_WAREHOUSE === "true" ||
    process.env.REACT_APP_SALES === "true"
      ? [
          {
            path: "/admin-area/platforms",
            component: (props) => <Platforms {...{ props }} />,
          },
        ]
      : []),
  ];

  const createRoutes = (routes) => {
    return routes.map((route, idx) => (
      <Route
        exact
        path={route.path}
        render={(props) => {
          return <Wrapper {...props}>{route.component(props)}</Wrapper>;
        }}
        key={idx}
      />
    ));
  };

  return (
    <Switch>
      <Route exact path="/" render={() => <Login />} />
      <Route exact path="/forgot-password" render={() => <ForgotPassword />} />
      <Route exact path="/register" render={() => <Register />} />
      {auth?.claims?.isAdmin &&
        auth?.claims?.isApproved &&
        createRoutes(adminRoutes)}
      {auth?.claims?.isApproved && createRoutes(authRoutes)}
      {auth?.user && (
        <Route exact path="/first-login" render={() => <FirstLogin />} />
      )}
      {!auth?.claims?.isApproved && createRoutes(notApprovedRoutes)}
      {auth?.user && (
        <Route
          render={(props) => (
            <Wrapper {...props}>
              <Error404 />
            </Wrapper>
          )}
        />
      )}
    </Switch>
  );
}
