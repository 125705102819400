import React from "react";
import {
  Card,
  Heading,
  Image,
  Divider,
  Flex,
  Button,
  Label,
  Checkbox,
} from "@theme-ui/components";
import { formatDistanceToNow } from "date-fns";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import { StyledLabel } from "../../../app/DSL/components/elements/styled";
import { useNewsflashes } from "../../../app/context/newsflash";

export default function NewsflashCard({
  title,
  featuredImage,
  html,
  author,
  id,
  createdAt,
  isPublished,
}) {
  const { update } = useNewsflashes();
  return (
    <StyledCard>
      <Image src={featuredImage ? featuredImage : "/assets/placeholder.gif"} />

      <Heading as="h4">{title}</Heading>
      <p>
        {createdAt &&
          formatDistanceToNow(createdAt.toDate(), {
            addSuffix: true,
          })}{" "}
        | {author?.name}
      </p>
      <Divider />
      <Flex style={{ justifyContent: "space-between", alignItems: "center" }}>
        <Flex>
          <NavLink to={"/admin-area/newsflash/" + id}>
            <Button>Edit</Button>
          </NavLink>
          <InputWrap style={{ marginLeft: "1em" }}>
            <Label variant="radioLabel">
              <Checkbox
                checked={isPublished}
                onChange={(e) => {
                  update(id)({ isPublished: !isPublished });
                }}
              />
              Publish
            </Label>
          </InputWrap>
        </Flex>
        <div>
          <StyledLabel
            style={{ marginLeft: "1em" }}
            colour={isPublished ? "lightGreen" : "tusk"}
            textColour={isPublished ? "white" : "grey"}
          >
            {isPublished ? "Published" : "Draft"}
          </StyledLabel>
        </div>
      </Flex>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  height: 100%;
  padding: 1em;
  display: grid;
  grid-template-rows: auto auto;
  margin-bottom: 1em;
`;
