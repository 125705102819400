import React, { useState } from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import ViewSliders from "./ViewSliders";
import CreateSlider from "./CreateSlider";
import EditSlider from "./EditSlider";

export default function DashboardSlider() {
  const [view, setView] = useState("view");
  const [selectedSlider, setSelectedSlider] = useState();
  //TODO: decide if changing view with router or state is better
  return (
    <div>
      <PageHeaderContainer>Manage Sliders</PageHeaderContainer>
      {
        {
          view: (
            <ViewSliders
              setView={setView}
              setSelectedSlider={setSelectedSlider}
            />
          ),
          create: <CreateSlider setView={setView} />,
          edit: (
            <EditSlider setView={setView} selectedSlider={selectedSlider} />
          ),
        }[view]
      }
    </div>
  );
}
