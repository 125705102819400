import React from "react";
import { Heading, Divider } from "@theme-ui/components";
import { formatDistanceToNow } from "date-fns";
import styled from "@emotion/styled";

export default function ArticleView({ newsflash }) {
  return (
    <div>
      <Heading
        dangerouslySetInnerHTML={{
          __html: newsflash?.title,
        }}
      />
      {newsflash?.createdAt && (
        <p>
          Posted{" "}
          {formatDistanceToNow(newsflash.createdAt.toDate(), {
            addSuffix: true,
          })}
        </p>
      )}
      <Divider />
      {newsflash?.featuredImage && newsflash?.showFeaturedImage && (
        <div>
          <img
            src={newsflash?.featuredImage}
            style={{ width: "100%", height: "auto" }}
            alt="header"
          />
          <Divider />
        </div>
      )}

      <Article
        dangerouslySetInnerHTML={{
          __html: newsflash?.html,
        }}
      />
    </div>
  );
}

const Article = styled.div`
  > *,
  img {
    max-width: 100%;
    height: auto !important;
  }

  .fusion-fullwidth {
    position: relative;
  }

  .fusion-builder-row {
    width: 100%;
    margin: 0 auto;
  }

  .fusion-text a {
    color: #0282cb;
  }
  .post-content p {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .fusion-button-wrapper {
    display: inline;
  }

  .fusion-fullwidth .button-default {
    background: #0082cb;
    text-transform: uppercase;
    color: #ffffff;
  }

  .fusion-fullwidth .button-large {
    padding: 13px 29px;
    line-height: 17px;
    font-size: 14px;
  }

  .fusion-imageframe {
    display: inline-block;
    z-index: 1;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    max-width: 100%;
  }

  .imageframe-align-center {
    text-align: center;
  }
  .fusion-imageframe img {
    max-width: 100%;
    height: auto;
  }

  .fusion-video iframe {
    max-width: 100%;
    height: auto;
    min-height: 350px;
  }

  .fusion-column-wrapper {
    background-color: transparent !important;
  }

  .fusion-layout-column.fusion-column-last {
    margin-left: 0;
    margin-right: 0;
  }
  .fusion-layout-column.fusion-one-full {
    float: none;
    clear: both;
  }

  .fusion-layout-column {
    position: relative;
    float: left;
  }

  .fusion-grid-2 .fusion-grid-column {
    width: 100%;
  }

  .fusion-gallery img {
    border-style: none;
    vertical-align: top;
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    .fusion-layout-column {
      width: 100% !important;
    }
  }
`;
