import React, { useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import styled from "@emotion/styled";

import { Ring } from "react-awesome-spinners";
import { Button, Image, Flex } from "@theme-ui/components";
import { primary } from "../../theme";
import { useToasts } from "react-toast-notifications";

export default function ImageUpload({
  cropper,
  aspectRatio,
  dimensions,
  centered,
  upload,
  uploading,
  image,
  fullwidth,
  name,
  mobileDimensions,
  autoSize,
}) {
  const [fullImage, setFullImage] = useState();
  const [loading, setLoading] = useState(false);
  const fileInput = React.createRef();
  const { addToast } = useToasts();
  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const dt = e.dataTransfer;
    const files = dt.files;
    handleFile(files);
  };

  const handleDrag = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleFile = (files) => {
    setLoading(true);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setFullImage(reader.result);
      setLoading(false);
    });
    try {
      reader.readAsDataURL(files[0]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!(fullImage === undefined || fullImage === null)) {
      const mimeType = fullImage
        ?.split(",")[0]
        .match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];
      console.log(mimeType);
      if (!mimeType?.includes("image")) {
        setFullImage(null);
        addToast(`Cannot upload file of type: ${mimeType}`, {
          appearance: "warning",
          autoDismiss: true,
          autoDismissTimeout: 1500,
        });
      }
    }
  }, [fullImage, addToast]);

  return (
    <DropArea
      autoSize={autoSize}
      fullwidth={fullwidth}
      centered={centered}
      dimensions={dimensions}
      mobileDimensions={mobileDimensions}
      onDrop={handleDrop}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
    >
      {(loading || uploading) && (
        <Loader dimensions={dimensions} fullwidth={fullwidth}>
          <Ring color="blue" />
        </Loader>
      )}
      {image && !fullImage ? (
        <div>
          <Image src={image} style={{ marginBottom: "20px" }} />

          <FileInput
            id={name ? name : "file"}
            type="file"
            accept="image/*"
            onChange={() => handleFile(fileInput.current.files)}
            ref={fileInput}
          />
          <FileInputLabel htmlFor={name ? name : "file"}>
            Choose another image
          </FileInputLabel>
        </div>
      ) : fullImage ? (
        <div>
          {cropper ? (
            <Cropper
              ready={() => setLoading(false)}
              ref={cropper}
              src={fullImage}
              className="cropper"
              // Cropper.js options
              aspectRatio={aspectRatio ? aspectRatio : 1 / 1}
              guides={true}
            />
          ) : (
            <Image src={fullImage} />
          )}
          {/* If there is an upload function include the upload button */}
          {upload && (
            <Flex style={{ justifyContent: "center" }}>
              <Button
                style={{
                  marginTop: "10px",
                }}
                onClick={() => upload(fullImage).then(() => setFullImage(null))}
              >
                Upload
              </Button>
              <Button
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
                variant="outline"
                onClick={() => setFullImage(null)}
              >
                Cancel
              </Button>
            </Flex>
          )}
        </div>
      ) : (
        !loading &&
        !uploading && (
          <>
            <p>Drag and drop an image here</p>
            <p>Or</p>
            <FileInput
              id={name ? name : "file"}
              type="file"
              accept="image/*"
              onChange={() => handleFile(fileInput.current.files)}
              ref={fileInput}
            />
            <FileInputLabel htmlFor={name ? name : "file"}>
              Choose a file
            </FileInputLabel>
          </>
        )
      )}
    </DropArea>
  );
}

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const FileInputLabel = styled.label`
  display: block;
  max-width: 200px;
  margin: auto;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.7em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.7em - 1px);
  text-align: center;
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors[primary].shade["500"]};
  color: ${(props) => props.theme.colors[primary].font["500"]};
  font-weight: bold;
  :hover {
    background-color: ${(props) => props.theme.colors[primary].shade["900"]};
  }
  :focus {
    outline: 0;
  }
  border-radius: 4px;
`;

const DropArea = styled.div`
  width: ${(props) =>
    props.fullwidth
      ? "calc(100% - 4.5em)"
      : props.dimensions
      ? props.dimensions.width
      : "400px"};
  margin: ${(props) => (props.centered ? "auto" : 0)};
  min-height: ${(props) =>
    props.dimensions ? props.dimensions.height : "400px"};
  padding: 2em;
  border: 5px dashed lightgrey;
  border-radius: 5px;
  display: grid;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1em;

  .cropper {
    height: ${(props) =>
      props.dimensions ? props.dimensions.height : "400px"};
    width: ${(props) => (props.dimensions ? props.dimensions.width : "400px")};
  }

  img {
    height: ${(props) =>
      props.autoSize
        ? "auto"
        : props.dimensions
        ? props.dimensions.height
        : "400px"};
    width: ${(props) =>
      props.autoSize
        ? "auto"
        : props.dimensions
        ? props.dimensions.width
        : "400px"};
  }

  /* Small devices */
  @media (max-width: 576px) {
    min-height: ${(props) =>
      props.mobileDimensions ? props.mobileDimensions.height : "200px"};
    width: ${(props) =>
      props.mobileDimensions ? props.mobileDimensions.width : "200px"};
    .cropper {
      height: ${(props) =>
        props.mobileDimensions ? props.mobileDimensions.height : "200px"};
      width: ${(props) =>
        props.mobileDimensions ? props.mobileDimensions.width : "200px"};
    }

    img {
      height: ${(props) =>
        props.autoSize
          ? "auto"
          : props.mobileDimensions
          ? props.mobileDimensions.height
          : "200px"};
      width: ${(props) =>
        props.autoSize
          ? "auto"
          : props.mobileDimensions
          ? props.mobileDimensions.width
          : "200px"};
    }
  }
`;
const Loader = styled.div`
  width: ${(props) =>
    props.fullwidth
      ? "calc(100% - 4.5em)"
      : props.dimensions
      ? props.dimensions.width
      : "400px"};
  height: ${(props) => (props.dimensions ? props.dimensions.height : "400px")};
  position: absolute;
  display: grid;
  align-items: center;
  justify-items: center;
  z-index: 5;
`;
