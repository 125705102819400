import React, { useState, useEffect } from "react";
import { useNewsflashes } from "../../app/context/newsflash";
import { Image, Card, Heading } from "@theme-ui/components";
import { Ellipsis } from "react-awesome-spinners";
import { NavLink } from "react-router-dom";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";
import styled from "@emotion/styled";

export default function NewsflashCard({ newsflash }) {
  const [imgURL, setImgURL] = useState();
  const { getImageURL } = useNewsflashes();
  const isWordpress = newsflash?._links;
  useEffect(() => {
    if (isWordpress) getImageURL(newsflash?.featured_media).then(setImgURL);
    else setImgURL(newsflash?.featuredImage);
  }, [getImageURL, newsflash, isWordpress]);
  return (
    <NavLink to={`/newsflash/${newsflash.id}`} style={{ height: "100%" }}>
      <StyledCard>
        {imgURL ? (
          <Image src={imgURL} />
        ) : (
          <div
            style={{
              height: "150px",
              display: "grid",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <Ellipsis color={colours[primary].shade["500"]} />
          </div>
        )}
        <Heading
          as="h4"
          dangerouslySetInnerHTML={{
            __html: isWordpress ? newsflash?.title?.rendered : newsflash?.title,
          }}
        ></Heading>
      </StyledCard>
    </NavLink>
  );
}
const StyledCard = styled(Card)`
  height: 100%;
  padding: 1em;
  display: grid;
  grid-template-rows: auto auto;
  margin-bottom: 1em;
  h4 {
    margin: 1em;
    align-self: end;
  }
`;
