import React, { useState } from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Input, Label, Image, Close, Button } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import BasicImageUpload from "../../../app/DSL/components/elements/BasicImageUpload";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import styled from "@emotion/styled";
import { useUserContext } from "../../../app/context/auth";
import { useFirebase } from "../../../app/context/Firebase";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

export default function YearInReviewForm() {
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [message, setMessage] = useState(null);
    const user = useUserContext();
    const { db } = useFirebase();
    const { addToast } = useToasts();
    const history = useHistory();
    const removeImage = (img) => {
        console.log("hello");
        const imgs = images.filter((i) => i !== img);
        setImages(imgs);
    };
    const submit = () => {
        setLoading(true);
        db.collection("YearInReview")
            .doc()
            .set({
                images,
                message,
                sentBy: {
                    displayName: user.displayName,
                    email: user.email,
                    id: user.id,
                },
            })
            .then((d) => {
                setLoading(false);
                addToast("Thank you! Your images have been sent successfully", {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                history.push("/dashboard");
            })
            .catch((err) => {
                setLoading(false);
                addToast("Oops! Something went wrong, please try again ", {
                    appearance: "warning",
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            });
    };
    return (
        <div>
            <PageHeaderContainer>Year in Review</PageHeaderContainer>
            <Card>
                <BlockHeaderContainer title="Share Your Year with Us" />
                <ImageHeader src="/assets/image-collage.jpg" />

                <h3>
                    We're excited that we have been in our new offices for
                    almost a year now, we'd like you to share your top memories
                    in the form of photos with us
                </h3>
                <Grid>
                    <Card variant="small">
                        <h4>Upload Images</h4>
                        <BasicImageUpload
                            addImage={(img) =>
                                setImages((prev) => [...prev, img])
                            }
                        />
                    </Card>
                    <Card variant="small">
                        <h4>Your Images</h4>
                        <ImageGrid>
                            {images.map((img, idx) => (
                                <Card
                                    variant="small"
                                    key={idx}
                                    style={{
                                        padding: "1em",
                                        position: "relative",
                                    }}
                                >
                                    <Image src={img} />
                                    <Remove onClick={() => removeImage(img)} />
                                </Card>
                            ))}
                        </ImageGrid>
                    </Card>
                </Grid>
                <InputWrap>
                    <Label>Message</Label>
                    <Input
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Your message"
                    />
                </InputWrap>
                {loading ? (
                    <Button variant="disabled">Loading...</Button>
                ) : (
                    <Button
                        onClick={submit}
                        variant={images.length > 0 ? "primary" : "disabled"}
                    >
                        Submit
                    </Button>
                )}
            </Card>
        </div>
    );
}

const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
    row-gap: 1em;
    h4 {
        margin-top: 0;
    }
    margin-bottom: 1em;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;
const ImageGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1em;
    row-gap: 1em;
    > div {
        display: grid;
        align-content: center;
    }
    @media (max-width: 1366px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;
const Remove = styled(Close)`
    position: absolute;
    top: 0.7em;
    right: 0.7em;
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.theme.colors.tusk.shade["500"]};
    border-radius: 50%;
`;

const ImageHeader = styled.div`
    width: 100%;
    height: 300px;
    background-image: url(${(props) => props.src});
    background-position: center;
    background-size: 100%;
    margin-top: -1em;
`;
