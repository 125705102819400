import React, { useMemo } from "react";
import { Card, Heading, Divider, Flex, Button } from "@theme-ui/components";
import { getObjFromLink } from "../../../app/context/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserContext } from "../../../app/context/auth";
import { Likes } from "../../../app/DSL/components/elements/styled";
import { useCompliments } from "../../../app/context/compliments";
import { formatDistanceToNow } from "date-fns";
//import Reply from "./Reply";
import { useToasts } from "react-toast-notifications";

export default function ComplimentCard({ compliment, approval }) {
  const user = useUserContext();
  const {
    likeCompliment,
    unlikeCompliment,
    approveCompliment,
    archiveCompliment,
  } = useCompliments();
  const liked = useMemo(
    () => compliment?.likes?.includes(`${user?.id}__${user?.displayName}`),
    [compliment, user]
  );

  const { addToast } = useToasts();
  return (
    <Card variant="small">
      {approval ? (
        <Heading as="h3">
          Posted by {getObjFromLink(compliment.postedBy).name} for{" "}
          {getObjFromLink(compliment.for).name}
        </Heading>
      ) : (
        <Heading as="h3">{getObjFromLink(compliment.postedBy).name}</Heading>
      )}
      <p style={{ marginTop: "0.5em" }}>
        {formatDistanceToNow(compliment.datePosted.toDate(), {
          addSuffix: true,
        })}
      </p>
      <Divider />
      <p>{compliment.content}</p>
      <Divider />
      <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
        {user?.id === getObjFromLink(compliment.postedBy).id ? (
          <Likes as="h4" active={true} noClick={true}>
            {" "}
            <FontAwesomeIcon icon={["fad", "thumbs-up"]} />{" "}
            {compliment.likes.length} Likes
          </Likes>
        ) : (
          <Likes
            as="h4"
            active={liked}
            onClick={() =>
              liked ? unlikeCompliment(compliment) : likeCompliment(compliment)
            }
          >
            {" "}
            <FontAwesomeIcon icon={[liked ? "fad" : "far", "thumbs-up"]} />{" "}
            {compliment.likes.length} Likes
          </Likes>
        )}
        {approval && (
          <Flex>
            {!compliment.isApproved && (
              <Button
                onClick={() =>
                  approveCompliment(compliment).then(() => {
                    addToast("Compliment approved", {
                      appearance: "success",
                      autoDismiss: true,
                      autoDismissTimeout: 1500,
                    });
                  })
                }
              >
                <FontAwesomeIcon icon={["fal", "check"]} /> Approve
              </Button>
            )}
            <Button
              style={{ marginLeft: "1em" }}
              onClick={() =>
                archiveCompliment(compliment).then(() => {
                  addToast("Compliment archived", {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 1500,
                  });
                })
              }
              variant="outline"
            >
              <FontAwesomeIcon icon={["fal", "trash"]} /> Archive
            </Button>
          </Flex>
        )}
      </Flex>
      {/* TODO: release reply feature */}
      {/* <Reply compliment={compliment} user={user} /> */}
    </Card>
  );
}
