import React from "react";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { Card, Divider } from "@theme-ui/components";
import { useUsers } from "../../../app/context/users";
import { useMemo } from "react";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useFirebase } from "../../../app/context/Firebase";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  RoundButton,
  ActionsTableContainer,
  SeeAllLink,
} from "../../../app/DSL/components/elements/styled";
import ReactTooltip from "react-tooltip";
import Table from "../../../app/DSL/components/elements/Table";
import ConfirmationModal from "../../../app/DSL/components/elements/ConfirmationModal";
import { NavLink } from "react-router-dom";
import { departments } from "../../../app/context/data/departments";

export default function ApprovalUsersTable() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState();
  const [modalActionType, setModalActionType] = useState();
  const [confirmItem, setConfirmItem] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { addToast } = useToasts();

  const firebase = useFirebase();
  const { users } = useUsers();

  //TODO: move to users context
  const approveUser = useCallback(
    () => (e, formValues, id) => {
      setLoading(true);
      e.preventDefault();
      formValues.displayName = `${formValues.firstName} ${formValues.lastName}`;
      delete formValues.formValid;

      return firebase
        .user(id)
        .update({
          ...formValues,
          isApproved: true,
        })

        .then(() => {
          setModalOpen(false);
          addToast("Edited Successfully", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 1500,
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);

          setError(error);
          console.log(error);
        });
    },
    [addToast, firebase]
  );

  const confirmApproval = useCallback(
    () => (id) => {
      setModalAction(approveUser);
      setModalActionType("details");
    },
    [approveUser]
  );

  const userList = useMemo(
    () =>
      users
        ? users.filter((u) => !u.isArchived).filter((u) => !u.isApproved)
        : [],
    [users]
  );
  //TODO: move to separate file
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        accessor: (u) => `${u.firstName} ${u.lastName}`,
        filter: "fuzzyText",
      },
      {
        Header: "Department",
        id: "department",
        accessor: (u) => {
          const dep = departments.find((d) => d.value === u.department);
          return dep ? dep.label : "-";
        },
        filter: "fuzzyText",
      },

      {
        Header: "Actions",
        accessor: (u) => u.id,
        Cell: (cell) => {
          return (
            <span>
              <RoundButton
                key="approve"
                data-tip="Approve user"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalActionType("approve");
                  setModalAction(confirmApproval);
                  setConfirmItem(cell.row.original);
                }}
              >
                <FontAwesomeIcon icon={["fas", "check"]} />
              </RoundButton>

              <ReactTooltip id="actionName" effect="solid" />
            </span>
          );
        },
        className: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [confirmApproval]
  );

  return (
    <Card style={{ position: "relative" }}>
      <BlockHeaderContainer title="Users awaiting approval" />
      <div style={{ marginBottom: "3em" }}>
        <ActionsTableContainer condensed>
          <Table
            emptyMsg="No users to approve"
            emptyIcon="check"
            columns={columns}
            data={userList}
            defaultRows={5}
            pagination={false}
            loading={users === undefined}
          />
        </ActionsTableContainer>
      </div>
      <SeeAllLink>
        <Divider />
        <NavLink to="/admin-area/users">See all</NavLink>
      </SeeAllLink>

      <ConfirmationModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        name={confirmItem.displayName}
        id={confirmItem.id}
        action={modalAction}
        actionType={modalActionType}
        item={confirmItem}
        type="user"
        loading={loading}
        error={error}
      />
    </Card>
  );
}
