import React, { useState, useMemo, useEffect } from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import styled from "@emotion/styled";
import {
  Card,
  Label,
  Input,
  Checkbox,
  Heading,
  Divider,
  Button,
} from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import {
  InputWrap,
  IconLeft,
} from "../../../app/DSL/components/form/StyledInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComplimentList from "../../Teammates/Compliments/ComplimentList";
import { useCompliments } from "../../../app/context/compliments";
import DateInput from "../../../app/DSL/components/form/DateInput";
import { isSameDay, isBefore } from "date-fns";
import { Ring } from "react-awesome-spinners";
import colours from "../../../app/DSL/styles/colours";
import { primary } from "../../../app/DSL/theme";
import { useHistory } from "react-router-dom";

export default function Compliments() {
  const [toSearch, setToSearch] = useState("");
  const [fromSearch, setFromSearch] = useState("");
  const [datePosted, setDatePosted] = useState();
  const [dateApproved, setDateApproved] = useState();
  const [onlyUnapproved, setOnlyUnapproved] = useState(false);
  const [compliments, setCompliments] = useState();
  const { getMonthsCompliments, getAllCompliments } = useCompliments();
  const history = useHistory();
  const [all, setAll] = useState(false);

  useEffect(
    () =>
      all
        ? getAllCompliments(setCompliments)
        : getMonthsCompliments(setCompliments),
    [getMonthsCompliments, getAllCompliments, all]
  );

  useEffect(() => {
    if (history.location.search === "?postedToday") {
      setDatePosted(new Date());
    }
    if (history.location.search === "?approvedToday") {
      setDateApproved(new Date());
    }
  }, [history]);
  const complimentList = useMemo(
    () =>
      compliments
        ? compliments
            .sort((a, b) =>
              isBefore(a?.datePosted?.toDate(), b?.datePosted?.toDate())
                ? 1
                : -1
            )
            .filter((c) => !c.isArchived)
            .filter((c) => {
              return c.for.toLowerCase().includes(toSearch);
            })
            .filter((c) => {
              return c.postedBy.toLowerCase().includes(fromSearch);
            })
            .filter((c) =>
              datePosted
                ? c.datePosted
                  ? isSameDay(c.datePosted.toDate(), datePosted)
                  : c.datePosted
                : true
            )
            .filter((c) =>
              dateApproved
                ? c.dateApproved
                  ? isSameDay(c.dateApproved.toDate(), dateApproved)
                  : c.dateApproved
                : true
            )
            .filter((c) => (onlyUnapproved ? !c.isApproved : true))
        : [],
    [
      fromSearch,
      toSearch,
      compliments,
      dateApproved,
      datePosted,
      onlyUnapproved,
    ]
  );
  return (
    <div>
      <PageHeaderContainer>Manage Compliments</PageHeaderContainer>
      <Grid>
        {/* TODO: extract to component */}
        <Card>
          <BlockHeaderContainer title="Find compliments" />

          <InputWrap icon="left" style={{ margin: "1em 0 2em" }}>
            <Label>Search recipient</Label>
            <IconLeft>
              <FontAwesomeIcon icon={["fas", "search"]} />
            </IconLeft>
            <Input
              placeholder="Search teammates"
              onChange={(e) => setToSearch(e.target.value)}
            />
          </InputWrap>
          <InputWrap icon="left" style={{ margin: "1em 0 2em" }}>
            <Label>Search sender</Label>
            <IconLeft>
              <FontAwesomeIcon icon={["fas", "search"]} />
            </IconLeft>
            <Input
              placeholder="Search teammates"
              onChange={(e) => setFromSearch(e.target.value)}
            />
          </InputWrap>
          <InputWrap style={{ margin: "1em 0 2em" }}>
            <Label>Date Posted</Label>
            <DateInput onChange={(d) => setDatePosted(d)} value={datePosted} />
          </InputWrap>
          <InputWrap style={{ margin: "1em 0 2em" }}>
            <Label>Date Approved</Label>
            <DateInput
              onChange={(d) => setDateApproved(d)}
              value={dateApproved}
            />
          </InputWrap>
        </Card>
        <Card>
          <BlockHeaderContainer
            classes="not-mobile"
            title={`Showing ${complimentList.length} compliment${
              complimentList.length === 1 ? "" : "s"
            }`}
            rightComponent={
              <InputWrap>
                <Label variant="radioLabel" style={{ textTransform: "none" }}>
                  <Checkbox
                    checked={onlyUnapproved}
                    onChange={(e) => {
                      setOnlyUnapproved(!onlyUnapproved);
                    }}
                  />
                  Only show compliments awaiting approval?
                </Label>
              </InputWrap>
            }
          />
          <div className="mobile" style={{ marginBottom: "1em" }}>
            <Heading>
              Showing {complimentList.length} compliment
              {complimentList.length === 1 ? "" : "s"}
            </Heading>
            <InputWrap>
              <Label variant="radioLabel" style={{ textTransform: "none" }}>
                <Checkbox
                  checked={onlyUnapproved}
                  onChange={(e) => {
                    setOnlyUnapproved(!onlyUnapproved);
                  }}
                />
                Only show compliments awaiting approval?
              </Label>
            </InputWrap>
            <Divider />
          </div>
          {compliments ? (
            <ComplimentList compliments={complimentList} approval={true} />
          ) : (
            <div style={{ textAlign: "center" }}>
              <Ring color={colours[primary].shade["500"]} />
            </div>
          )}
          {!all && (
            <div>
              <Divider />
              <h3>Only showing compliments from the last month</h3>
              <Button
                onClick={() => {
                  setAll(true);
                  setCompliments(null);
                }}
              >
                Load all Compliments
              </Button>
            </div>
          )}
        </Card>
      </Grid>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 1em;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
