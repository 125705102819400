import React from "react";
import { Card } from "@theme-ui/components";

import ReactModal from "react-modal";
import styled from "@emotion/styled";

export default function CardModal({ isOpen, children, close, width }) {
  ReactModal.setAppElement("#root");
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName="overlay"
      className="content"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={close}
    >
      <StyledCard style={{ maxWidth: width, margin: "auto" }}>
        {children}
      </StyledCard>
    </ReactModal>
  );
}

const StyledCard = styled(Card)`
  @media (max-width: 768px) {
    max-width: 100% !important;
    width: 100% !important;
  }
`;
